import {DecimalPipe} from '@angular/common';
import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Chart} from 'angular-highcharts';

import {InvoiceReportComparisonType} from '../model/byinterval/invoice-report-comparison-type';
import {InvoiceReportResultByIntervalDto} from '../model/byinterval/invoice-report-result-by-interval-dto';
import {InvoiceReportStackDto} from '../model/byinterval/invoice-report-stack-dto';
import {TranslateLocalizedText} from '../../shared/pipes/translate-localized-text';
import {DiagramService} from '../../shared/services/diagram.service';
import {colors} from '../../shared/constants/colors';
import {DateFormatUtil} from '../../core/util/date-format.util';

@Component({
    selector: 'jhi-invoice-column-diagram',
    templateUrl: './invoice-column-diagram.component.html',
    styleUrls: ['./invoice-column-diagram.component.scss']
})
export class InvoiceColumnDiagramComponent implements OnInit {

    @Input() set unitCost(value: boolean) {
        this._unitCost = value;
    }

    @Input() set chartData(invoiceReportResultByIntervalDto: InvoiceReportResultByIntervalDto) {
        this.createChart(invoiceReportResultByIntervalDto);
    }

    chart: Chart;

    options: any;
    data: InvoiceReportResultByIntervalDto;

    _unitCost: boolean;

    constructor(private translateService: TranslateService,
                private translateLocalizedText: TranslateLocalizedText,
                private diagramService: DiagramService,
                private decimalPipe: DecimalPipe) {
    }

    ngOnInit() {
    }

    private createChart(data: InvoiceReportResultByIntervalDto) {
        this.data = data;
        this.options = this.defaultChartOptions;

        this.options.title = {
            text: this._unitCost ? this.translateService.instant('invoiceReport.chart.unitCostTitle') : this.translateService.instant('invoiceReport.chart.costTitle')
        };
        this.options.yAxis.title = {text: data.unit};

        let categories = data.categories.map(category => this.getCategoryName(category.dates));
        this.options.xAxis = {
            categories: categories
        };

        this.options.xAxis.minRange = 1; // set min and max for minRange = 1, see https://github.com/highcharts/highcharts/issues/6800
        this.options.xAxis.min = 0;
        this.options.xAxis.max = categories.length - 1;

        this.options.series = [];
        data.resources.forEach((resource, resourceIndex) => {
            for (let stackIndex = 0; stackIndex < data.stacksPerCategory; stackIndex++) {
                const nullDataArray = new Array(data.categories.length);
                nullDataArray.fill(null, 0, data.categories.length);

                this.options.series.push({
                    id: this.getSeriesId(resource.resourceId, stackIndex),
                    name: this.translateLocalizedText.transform(resource.resourceName),
                    data: nullDataArray,
                    color: colors[(data.resources.length - 1 - resourceIndex) % colors.length],
                    stack: stackIndex,
                    linkedTo: stackIndex != 0 ? this.getSeriesId(resource.resourceId, 0) : undefined,
                    resourceId: resource.resourceId
                });
            }
        });

        data.values.forEach(value => {
            const stack = this.getStack(value.stackId);
            const seriesId = this.getSeriesId(value.resourceId, stack.stackIndex);
            const series = this.options.series.find(serie => serie.id === seriesId);

            const categoryIndex = this.data.categories.findIndex(category => stack.categoryId === category.categoryId);

            series.data[categoryIndex] = {
                y: value.value,
                stackId: value.stackId
            };
        });

        let seriesToRemove = this.options.series
            .filter(serie => serie.data.every(d => d === null));
        seriesToRemove
            .forEach(serieToRemove => this.removeSerie(serieToRemove));

        this.updateTooltip();

        this.chart = new Chart(this.options);
    }

    private removeSerie(serieToRemove) {
        const seriesToRemoveIndex = this.options.series.findIndex(serie => serie.id === serieToRemove.id);

        const linkedSeries = this.options.series
            .filter(serie => serieToRemove.id === serie.linkedTo);
        if (linkedSeries.length > 0) {
            linkedSeries.forEach(linkedSerie => linkedSerie.linkedTo = linkedSeries[0].id);
            linkedSeries[0].linkedTo = undefined;
        }
        this.options.series.splice(seriesToRemoveIndex, 1);
    }

    private updateTooltip() {
        let totalText: string = this.translateService.instant('invoiceReport.chart.total');
        const _this = this;
        const valueFn = (value: number) => _this.decimalPipe.transform(value, this.data.unit.match('.*MW.*') ? '1.3-3' : '1.2-2');

        this.options.tooltip = {
            useHTML: true,
            formatter: function () {
                const stack = _this.getStack(this.point.stackId);
                let categoryIndex = _this.data.categories.findIndex(category => category.categoryId === stack.categoryId);
                const stackSeries = _this.options.series.filter(serie => serie.stack === stack.stackIndex);

                let s: string = '<small>' + (_this.data.comparisonType === InvoiceReportComparisonType.DATE || _this.data.comparisonType === InvoiceReportComparisonType.NONE ? DateFormatUtil.formatToLocalDate(stack.stackName) : stack.stackName) + '</small>';

                s += '<table>';

                _this.data.resources.forEach(resource => {
                    const series = stackSeries.find(stackSerie => stackSerie.resourceId === resource.resourceId);
                    const point = series ? series.data[categoryIndex] : null;

                    if (point !== null && point.y !== null) {
                        s += '<tr style="color: ' + series.color + '">'
                            + '  <td style="padding-right: 10px"><b>' + series.name + '</b></td>'
                            + '  <td style="text-align: right"><b>' + valueFn(point.y) + ' ' + _this.data.unit + '</b> </td>'
                            + '</tr>';
                    }
                });

                s += '<tr style="border-top: solid thin">'
                    + '  <td style="padding-right: 10px; font-style: italic"><b>' + totalText + '</b></td>'
                    + '  <td style="text-align: right; font-style: italic"><b>' + valueFn(stack.sum) + ' ' + _this.data.unit + '</b></td>'
                    + '</tr>';

                s += '</table>';
                return s;
            }
        };
    }

    private getSeriesId(resourceId: number, stackId: number): string {
        return resourceId + '|' + stackId;
    }

    private getCategoryName(dates: string[]): string {
        return dates.map(date => DateFormatUtil.formatToLocalDate(date)).join('<br>');
    }

    private getStack(stackId: number): InvoiceReportStackDto {
        return this.data.stacks.find(stack => stack.stackId === stackId);
    }

    get defaultChartOptions(): any {
        return {
            chart: {
                type: 'column',
                zoomType: 'x',
                events: this.diagramService.getWatermark(false),
                resetZoomButton: {
                    theme: {
                        opacity: 0.3,
                        states: {
                            hover: {
                                opacity: 1
                            }
                        }
                    }
                }
            },
            yAxis: {
                min: 0
            },
            legend: {
                enabled: true,
                reversed: true
            },
            scrollbar: {
                enabled: false
            },
            time: {
                useUTC: true
            },
            plotOptions: {
                column: {
                    stacking: 'normal'
                },
                series: {
                    borderWidth: 0
                }
            },
            credits: {
                enabled: false
            },
            exporting: {
                filename: this._unitCost ? this.translateService.instant('invoiceReport.chart.unitCostTitle') : this.translateService.instant('invoiceReport.chart.costTitle'),
                buttons: {
                    contextButton: {
                        align: 'left',
                        x: -10,
                        y: -10
                    }
                },
                sourceWidth: 1680,
                sourceHeight: 400,
                scale: 1,
                chartOptions: {
                    title: {
                        style: {
                            fontSize: '12px'
                        }
                    },
                    xAxis: {
                        labels: {
                            style: {
                                fontSize: '10px'
                            }
                        },
                        title: {
                            style: {
                                fontSize: '10px'
                            }
                        }
                    },
                    yAxis: {
                        labels: {
                            style: {
                                fontSize: '10px'
                            }
                        },
                        title: {
                            style: {
                                fontSize: '10px'
                            }
                        }
                    },
                    legend: {
                        itemStyle: {
                            fontSize: '10px'
                        }
                    }
                }
            }
        };
    }

}
