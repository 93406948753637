import {Component, Inject} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'rfq-confirmation',
    templateUrl: './rfq-confirmation.component.html',
    styles: []
})
export class RfqConfirmationComponent {

    public description: SafeHtml = this.getDescription();

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                private translateService: TranslateService,
                private sanitizer: DomSanitizer) {
    }

    private getDescription(): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(this.translateService.instant('offerClaim.rfqConfirmDialog.description'));
    }
}
