import {HttpClient, HttpParams} from '@angular/common/http';
import {Component, OnInit, ViewChild} from '@angular/core';
import {FileDto} from '../../shared';
import {AgreementDetailsComponent} from '../../shared/agreement-details/agreement-details.component';
import {AgreementListComponent} from '../../shared/agreement-list/agreement-list.component';
import {AgreementModel} from '../../shared/dto/agreement.model';
import {CompanyModel} from '../../shared/dto/company.model';
import {NgBDatePickerConvertService} from '../../shared/services/ngb.datepicker.convert.service';
import {DialogService} from "../../shared/dialog/dialog.service";
import {TranslateService} from "@ngx-translate/core";
import {CanLeave, CanLeaveService, IsModified} from "../../shared/can-leave/can-leave.service";
import {Observable} from "rxjs";
import {LoadingOverlayService} from "../../shared/services/loading-overlay.service";
import {BootstrapAlertService} from '../../shared/services/bootstrap-alert.service';
import {SalesforceEntityType, SalesforceProcessAction} from '../../shared/dto/salesforce.type';
import {CutoffDeals} from '../../shared/dto/cutoff-deals-model';

@Component({
    selector: 'contract-admin',
    templateUrl: './contract.component.html',
    styles: []
})
export class ContractComponent implements OnInit, CanLeave {

    editMode: boolean;
    agreement: AgreementModel = new AgreementModel();
    agreements: AgreementModel[] = [];
    selectedCompany: CompanyModel;
    salesforceQuoteId: string;

    private _modified: IsModified.Type;

    @ViewChild(AgreementListComponent, {static: true}) agreementListComponent: AgreementListComponent;
    @ViewChild(AgreementDetailsComponent, {static: false}) agreementDetailsComponent: AgreementDetailsComponent;

    constructor(private http: HttpClient,
                private ngBDatePickerConvertService: NgBDatePickerConvertService,
                private dialogService: DialogService,
                private translate: TranslateService,
                private canLeaveService: CanLeaveService,
                private bootstrapAlertService: BootstrapAlertService,
                private loadingOverlay: LoadingOverlayService) {
    }

    ngOnInit() {
        this.canLeaveService.isModified()
            .subscribe((value: IsModified.Type) => {
                this._modified = value;
            });
    }

    canLeave(): Observable<boolean> {
        return this.canLeaveService.canLeave();
    }

    selectedCompanyChange() {
        this.agreementListComponent.refreshAgreementList(this.selectedCompany);
    }

    newAgreement() {
        this.agreement = new AgreementModel();
        this.agreement.partner = this.selectedCompany;
        this.editMode = true;
    }

    edit(agreement: AgreementModel) {
        this.agreement = agreement;
        this.editMode = true;
    }

    delete(agreementId: number) {
        this.dialogService.confirm(this.translate.instant('filter.basic.deleteDialog.title'), this.translate.instant('filter.basic.deleteDialog.message'))
            .subscribe((value: boolean): void => {
                if (value) {
                    this.deleteConfirmed(agreementId);
                }
            });
    }

    deleteConfirmed(agreementId: number): void {
        this.http.delete('api/agreement/' + agreementId)
            .subscribe(() => this.agreementListComponent.refreshAgreementList(this.selectedCompany));
    }

    close() {
        this.canLeaveService.canLeave()
            .subscribe((value) => {
                if (value) {
                    this.closeConfirmed();
                }
            });
    }

    private closeConfirmed() {
        this.editMode = false;
        this.agreementListComponent.refreshAgreementList(this.selectedCompany);
        this.canLeaveService.setModified(false);
        // this.setPodImportToDefault();
    }

    submit() {
        if (typeof this._modified === 'boolean') {
            this.canLeaveService.canLeave(() => {},
                this.translate.instant('agreement.confirmDialog.message'),
                this.translate.instant('filter.basic.confirmDialog.title'))
                .subscribe((v) => {
                    if (v) {
                        this.submitConfirmed();
                    }
                });
        } else {
            this.submitConfirmed();
        }
    }

    syncNewAgreementFromSalesforce(salesforceQuoteId?: string): void {
        this.loadingOverlay.turnOn();

        let params: HttpParams = new HttpParams();
        if (salesforceQuoteId) {
            params = params.set('salesforceId', salesforceQuoteId);
        }

        this.http.post<boolean>(`api/salesforce/query/${ SalesforceEntityType.QUOTE }`, {}, {params: params})
            .subscribe((hasNoValidationErrors: boolean): void => {
                this.loadingOverlay.turnOff();

                if (hasNoValidationErrors) {
                    this.showSyncSalesforceSuccess();
                } else {
                    this.showCutOffOfferClaims();
                }
            }, (): void => {
                this.loadingOverlay.turnOff();
            });
    }

    showCutOffOfferClaims(): void {
        this.http.get<CutoffDeals[]>(`api/salesforce/query/cutoff-deals`).subscribe((cutoffDeals: CutoffDeals[]) => {
            this.doProcessSalesforce(false);
            this.dialogService.showCutOffDeals(cutoffDeals).subscribe();
        });
    }

    syncExistingAgreementFromSalesforce(salesforceQuoteId: string): void {
        this.syncNewAgreementFromSalesforce(salesforceQuoteId);
    }

    doProcessSalesforce(continueProcess: boolean): void {
        this.loadingOverlay.turnOn();
        const processAction: SalesforceProcessAction = continueProcess
            ? SalesforceProcessAction.PROCESS
            : SalesforceProcessAction.ABORT;

        this.http.post<string>(`api/salesforce/process/${ SalesforceEntityType.QUOTE }/${ processAction }`, {})
            .subscribe((): void => {
                this.loadingOverlay.turnOff();
                if (processAction === SalesforceProcessAction.PROCESS) {
                    this.showSyncSalesforceSuccess();
                }
            }, (): void => {
                this.loadingOverlay.turnOff();
            });
    }

    private showSyncSalesforceSuccess(): void {
        this.bootstrapAlertService.showSuccess('agreement.sync.syncSuccess');

        if (this.selectedCompany) {
            this.agreementListComponent.refreshAgreementList(this.selectedCompany);
        }
    }

    private submitConfirmed(): void {
        if (this.agreement.productType !== "STEP_BY_STEP") {
            this.agreement.clicks = null;
            this.agreement.clickModification = null;
            this.agreement.clickFlexibilityUp = null;
            this.agreement.clickFlexibilityDown = null;
            this.agreement.maxTransactionSizeOnHudexTranche = null;
            this.agreement.automaticClosingProduct = null;
        }

        if (this.agreement.productType !== "CASH_OUT") {
            this.agreement.maxTotalHedgeCapacity = null;
            this.agreement.maxTransactionSizeOnHudex = null;
            this.agreement.minClickSizeOnHudex = null;
            this.agreement.minClickSizeOnOtc = null;
        }

        if (typeof this.agreement.hedgeLimit === 'string') {
            this.agreement.hedgeLimit = parseFloat(this.agreement.hedgeLimit.replace(/[^\d.-]/g, ''));
        }


        if (this.agreement.services.scheduling as any == '') {
            this.agreement.services.scheduling = null;
        }

        this.agreement.startTime = this.ngBDatePickerConvertService.convertToDate(this.agreement.startTimeNgB);
        this.agreement.endTime = this.ngBDatePickerConvertService.convertToDate(this.agreement.endTimeNgB);
        this.agreement.services.extensionDeadline = this.ngBDatePickerConvertService.convertToDate(this.agreement.services.extensionDeadlineNgB);
        this.agreement.services.extensionPeriodStart = this.ngBDatePickerConvertService.convertToDate(this.agreement.services.extensionPeriodStartNgB);
        this.agreement.services.extensionPeriodEnd = this.ngBDatePickerConvertService.convertToDate(this.agreement.services.extensionPeriodEndNgB);
        this.clearTempIds();

        if (this.agreement.id)
            this.http.put('api/agreement', this.agreement).subscribe(r => {
                    let saveAgreement = <AgreementModel>r;
                    this.attachFiles(saveAgreement);
                    this.agreementListComponent.refreshAgreementList(this.selectedCompany);
                },
                err => this.agreementListComponent.refreshAgreementList(this.selectedCompany));

        else
            this.http.post('api/agreement', this.agreement).subscribe(r => {
                    let saveAgreement = <AgreementModel>r;
                    this.attachFiles(saveAgreement);
                    this.agreementListComponent.refreshAgreementList(this.selectedCompany);
                },
                err => this.agreementListComponent.refreshAgreementList(this.selectedCompany));

        this.editMode = false;
        this.canLeaveService.setModified(false);
    }

    attachFiles(saveAgreement: AgreementModel) {
        if (this.agreement.contracts)
            for (let contract of this.agreement.contracts.filter(contractRecord => contractRecord.id > -1 && contractRecord.uploadedFiles)) {
                let data = new FormData();
                data.append('file', contract.uploadedFiles[0]);
                this.http.post(`api/agreement/${saveAgreement.id}/contract/${contract.id}/file`, data)
                    .subscribe(jsonResp => {
                        contract.files[0] = <FileDto>jsonResp;
                    });
            }
    }

    isAgreementInValid() {
        if (!this.agreementDetailsComponent)
            return false;

        return this.agreementDetailsComponent.servicesForm?.invalid || this.agreementDetailsComponent.form?.invalid;
    }

    private clearTempIds() {
        if (this.agreement.tolerancePeriods)
            this.agreement.tolerancePeriods.forEach(r => {
                if (r.id < 0)
                    r.id = null;
            });
        if (this.agreement.pods)
            this.agreement.pods.forEach(r => {
                if (r.id < 0)
                    r.id = null;
            });
        if (this.agreement.contracts)
            this.agreement.contracts.forEach(r => {
                if (r.id < 0)
                    r.id = null;
            });
    }
}
