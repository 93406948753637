import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {ValidationException} from '../dto/validation.exception.model';
import {FileSaverService} from '../services/file-saver.service';
import {ExcelImportResult} from './model/excel-import-result.model';
import {ExcelType} from './model/excel-type.enum';
import {TimeSeriesType} from './model/time-series-type.enum';
import {TimeSeries} from './model/timeseries';
import {TimeInterval} from './time-series-interval/time-interval.model';
import {DefaultTimeSeriesFormat} from './model/default-time-series-format.model';
import {AbstractControl} from '@angular/forms';
import {DateFormatUtil} from '../../core/util/date-format.util';

@Injectable({
    providedIn: 'root'
})
export class TimeSeriesService {

    constructor(private http: HttpClient,
                private fileSaver: FileSaverService,
                private translateService: TranslateService) {
    }

    getTimeSeries(timeSeriesType: TimeSeriesType, timeInterval: TimeInterval, agreementId: number): Observable<TimeSeries> {

        let agreementParams = agreementId ? new HttpParams().set('agreementId', agreementId.toString()) : new HttpParams();

        return this.http.post<TimeSeries>(`api/timeseries/query/${timeSeriesType}`, timeInterval, {params: agreementParams});
    }

    check(t: TimeInterval): Observable<void> {
        const params = new HttpParams()
            .set('resolution', t.resolution)
            .set('start', DateFormatUtil.formatToDashed(t.start))
            .set('end', DateFormatUtil.formatToDashed(t.end));
        return this.http.put<void>(`api/timeseries/timeinterval/check`, null, {params});
    }


    export(templateType: ExcelType, timeSeriesType: TimeSeriesType, timeInterval: TimeInterval, includeTimeSeries: boolean, params: { [param: string]: string } = {}) {

        this.fileSaver.save(
            'api/timeseries/excel-template',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            {
                timeInterval,
                timeSeriesType,
                templateType,
                params,
                includeTimeSeries
            },
            params
        );
    }

    saveDefaultFormat(form: AbstractControl, uiName: string, companyId: number): Observable<void> {

        const body: DefaultTimeSeriesFormat = {
            tsType: form.get('timeSeriesType').value,
            templateType: form.get('excelType').value,
            timeInterval: form.get('timeInterval').value,

            companyId,
            uiName
        };
        return this.http.put<void>(`api/timeseries/default-format/${uiName}`, body);
    }

    getDefaultFormat(uiName: string, companyId: number): Observable<DefaultTimeSeriesFormat> {
        const params = new HttpParams()
            .set('companyId', companyId.toString());
        return this.http.get<DefaultTimeSeriesFormat>(`api/timeseries/default-format/${uiName}`, {params});
    }

    activate(file: Blob, agreementId: number, hedgeParams: HttpParams = null): Observable<ExcelImportResult> {
        let agreementParams = agreementId ? new HttpParams().set('agreementId', agreementId.toString()) : new HttpParams();

        let formData = new FormData();

        formData.append('file', file);
        return this.http.post<ExcelImportResult>('api/timeseries/import', formData, {params: hedgeParams || agreementParams});
    }

    warningHandle(w: ValidationException): string {
        let message: string;

        if (w.violations.length === 1) {
            message = this.translateService.instant('validation.' + w.violations[0].errorCode, w.violations[0].args);
        } else {
            message = w.violations.map(violation => '<li>' + this.translateService.instant(
                `validation.${violation.errorCode}`,
                violation.args
            ) + '</li>').join('');
        }

        return message;
    }
}
