import {Pipe, PipeTransform} from "@angular/core";
import {DateFormatUtil} from '../../core/util/date-format.util';

@Pipe({
    name: 'cezYearMonth'
})
export class CezYearMonthPipe implements PipeTransform {

    public transform(date: any, verbose: boolean = false): string | null {
        return DateFormatUtil.formatToYearMonth(date, verbose);
    }

}
