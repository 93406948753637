import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BehaviorSubject, from, Observable} from 'rxjs';
import {
    OfferClaimReservationPeriodMapWithAveraging
} from './overview/position-report/offer-claim-reservation-period-map.model';
import {ProductType} from '../shared/agreement-details/agreement-enums.model';
import {DeliveryPeriodDto} from '../shared/dto/delivery-period-dto';
import {AgreementModel} from '../shared/dto/agreement.model';
import {CartItemDto} from '../shared/offer-claim/model/CartItemDto';
import {OfferClaimDto} from '../shared/offer-claim/model/OfferClaimDto';
import {PeriodDiagramDataDto} from '../shared/offer-claim/model/PeriodDiagramDataDto';
import {Market} from '../shared/offer-claim/model/market.enum';
import {BusinessCalendarInfo} from '../shared/offer-claim/model/business-calendar-info.model';
import {
    ProductAvailabilityModel,
    ProductAvailabilityQueryModel
} from '../shared/daily-rates/model/product-availability.model';
import {DailyRatesDto} from '../shared/daily-rates/model/DailyRatesDto';
import {AveragingTransactionDisplayModel} from '../shared/offer-claim/model/averaging-transaction-display.model';
import {PriceTrackerDto} from './price-monitoring/coverage-transactions-price-tracker.model';
import {OfferClaimWithTimeSeries} from '../shared/offer-claim/model/OfferClaimWithTimeSeries';
import {TimeSeries} from '../shared/time-series/model/timeseries';
import {MtmReportCashoutCalculationType, MtmReportDto} from './overview/mtm-report/coverage-transactions-mtm.model';
import {DeliveryPeriodParametersModel} from '../shared/parameter-viewer/parameter-viewer.model';
import {TimeSeriesForTranche} from '../shared/offer-claim/model/TimeSeriesForTranche';
import {SbsTrancheDto} from '../shared/offer-claim/model/SbsTrancheDto';
import {
    CoverageTransactionsOverview,
    CoverageTransactionsOverviewDto,
    CoverageTransactionsOverviewRequest
} from './overview/coverage-transactions-overview.model';
import {Resolution} from '../shared/time-series/model/resolution.enum';
import {ScheduledQuotationDto} from './scheduled-quotation-dialog/scheduled-quotation.types';
import {AvailableProductPeriodsDto} from '../shared/available-products/model/AvailableProductPeriodsDto';
import {
    AggregationTableDataModel,
    AggregationTableType
} from '../shared/aggregation-table-data/aggregation-table-data.model';
import {concatMap, tap} from 'rxjs/operators';
import {SaleStatus} from '../shared/offer-claim/model/sale-status.enum';
import * as _ from 'lodash';
import {StateStorageService} from '../shared/auth/state-storage.service';
import {Principal} from '../shared/auth/principal.service';
import {DailyPricesState} from '../shared/dto/daily-prices-state.model';
import {OpenPositionStateDTO} from './offer-claim/offer-claim-list/offer-claim-list.open-position-status.model';
import {OfferClaimFieldsForOpenPositionDTO} from './offer-claim/offer-claim-list/offer-claim-list.component';
import {
    AveragingTransactionDisplayForUserModel
} from '../shared/offer-claim/model/averaging-transaction-display-for-user.model';
import {UtilService} from '../shared/services/util.service';

@Injectable({providedIn: 'root'})
export class CoverageTransactionsService {

    public isCartItemInMultipleDeliveryPeriods: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(private http: HttpClient, private stateStorageService: StateStorageService, private principal: Principal) {
    }

    public getExistingReservationMapInMWhForAYear(periodEntityId: number, productType: ProductType, extractedOfferClaimIds: number[] = []): Observable<OfferClaimReservationPeriodMapWithAveraging> {
        const params: HttpParams = new HttpParams()
            .set('productType', productType)
            .set('extractedOfferClaimIds', extractedOfferClaimIds.join(', '));
        return this.http.get<OfferClaimReservationPeriodMapWithAveraging>('/api/offer-claim/' + periodEntityId + '/reservation-map-mwh', {params});
    }

    public getExistingReservationCapacityMapInMWForAYear(periodEntityId: number, productType: ProductType, extractedOfferClaimIds: number[] = []): Observable<OfferClaimReservationPeriodMapWithAveraging> {
        const params: HttpParams = new HttpParams()
            .set('productType', productType)
            .set('extractedOfferClaimIds', extractedOfferClaimIds.join(', '));
        return this.http.get<OfferClaimReservationPeriodMapWithAveraging>('/api/offer-claim/' + periodEntityId + '/reservation-map-capacity-mw', {params});
    }

    public getOfferClaimReservationPeriodMapInTranche(periodEntityId: number, productType: ProductType, extractedOfferClaimIds: number[] = []): Observable<OfferClaimReservationPeriodMapWithAveraging> {
        const params: HttpParams = new HttpParams()
            .set('productType', productType)
            .set('extractedOfferClaimIds', extractedOfferClaimIds.join(', '));
        return this.http.get<OfferClaimReservationPeriodMapWithAveraging>('/api/offer-claim/' + periodEntityId + '/reservation-map-tranche', {params});
    }

    public finalizingPurchase(claims: OfferClaimDto[]): Observable<void> {
        return this.http.post<void>('api/offer-claim/finalizing-purchase', claims);
    }

    public deleteOfferClaimById(offerClaimId: number[]): Observable<Object> {
        return this.http.delete('api/offer-claim/' + offerClaimId)
            .pipe(tap(() => this.refreshCartVisibility()));
    }

    public deleteOfferClaimForAdminById(offerClaimId: number): Observable<Object> {
        return this.http.delete(`api/offer-claim/${offerClaimId}/admin`);
    }

    public getDeliveryPeriods(selectedCompanyId: number): Observable<DeliveryPeriodDto[]> {
        return this.http.get<DeliveryPeriodDto[]>('/api/delivery-periods/' + selectedCompanyId);
    }

    public getAvailableDeliveryPeriods(selectedCompanyId: number): Observable<DeliveryPeriodDto[]> {
        return this.http.get<DeliveryPeriodDto[]>('/api/delivery-periods/available/' + selectedCompanyId);
    }

    public getAgreementsByPartner(selectedCompanyId: number, includePreviousYear: boolean = false): Observable<AgreementModel[]> {
        return this.http.get<AgreementModel[]>(
            `api/agreements/partner/${selectedCompanyId}/${includePreviousYear}/products/${ProductType.CASH_OUT},${ProductType.STEP_BY_STEP}`
        );
    }

    public getOfferClaimsByPartnerId(partnerId: number): Observable<CartItemDto[]> {
        return this.http.get<CartItemDto[]>(`api/offer-claim/${partnerId}/cart-items`);
    }

    public getPeriodCalculation(neededAfterTrade: boolean, deliveryPeriodId: number): Observable<PeriodDiagramDataDto> {
        const params = new HttpParams()
            .set('neededAfterTrade', String(neededAfterTrade));
        return this.http.get<PeriodDiagramDataDto>('/api/offer-claim/period-calculation/' + deliveryPeriodId, {params});
    }

    public getPeriodCalculationByResolution(neededAfterTrade: boolean, deliveryPeriodId: number, resolution: Resolution): Observable<PeriodDiagramDataDto> {
        const params = new HttpParams()
            .set('neededAfterTrade', String(neededAfterTrade));
        return this.http.get<PeriodDiagramDataDto>('/api/offer-claim/period-calculation/' + deliveryPeriodId + '/' + resolution, {params});
    }

    public getPeriodCalculationByClaims(offerClaims: OfferClaimDto[]): Observable<PeriodDiagramDataDto> {
        return this.http.post<PeriodDiagramDataDto>('/api/offer-claim/period-calculation', offerClaims);
    }

    public getPeriodCalculationByClaimsByResolution(offerClaims: OfferClaimDto[], resolution: Resolution): Observable<PeriodDiagramDataDto> {
        return this.http.post<PeriodDiagramDataDto>('/api/offer-claim/period-calculation/' + resolution, offerClaims);
    }

    public requestForQuotation(scheduledQuotation: ScheduledQuotationDto): Observable<void> {
        return this.http.post<void>('api/offer-claim/request-for-quotation', scheduledQuotation);
    }

    public getOfferClaimsByDeliveryPeriodId(deliveryPeriodId: number): Observable<OfferClaimDto[]> {
        return this.http.get<OfferClaimDto[]>('api/offer-claim/' + deliveryPeriodId);
    }

    public revokeRequestForQuotationByDeliveryPeriodId(deliveryPeriodId: number): Observable<OfferClaimDto[]> {
        return this.http.delete<OfferClaimDto[]>(`api/offer-claim/period/${deliveryPeriodId}/revoke-request-for-quotation`);
    }

    public revokeRequestForQuotationFromCart(partnerId: number): Observable<OfferClaimDto[]> {
        return this.http.delete<OfferClaimDto[]>(`api/offer-claim/partner/${partnerId}/cart/revoke-request-for-quotation`);
    }

    public hasRequestForQuotationByPartner(partnerId: number): Observable<boolean> {
        return this.http.get<boolean>(`api/offer-claim/partner/${partnerId}/has-request-for-quotation`);
    }

    public getAverageQuantity(timeSeries: TimeSeries): Observable<number> {
        return this.http.post<number>('api/offer-claim/averageQuantity', timeSeries);
    }

    public getTrancheFromSeries(timeSeriesForTranche: TimeSeriesForTranche): Observable<number> {
        return this.http.post<number>('api/offer-claim/trancheFromSeries', timeSeriesForTranche);
    }

    public isMarketOpen(market: Market, hudexAccess: boolean, hudexD1Access: boolean): Observable<boolean> {
        let params = null;
        if (market == Market.HUDEX) {
            params = new HttpParams()
                .set('hudexAccess', String(hudexAccess))
                .set('hudexD1Access', String(hudexD1Access));
        }
        return this.http.get<boolean>(`api/offer-claim/is-market-open/${market}`, {params});
    }

    public getBusinessCalendarAllowance(businessCalendarInfo: BusinessCalendarInfo): Observable<Boolean> {
        return this.http.post<Boolean>('/api/business-calendar', businessCalendarInfo);
    }

    public getExistingTranche(periodEntityId: number, params: { [param: string]: string }): Observable<number> {
        return this.http.get<number>('/api/offer-claim/' + periodEntityId + '/existing-tranche', {params: params});
    }

    public updateClaim(claim: OfferClaimWithTimeSeries): Observable<OfferClaimDto> {
        return this.http.put<OfferClaimDto>('/api/offer-claim', claim)
            .pipe(tap(() => this.refreshCartVisibility()));
    }

    public createClaim(claim: OfferClaimWithTimeSeries): Observable<OfferClaimDto> {
        return this.http.post<OfferClaimDto>('/api/offer-claim', claim)
            .pipe(tap(() => this.refreshCartVisibility()));
    }

    public validateClaim(claim: OfferClaimWithTimeSeries): Observable<OfferClaimDto> {
        return this.http.post<OfferClaimDto>('/api/offer-claim/validate', claim);
    }

    public validateFinalizingPurchase(claims: OfferClaimDto[]): Observable<void> {
        return this.http.post<void>('/api/offer-claim/validate-finalizing-purchase', claims);
    }

    public getProductAvailability(productQueryDto: ProductAvailabilityQueryModel): Observable<ProductAvailabilityModel> {
        return this.http.post<ProductAvailabilityModel>(`api/daily-rates/product-availability`, productQueryDto);
    }

    public getProductsByDeliveryPeriod(deliveryId: number, includePreviousPrices: boolean = false): Observable<DailyRatesDto[]> {
        const params: HttpParams = new HttpParams().set('includePreviousPrices', includePreviousPrices.toString());
        return this.http.get<DailyRatesDto[]>(`/api/daily-rates/current/by-delivery-period/${deliveryId}`, {params});
    }

    public getAllProductsByDeliveryPeriod(deliveryId: number): Observable<DailyRatesDto[]> {
        return this.http.get<DailyRatesDto[]>(`/api/daily-rates/all/by-delivery-period/${deliveryId}`);
    }

    public getAveragingTransactions(): Observable<AveragingTransactionDisplayModel[]> {
        return this.http.get<AveragingTransactionDisplayModel[]>(`api/offer-claim/admin/averaging-items`);
    }

    public getOfferClaimApprovedByPartnerAndDeliveryPeriod(partner: number, deliveryPeriod: number): Observable<AveragingTransactionDisplayForUserModel[]> {
        return this.http.get<AveragingTransactionDisplayForUserModel[]>(`api/offer-claim/averaging-items/${partner}/${deliveryPeriod}`);
    }

    public stopAveragingTransaction(averagingTransactionId: number): Observable<void> {
        return this.http.put<void>(`api/offer-claim/averaging-items/stop`, {id: averagingTransactionId});
    }

    public getDailyPricesDeadline(): Observable<number> {
        return this.http.get<number>('/api/daily-prices-state/deadline');
    }

    public getDailyState(): Observable<DailyPricesState> {
        return this.http.get<DailyPricesState>('/api/daily-prices-state');
    }

    public getLastWeekPrices(dailyRates: DailyRatesDto[]): Observable<{ [key: string]: number[] }> {
        return this.http.post<{ [key: string]: number[] }>('/api/daily-rates/last-week-prices', dailyRates);
    }

    public getDeliveryPeriodParameters(deliveryPeriodId): Observable<DeliveryPeriodParametersModel> {
        return this.http.get<DeliveryPeriodParametersModel>(`api/delivery-period/parameters/${deliveryPeriodId}`);
    }

    public getQuantityFromTranche(sbsTrancheDto: SbsTrancheDto): Observable<number> {
        return this.http.post<number>('api/offer-claim/quantity-from-tranche', sbsTrancheDto);
    }

    public getOverview(requests: CoverageTransactionsOverviewRequest[]): Observable<CoverageTransactionsOverviewDto[]> {
        return this.http.post<CoverageTransactionsOverviewDto[]>(`api/offer-claim/overview`, requests, {});
    }

    public getAvailableProductPeriods(): Observable<AvailableProductPeriodsDto[]> {
        return this.http.get<AvailableProductPeriodsDto[]>('api/available-product-periods/');
    }

    public getAggregationTableData(deliveryPeriodId: string, aggregationTableType: AggregationTableType): Observable<AggregationTableDataModel> {
        const params: HttpParams = new HttpParams()
            .set('aggregationTableType', aggregationTableType.toString());
        return this.http.get<AggregationTableDataModel>('/api/offer-claim/aggregation-table-data/' + deliveryPeriodId, {params});
    }

    // ---- price monitoring

    public getPriceTrackers(): Observable<PriceTrackerDto[]> {
        return this.http.get<PriceTrackerDto[]>('api/price-tracker');
    }

    public validatePriceTracker(priceTracker: PriceTrackerDto): Observable<PriceTrackerDto> {
        return this.http.post<PriceTrackerDto>('api/price-tracker/validate', priceTracker);
    }

    public getDailyRateNames(year: number): Observable<string[]> {
        return this.http.get<string[]>(`api/price-tracker/daily-rate-names/${year}`);
    }

    public getDailyRateNamesForOfferClaim(year: number): Observable<string[]> {
        return this.http.get<string[]>(`api/offer-claim/daily-rate-names/${year}`);
    }

    public getDailyRateNamesForAdminOfferClaim(deliveryPeriodId: number): Observable<string[]> {
        return this.http.get<string[]>(`api/offer-claim/daily-rate-names-by-delivery-period/${deliveryPeriodId}`);
    }

    public createPriceTracker(tracker: PriceTrackerDto): Observable<void> {
        return this.http.post<void>('api/price-tracker', tracker);
    }

    public updatePriceTracker(tracker: PriceTrackerDto): Observable<void> {
        return this.http.put<void>('api/price-tracker', tracker);
    }

    public deletePriceTracker(trackerId: number): Observable<void> {
        return this.http.delete<void>(`api/price-tracker/${trackerId}`);
    }

    // ---- mtm report

    public getMtmSummary(deliveryPeriodId: number): Observable<MtmReportDto[]> {
        return this.http.get<MtmReportDto[]>(`api/mtm-report/summary/${deliveryPeriodId}`);
    }

    public getMtmDetails(deliveryPeriodId: number, calculationType?: MtmReportCashoutCalculationType): Observable<MtmReportDto[]> {
        let params: HttpParams = new HttpParams();
        if (calculationType) {
            params = params.set('calculationType', calculationType);
        }

        return this.http.get<MtmReportDto[]>(`api/mtm-report/details/${deliveryPeriodId}`, {params: params});
    }

    public getMtmDetailsByClaims(deliveryPeriodId: number, offerClaims: OfferClaimDto[]): Observable<PeriodDiagramDataDto> {
        return this.http.post<PeriodDiagramDataDto>(`api/mtm-report/details/${deliveryPeriodId}`, offerClaims);
    }

    public getAcceptedHedgeLimitDetails(offerClaims: OfferClaimFieldsForOpenPositionDTO[]): Observable<OpenPositionStateDTO> {
        return this.http.post<OpenPositionStateDTO>(`/api/offer-claim-used-fund/accepted-hege-limit-details`, offerClaims);
    }

    public initCartVisibility(): void {
        if (this.principal.isAuthenticated() && this.hasCoverageTransactionsReadPermission()) {
            this.refreshCartVisibility();
        }
    }

    private refreshCartVisibility(): void {
        if (!!this.stateStorageService.getSelectedCompanyId()) {
            this.getOfferClaimsByPartnerId(this.stateStorageService.getSelectedCompanyId()).subscribe((cartItems: CartItemDto[]) => {
                cartItems = cartItems.filter((cartItem: CartItemDto) => cartItem.saleStatus !== SaleStatus.ACCEPTED);
                let numberOfDeliveryPeriods: number = 0;
                if (cartItems && cartItems.length && cartItems.length > 0) {
                    numberOfDeliveryPeriods = _.keys(_.groupBy(cartItems, 'deliveryPeriodId')).length;
                }
                this.isCartItemInMultipleDeliveryPeriods.next(numberOfDeliveryPeriods > 1);
            });
        }
    }

    private hasCoverageTransactionsReadPermission(): boolean {
        let permissions = this.stateStorageService.getEffectivePermissions();
        return permissions.indexOf('HEDGE_TRANSACTIONS_READ') > -1 && permissions.indexOf('HEDGE_TRANSACTIONS_SCREEN') > -1;
    }

    /**
     * Loads mtm unit prices asynchronously for CoverageTransactionsOverview.
     *
     * @param coverageTransactionsOverviews Array is mutated. The coverage transaction overviews that are enriched with the loaded mtm unit prices
     * @param selectedDeliveryPeriodId If supplied you want to reload unit prices only for the selected delivery period, undefined if we want to reload all unit prices
     * */
    public loadMtmUnitPrices(coverageTransactionsOverviews: CoverageTransactionsOverview[], selectedDeliveryPeriodId?: number): void {
        from(coverageTransactionsOverviews
            .filter((overview: CoverageTransactionsOverview): boolean => {
                if (!selectedDeliveryPeriodId) {
                    // if no value is supplied, all mtm prices will be refreshed
                    return true;
                }
                return overview.deliveryPeriodDto.id === selectedDeliveryPeriodId;
            }))
            .pipe(
                concatMap((item: CoverageTransactionsOverview): any[] => {
                    item.mtmUnitPrice = null;
                    this.getMtmSummary(item.deliveryPeriodDto.id).subscribe((mtmReports: MtmReportDto[]): void => {
                        const grandTotal: MtmReportDto[] = mtmReports.filter((report: MtmReportDto): boolean => report.type === 'GrandTotal');
                        item.mtmUnitPrice = grandTotal.length === 1 ? grandTotal[0].unitPrice || 0 : 0;

                        if (item.productType === ProductType.CASH_OUT) {
                            const currentValue: number = this.calculateProgressbarValue(mtmReports, item);
                            item.progressBarConfig.currentValue = currentValue ? currentValue : 0;
                        }
                    });
                    return [];
                })
            ).subscribe();
    }

    private calculateProgressbarValue(mtmReports: MtmReportDto[], item: CoverageTransactionsOverview): number {
        // fix forward + closed buy - closed sell
        const fixedForwardMtm: MtmReportDto[] = mtmReports.filter((report: MtmReportDto): boolean => report.type === 'FixedForward');
        const closedBuyMtm: MtmReportDto[] = mtmReports.filter((report: MtmReportDto): boolean => report.type === 'ClosedBuy');
        const closedSellMtm: MtmReportDto[] = mtmReports.filter((report: MtmReportDto): boolean => report.type === 'ClosedSell');

        const fixedForward: number = fixedForwardMtm.length === 1 ? fixedForwardMtm[0].quantity : 0;
        const closedBuy: number = closedBuyMtm.length === 1 ? closedBuyMtm[0].quantity : 0;
        const closedSell: number = closedSellMtm.length === 1 ? closedSellMtm[0].quantity : 0;
        const absolutResult: number = (Math.abs(fixedForward) + Math.abs(closedBuy) - Math.abs(closedSell)) / item.quantity;

        return +UtilService.round(absolutResult * 100, 2);
    }
}
