import {Pipe, PipeTransform} from "@angular/core";
import {DateFormatUtil} from '../../core/util/date-format.util';

@Pipe({
    name: 'cezNgbDate'
})
export class CezNgbDatePipe implements PipeTransform {

    public transform(date: any): Date | null {
        return DateFormatUtil.transformNgbToDate(date);
    }

}
