import {Component, Inject} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';

@Component({
    selector: 'rts-confirm',
    templateUrl: './confirm.component.html',
    styles: []
})
export class ConfirmComponent {

    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

}
