import {ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import {Resolution} from '../model/resolution.enum';
import {TimeInterval} from './time-interval.model';
import * as moment from 'moment';
import {Moment} from 'moment';
import {Observable} from 'rxjs';
import {MeasureUnit} from './measure-unit.enum';
import {DeliveryPeriodDto} from "../../dto/delivery-period-dto";
import {DeliveryPeriodUtil} from "../../delivery-period-list/delivery-period.util";
import {HState} from "../model/state-type.enum";


@Component({
    selector: 'cez-time-interval',
    templateUrl: './time-interval.component.html',
    styleUrls: ['./time-interval.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TimeIntervalComponent),
            multi: true
        }
    ]
})
export class TimeIntervalComponent implements ControlValueAccessor, OnInit {

    @Input() min = moment("2000-01-01");
    @Input() max = moment().add(100, 'years').startOf('day');
    @Input() resolutions: Resolution[] = [Resolution.QUARTER_HOURLY, Resolution.HOURLY, Resolution.DAILY];
    @Input() isExpectedPlan: boolean = false;
    @Input() isHedgeConsumptionPlan: boolean = false;
    @Input() clearInputValues: Observable<boolean>;
    @Input() hideResolution: boolean = false;
    @Input() allowDifferentMeasureUnits: boolean = false;
    @Input() deliveryPeriod: DeliveryPeriodDto;
    @Output() expectedPlanChange: EventEmitter<string> = new EventEmitter();
    @Output() sumYearlyValueChange: EventEmitter<number> = new EventEmitter();
    @Output() sumMonthlyValueChange: EventEmitter<number> = new EventEmitter();

    form: UntypedFormGroup = this.fb.group({
        start: [null, Validators.required],
        end: [null, Validators.required],
        resolution: [null, Validators.required],
        measureUnit: [null, Validators.required],
        sumYearly: [null],
        sumMonthly: [null]
    });

    onChange: Function;
    onTouched: Function;

    expectedPlanProps: string[] = ['sumYearly', 'sumMonthly', 'expectedPlan'];
    selectedExpectedPlan: string = this.expectedPlanProps[0];
    selectedTimeInterval: TimeInterval;
    measureUnits: MeasureUnit[] = [];

    constructor(private fb: UntypedFormBuilder,
                private changeDetector: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.form.controls['sumYearly'].disable({emitEvent: false});
        this.form.controls['sumMonthly'].disable({emitEvent: false});
        this.formValueChanges();

        if (this.isHedgeConsumptionPlan) {
            this.valueChanges(this.form.controls['sumYearly'].valueChanges, 1500, 'sumYearly');
            this.valueChanges(this.form.controls['sumMonthly'].valueChanges, 1500, 'sumMonthly');

            this.clearInputValueChanges();
            this.resolutionValueChanges();
        }
    }

    private resolutionValueChanges() {
        this.form.controls['resolution'].valueChanges.subscribe((resolution: Resolution) => {
            if (resolution === Resolution.MONTHLY) {
                this.form.controls.start.setValue(this.min, {emitEvent: false});
                this.form.controls.end.setValue(this.max, {emitEvent: false});

                var state: HState = DeliveryPeriodUtil.getState(this.deliveryPeriod);
                switch (state) {
                    case "ONE":
                        this.expectedPlanProps = ['sumMonthly', 'expectedPlan'];
                        this.selectedExpectedPlan = this.expectedPlanProps[0];
                        break;
                    case "TWO":
                        this.expectedPlanProps = ['expectedPlan'];
                        this.selectedExpectedPlan = this.expectedPlanProps[0];
                        break;
                    case "THREE":
                        this.expectedPlanProps = ['sumYearly', 'expectedPlan'];
                        this.selectedExpectedPlan = this.expectedPlanProps[0];
                        break;
                    case "FOUR":
                        this.expectedPlanProps = [];
                        this.selectedExpectedPlan = null;
                        break;
                }

                this.expectedPlanSelectionChange(this.selectedExpectedPlan);

            }
            this.setDefaultMeasureUnits();
        });
    }

    private clearInputValueChanges() {
        this.clearInputValues.subscribe((value: boolean) => {
            if (value) {
                this.form.controls['sumYearly'].setValue(null, {emitEvent: false});
                this.form.controls['sumMonthly'].setValue(null, {emitEvent: false});
            }

            this.changeDetector.markForCheck();
        });
    }

    private formValueChanges() {
        this.form.valueChanges
            .subscribe(t => {
                if (this.isHedgeConsumptionPlan && t.resolution === Resolution.MONTHLY) {
                    this.form.controls['sumYearly'].enable({emitEvent: false});
                } else if (this.isHedgeConsumptionPlan && t.resolution !== Resolution.MONTHLY) {
                    this.form.controls['sumYearly'].disable({emitEvent: false});
                    this.form.controls['sumMonthly'].disable({emitEvent: false});
                }

                const currentTimeInterval: TimeInterval = {
                    resolution: t.resolution,
                    start: t.start.clone(),
                    end: t.end.clone().add(1, 'day'),
                    measureUnit: t.measureUnit
                };

                if (!this.selectedTimeInterval || this.selectedTimeInterval.resolution !== currentTimeInterval.resolution ||
                    !this.selectedTimeInterval.start.isSame(currentTimeInterval.start) ||
                    !this.selectedTimeInterval.end.isSame(currentTimeInterval.end) ||
                    this.selectedTimeInterval.measureUnit !== currentTimeInterval.measureUnit) {
                    this.onChange({
                        resolution: t.resolution,
                        start: t.start.clone(),
                        end: t.end.clone().add(1, 'day'),
                        sumYearly: null,
                        sumMonthly: null,
                        measureUnit: t.measureUnit
                    } as TimeInterval);
                }

                this.selectedTimeInterval = currentTimeInterval;
            });
    }

    startDateChanged(startDate: Moment) {
        if (startDate && (!this.form.controls.end.value || startDate.isAfter(this.form.controls.end.value))) {
            this.form.controls.end.setValue(startDate.clone());
        }
    }

    endDateChanged(endDate: Moment) {
        if (endDate && (!this.form.controls.start.value || endDate.isBefore(this.form.controls.start.value))) {
            this.form.controls.start.setValue(endDate.clone());
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
    }

    writeValue(t: TimeInterval): void {
        if (t) {
            this.form.setValue(
                {
                    resolution: t.resolution,
                    start: t.start,
                    end: t.end.clone().subtract(1, 'day'),
                    sumYearly: null,
                    sumMonthly: null,
                    measureUnit: t.measureUnit || null
                } as TimeInterval,
                {emitEvent: false});
            this.setDefaultMeasureUnits();
        }
    }

    expectedPlanSelectionChange(e: string): void {
        if (this.expectedPlanProps) {
            this.expectedPlanChange.emit(e);

            this.expectedPlanProps.forEach(p => {
                    if (p === e) {
                        if (p !== 'expectedPlan' && this.form.controls[p]) {
                            this.form.controls[p].enable({emitEvent: false})
                        }
                    } else if (this.form.controls[p]) {
                        this.form.controls[p].disable({emitEvent: false})
                    }
                }
            );
        }
    }

    measureUnitInputHidden() {
        return this.allowDifferentMeasureUnits === false || this.monthlyResolution();
    }

    private monthlyResolution(): boolean {
        if (this.form.controls.resolution) {
            return this.form.controls.resolution.value === Resolution.MONTHLY;
        }
        return false;
    }

    private valueChanges(value: Observable<any>, dueTime: number = 0, type: string) {
        value.pipe(
            distinctUntilChanged(),
            debounceTime(dueTime),
            tap(v => {
                if (v !== null && type === 'sumYearly') {
                    this.sumYearlyValueChange.emit(v);
                } else if (v !== null && type === 'sumMonthly') {
                    this.sumMonthlyValueChange.emit(v);
                }
            })
        ).subscribe();
    }

    private setDefaultMeasureUnits() {
        const resolution: Resolution = this.form.controls['resolution'].value;
        if (this.allowDifferentMeasureUnits) {
            if (resolution === Resolution.QUARTER_HOURLY) {
                this.measureUnits = [MeasureUnit.KW, MeasureUnit.MW];
            } else if (resolution === Resolution.HOURLY) {
                this.measureUnits = [MeasureUnit.KW, MeasureUnit.KWH, MeasureUnit.MW, MeasureUnit.MWH];
            } else {
                this.measureUnits = [];
            }
        }
        if (this.isHedgeConsumptionPlan) {
            if (resolution === Resolution.QUARTER_HOURLY || resolution === Resolution.HOURLY) {
                this.form.controls.measureUnit.setValue(MeasureUnit.MW, {emitEvent: false});
            } else {
                this.form.controls.measureUnit.setValue(null, {emitEvent: false});
            }
        }
    }
}
