import {Component, Inject} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';
import {CutoffDeals} from '../../dto/cutoff-deals-model';
import {OfferClaimDto} from '../../offer-claim/model/OfferClaimDto';
import {OfferPeriod} from '../../offer-claim/model/offer-period.enum';
import * as  _ from 'lodash';
import {TranslateService} from '@ngx-translate/core';
import {Clipboard} from '@angular/cdk/clipboard';
import {BootstrapAlertService} from '../../services/bootstrap-alert.service';

@Component({
    selector: 'cutoff-deals',
    templateUrl: './cutoff-deals.component.html',
    styleUrl: './cutoff-deals.component.scss'
})
export class CutoffDealsComponent {

    constructor(@Inject(MAT_DIALOG_DATA) public data: CutoffDeals[],
                private translate: TranslateService,
                private clipboard: Clipboard,
                private bootstrapAlertService: BootstrapAlertService) {
    }

    public getProductName(offerClaim: OfferClaimDto): string {
        const periodNumber: any = _.isNil(offerClaim.periodNumber) ? '' : offerClaim.periodNumber;
        const period: string = offerClaim.period === OfferPeriod.Y ? 'YR' : `${offerClaim.period}${periodNumber}`;
        return `${period}-${new Date(offerClaim.startDate).getFullYear()} HU ${offerClaim.type}`;
    }

    public getPurchaseStatus(offerClaim: OfferClaimDto): string {
        if (offerClaim.averagingTransaction) {
            return this.translate.instant('offerClaimHistory.averagingPurchaseStatus.' + offerClaim.purchaseStatus);
        } else {
            return this.translate.instant('offerClaimHistory.purchaseStatus.' + offerClaim.purchaseStatus);
        }
    }

    public copyToClipboard(claims: OfferClaimDto[]): void {
        const clipboard: string = claims
            .map((claim: OfferClaimDto): string => this.formatToClipBoard(claim))
            .join('\n');

        if (this.clipboard.copy(clipboard)) {
            this.bootstrapAlertService.showSuccess(this.translate.instant('global.copiedToClipboard'));
        }
    }

    private formatToClipBoard(claim: OfferClaimDto): string {
        return `${claim.dealId};${this.getProductName(claim)};${this.getPurchaseStatus(claim)};`;
    }

}
