import {DeliveryPeriodDto} from '../dto/delivery-period-dto';
import {DateUtil} from "../../core/util/date.util";
import moment, {Moment} from "moment";
import {HState} from "../time-series/model/state-type.enum";

export class DeliveryPeriodUtil {


    static isInThePast(deliveryPeriod: DeliveryPeriodDto) {
        return !DateUtil.isNowBeforeDate(deliveryPeriod.endTime);
    }

    static isRunning(deliveryPeriod: DeliveryPeriodDto) {
        return DateUtil.isNowInTheInterval(deliveryPeriod.startTime, deliveryPeriod.endTime)
    }

    static getNumberOfRemainingCompleteMonthsFromNow(deliveryPeriod: DeliveryPeriodDto) {
        var tilEndTime: Moment = moment(deliveryPeriod.endTime);

        // Calculate the difference in months between now and tilEndTime
        const monthsDifference: number = tilEndTime.diff(moment(), 'months', true);

        // Return only the complete months by flooring the value
        return Math.floor(monthsDifference);
    }

    static deliveryPeriodIsACalendarYear(deliveryPeriod: DeliveryPeriodDto): boolean {
        var start: Moment = moment(deliveryPeriod.startTime);
        var end: Moment = moment(deliveryPeriod.endTime);

        // Check if the start is January 1st and end is December 31st of the same year
        const isStartJanuaryFirst = start.month() === 0 && start.date() === 1;
        const isEndDecemberThirtyFirst = end.month() === 11 && end.date() === 31;
        const isSameYear = start.year() === end.year();

        return isStartJanuaryFirst && isEndDecemberThirtyFirst && isSameYear;

    }

    static isInFuture(deliveryPeriod: DeliveryPeriodDto): boolean {
        return moment(deliveryPeriod.startTime).isAfter(moment());
    }

    /**
     * Amennyiben olyan szállítási időszakról van szó, amiben már benne vagyunk (a nap, amikor a kiértékelés történik, beleesik a szállítási időszakba), és a szállítási időszakból még visszamarad legalább két teljes módosítható hónap, akkor csak a  „Hátralévő időszaki szumma” (jelenleg “hátrelvő szumma”, ezt cserélni kell)és „Havi terv” (jelenleg “várható terv” ezt cserélni kell) két funkció/legördülő opció legyen csak aktív.
     *
     * Amennyiben olyan szállítási időszakról van szó, amiben már benne vagyunk (a nap, amikor a kiértékelés történik, beleesik a szállítási időszakba), és a szállítási időszakból már csak egy teljes módosítható hónap van hátra, akkor csak egy funkció/legördülő opció legyen aktív:  „Havi terv”.
     *
     * Amennyiben olyan szállítási időszakról van szó, ami teljes egészében a jövőre vonatkozik, (a nap, amikor a kiértékelés történik, múltnak minősül a szállítási időszakhoz képest), és a szállítási időszak egybeesik egy teljes kalendáriumi évvel, akkor teljesüljön a Saci által írt második pont, tehát, hogy a legördülő opciók: „Éves szumma” és „Havi terv” jelenjenek meg.
     *
     * Amennyiben olyan szállítási időszakról van szó, ami teljes egészében a jövőre vonatkozik, (a nap, amikor a kiértékelés történik, múltnak minősül a szállítási időszakhoz képest), de a szállítási időszak nem esik egybe egy teljes kalendáriumi évvel, annál rövidebb, akkor a legördülő opciók közül csak ez legyen aktív: „Havi terv”.
     *
     * Amennyiben a vizsgált szállítási időszak teljes egészében már a múltban van, egyik opció se jelenjen meg, hiszen a mindenkori cél, hogy csak a jövő időszakra vonatkozóan tudjunk tervet módosítani.
     *
     *
     * */

    static getState(deliveryPeriod: DeliveryPeriodDto): HState {

        if (DeliveryPeriodUtil.isRunning(deliveryPeriod) && DeliveryPeriodUtil.getNumberOfRemainingCompleteMonthsFromNow(deliveryPeriod) >= 2) {
            return HState.ONE;
        }

        if ((DeliveryPeriodUtil.isRunning(deliveryPeriod) && DeliveryPeriodUtil.getNumberOfRemainingCompleteMonthsFromNow(deliveryPeriod) == 1) ||
            (DeliveryPeriodUtil.isInFuture(deliveryPeriod) && !DeliveryPeriodUtil.deliveryPeriodIsACalendarYear(deliveryPeriod))) {
            return HState.TWO;
        }

        if (DeliveryPeriodUtil.isInFuture(deliveryPeriod) && DeliveryPeriodUtil.deliveryPeriodIsACalendarYear(deliveryPeriod)) {
            return HState.THREE;
        }

        if (DeliveryPeriodUtil.isInThePast(deliveryPeriod)) {
            return HState.FOUR;
        }
    }
}
