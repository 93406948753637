import {DecimalPipe} from '@angular/common';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Chart} from 'angular-highcharts';
import {ToleranceData} from './tolerance-data.model';
import * as moment from 'moment';
import {DiagramService} from '../shared/services/diagram.service';
//import HC_annotations from 'highcharts/modules/annotations';
import {SeriesOptionsType} from 'highcharts';
import {DateFormatUtil} from '../core/util/date-format.util';

//HC_annotations(Highcharts);
@Injectable()
export class ToleranceChartService {

  chart: Chart;


  constructor(private translate: TranslateService,
              private diagramService: DiagramService,
              private decimalPipe: DecimalPipe) {
  }


  public create(): Chart {
    const _this = this;
    this.chart = new Chart({
      chart: {
        events: this.diagramService.getWatermark(false),
        zooming: {
          type: 'x'
        },
        resetZoomButton: {
          theme: {
            opacity: 0.3,
            states: {
              hover: {
                opacity: 1
              }
            }
          }
        }
      },
      boost: {
        enabled: false
      },
      xAxis: {
        tickInterval: 1000 * 60 * 60 * 24 * 30,
        type: 'datetime',
        labels: {
          formatter: function () {
            return DateFormatUtil.formatToYearMonth(new Date(this.value));
          }
        }
      },
      yAxis: [
        {
          id: 'yAxis-energy',
          softMin: 0,
          tickAmount: 9,
          alignTicks: false,
          title: {
            text: this.translate.instant('toleranceThreshold.chart.energy')
          }
        },
        {
          id: 'yAxis-percentage',
          min: 0,
          max: 160,
          tickInterval: 20,
          tickAmount: 9,
          alignTicks: false,
          title: {
            text: this.translate.instant('toleranceThreshold.chart.value')
          },
          plotBands: [{
            color: 'rgba(255, 0, 0, 0.1)'
          }],
          plotLines: [
            {
              color: '#ff0000',
              dashStyle: 'ShortDash',
              width: 2,
              label: {
                text: this.translate.instant('toleranceThreshold.chart.warnUpper'),
                align: 'left'
              },
              zIndex: 5
            },
            {
              color: '#ff0000',
              dashStyle: 'ShortDash',
              width: 2,
              label: {
                text: this.translate.instant('toleranceThreshold.chart.warnLower'),
                align: 'left',
                y: 12
              },
              zIndex: 5
            }
          ],
          opposite: true
        }
      ],
      plotOptions: {
        column: {
          borderWidth: 0
        },
        series: {
          pointInterval: 1,
          pointIntervalUnit: 'month'
        }
      },
      title: {
        text: ''
      },
      credits: {
        enabled: false
      },
      tooltip: {
        shared: true,
        valueDecimals: 2,
        useHTML: true,
        headerFormat: '<div style="padding: 0 0 5px 10px"><b>{point.key}</b></div><table>',
        pointFormatter: function () {
          const valueFn = (value: number) => _this.decimalPipe.transform(value, '1.2-2') + ' ' + this.series.options.custom.unit;
          return `<tr style="color: ${this.color}"><td style="padding-right: 10px"><b>${this.series.name}</b></td><td style="text-align: right"><b>${valueFn(this.y)}</b> </td></tr>`;
        },
        footerFormat: '</table>',
        xDateFormat: '%Y-%m'
      },
      annotations: [
        {
          labelOptions: {
            backgroundColor: 'rgba(0, 0, 0, 0.7)'
          },
          labels: [
            {
              point: 'point-1',
              format: '{y:.2f} %'
            }
          ]
        },
        {
          labelOptions: {
            backgroundColor: 'rgba(0, 170, 255, 0.7)'
          },
          labels: [
            {
              point: 'point-2',
              format: '{y:.2f} %'
            }
          ]
        }
      ],
      exporting: {
        filename: 'Tolerance',
        buttons: {
          contextButton: {
            align: 'left',
            x: -10,
            y: -10
          }
        },
        sourceWidth: 1180,
        sourceHeight: 400,
        scale: 1,
        chartOptions: {
          title: {
            style: {
              fontSize: '12px'
            }
          },
          xAxis: {
            labels: {
              style: {
                fontSize: '10px'
              }
            },
            title: {
              style: {
                fontSize: '10px'
              }
            }
          },
          yAxis: [{
            softMin: 0,
            tickAmount: 9,
            alignTicks: false,
            title: {
              text: this.translate.instant('toleranceThreshold.chart.energy'),
              style: {
                fontSize: '10px'
              }
            },
            labels: {
              style: {
                fontSize: '10px'
              }
            }
          },
            {
              min: 0,
              max: 160,
              tickInterval: 20,
              tickAmount: 9,
              alignTicks: false,
              title: {
                text: this.translate.instant('toleranceThreshold.chart.value'),
                style: {
                  fontSize: '10px'
                }
              },
              labels: {
                style: {
                  fontSize: '10px'
                }
              },
              opposite: true
            }],
          legend: {
            itemStyle: {
              fontSize: '10px'
            }
          }
        }
      },
      series: [
        {
          id: 'series-contracted',
          type: 'column',
          name: this.translate.instant('toleranceThreshold.chart.contracted'),
          zIndex: 0,
          color: '#8c8c8c',
          custom: {
            unit: 'MWh'
          }
        },
        {
          id: 'series-consumption',
          type: 'column',
          name: this.translate.instant('toleranceThreshold.chart.consumption'),
          zIndex: 0,
          color: '#4472C4',
          custom: {
            unit: 'MWh'
          },
          tooltip: {
            pointFormatter: function () {
              const valueFn = (value: number) => _this.decimalPipe.transform(value ? value : 0, '1.3-3');
              const translateFn = (name: string) => _this.translate.instant('toleranceThreshold.chart.' + name);
              const data = this.series.options.custom;
              const series = `    <tr style="color: ${this.color}">
                                                    <td style="padding-right: 10px">
                                                        <b>${this.series.name}</b>
                                                    </td>
                                                    <td style="text-align: right"><b>${valueFn(this.y)} MWh</b></td>
                                                </tr>`;
              const rate = `    <tr style="color: ${this.color}">
                                                <td style="padding: 0 10px">
                                                    <i>${translateFn('ratePlan')}</i>
                                                </td>
                                                <td style="text-align: right">
                                                    <i>${valueFn(data.rate)} %</i>
                                                </td>
                                              </tr>`;
              const cum_fact = `<tr style="color: ${this.color}">
                                                <td style="padding: 0 10px">
                                                    <i>${translateFn('cum_fact')}</i>
                                                </td>
                                                <td style="text-align: right">
                                                    <i>${valueFn(data.cum_fact)} MWh</i>
                                                </td>
                                              </tr>`;
              const cum_contracted = `<tr style="color: ${this.color}">
                                                        <td style="padding: 0 10px">
                                                            <i>${translateFn('cum_contracted')}</i>
                                                        </td>
                                                        <td style="text-align: right">
                                                            <i>${valueFn(data.cum_contracted)} MWh</i>
                                                        </td>
                                                    </tr>`;
              const cum_rate = `<tr style="color: ${this.color}">
                                                <td style="padding: 0 10px"><i>${translateFn('rateCumFact')}</i></td>
                                                <td style="text-align: right">
                                                    <i>${valueFn(data.cum_contracted ? data.cum_fact / data.cum_contracted * 100 : 0)} %</i>
                                                </td>
                                              </tr>`;
              return `${series}${rate}${cum_contracted}${cum_fact}${cum_rate}`;
            }
          }
        },

        {
          id: 'series-planned',
          type: 'column',
          name: this.translate.instant('toleranceThreshold.chart.planned'),
          zIndex: 0,
          color: '#f77f45',
          custom: {
            unit: 'MWh'
          },
          tooltip: {
            pointFormatter: function () {
              const valueFn = (value: number) => _this.decimalPipe.transform(value ? value : 0, '1.3-3');
              const translateFn = (name: string) => _this.translate.instant('toleranceThreshold.chart.' + name);
              const data = this.series.options.custom;
              const series = `    <tr style="color: ${this.color}">
                                                    <td style="padding-right: 10px">
                                                        <b>${this.series.name}</b>
                                                    </td>
                                                    <td style="text-align: right"><b>${valueFn(this.y)} MWh</b></td>
                                                </tr>`;
              const rate = `      <tr style="color: ${this.color}">
                                                    <td style="padding: 0 10px"><i>${translateFn('ratePlan')}</i></td>
                                                    <td style="text-align: right">
                                                        <i>${valueFn(data.rate)} %</i>
                                                    </td>
                                                </tr>`;
              const cum_plan = `  <tr style="color: ${this.color}">
                                                    <td style="padding: 0 10px"><i>${translateFn('cum_plan')}</i></td>
                                                    <td style="text-align: right">
                                                        <i>${valueFn(data.cum_plan)} MWh</i>
                                                    </td>
                                                </tr>`;
              const cum_contracted = `<tr style="color: ${this.color}">
                                                        <td style="padding: 0 10px"><i>${translateFn('cum_contracted')}</i></td>
                                                        <td style="text-align: right">
                                                            <i>${valueFn(data.cum_contracted)} MWh</i>
                                                        </td>
                                                    </tr>`;
              const cum_rate = `  <tr style="color: ${this.color}">
                                                    <td style="padding: 0 10px"><i>${translateFn('rateCumPlan')}</i></td>
                                                    <td style="text-align: right">
                                                        <i>${valueFn(data.cum_contracted ? data.cum_plan / data.cum_contracted * 100 : 0)} %</i>
                                                    </td>
                                                 </tr>`;
              return `${series}${rate}${cum_contracted}${cum_plan}${cum_rate}`;
            }
          }
        },

        {
          id: 'series-rateFact',
          type: 'line',
          keys: ['y', 'id'],
          data: [null, 'point-1'],
          name: this.translate.instant('toleranceThreshold.chart.rateFact'),
          yAxis: 1,
          color: '#000',
          zIndex: 2,
          marker: {
            // enabled: false,
            states: {
              hover: {
                enabled: false
              }
            }
          },
          custom: {
            unit: '%'
          },
          enableMouseTracking: false
        },
        {
          id: 'series-ratePlan',
          type: 'line',
          dashStyle: 'ShortDot',
          keys: ['y', 'id'],
          /* data: [null, 'point-2'],*/
          name: this.translate.instant('toleranceThreshold.chart.ratePlan'),
          yAxis: 1,
          color: '#0af',
          zIndex: 2,
          marker: {
            // enabled: false,
            states: {
              hover: {
                enabled: true
              }
            }
          },
          custom: {
            unit: '%'
          },
          enableMouseTracking: false
        }
      ]
    });
    return this.chart;
  }

  public update(data: ToleranceData) {
    const pointStart = moment(data.start).startOf('month').valueOf();

    let seriesConsumption: any;
    this.chart.ref$.subscribe(res => {
      seriesConsumption = res.get('series-consumption')
    });
    seriesConsumption.update({
      data: data.fact
        ? data.fact.map((p, i) => this.swapNaN(p)
          ? {
            y: this.swapNaN(p),
            cum_fact: this.swapNaN(data.cumulatedFact[i]),
            cum_contracted: this.swapNaN(data.cumulatedContracted[i]),
            rate: this.swapNaN(data.contracted[i]) ? this.swapNaN(data.fact[i]) / this.swapNaN(data.contracted[i]) * 100 : 0
          }
          : null
        )
        : null,
      pointStart
    }, true);

    let seriesContracted: any;
    this.chart.ref$.subscribe(res => {
      seriesContracted = res.get('series-contracted')
    });
    seriesContracted.update({
      data: data.contracted ? data.contracted.map(p => this.swapNaN(p)) : null,
      pointStart
    }, true);

    let seriesPlanned: any;
    this.chart.ref$.subscribe(res => {
      seriesPlanned = res.get('series-planned')
    });
    seriesPlanned.update({
      data: data.plan
        ? data.plan.map((p, i) => this.swapNaN(p)
          ? {
            y: this.swapNaN(p),
            cum_plan: this.swapNaN(data.cumulatedPlan[i]),
            cum_contracted: this.swapNaN(data.cumulatedContracted[i]),
            rate: this.swapNaN(data.contracted[i]) ? this.swapNaN(data.plan[i]) / this.swapNaN(data.contracted[i]) * 100 : 0
          }
          : null
        )
        : null,
      pointStart
    }, true);

    let seriesRateFact: any;
    this.chart.ref$.subscribe(res => {
      seriesRateFact = res.get('series-rateFact')
    });
    let rF: any[] = (data.fact && data.fact.length != 0) ? data.fact.map(p => this.swapNaN(p) ? {
      y: data.rateFact,
      id: 'point-1'
    } : {y: null, id: ''}) : [{y: null, id: 'point-1'}];
    seriesRateFact.update({
      data: rF,
      pointStart
    }, true);

    const rp: any[] = (data.contracted && data.contracted.length != 0) ? data.contracted.map(p => this.swapNaN(p) ? {
      y: data.ratePlan,
      id: ''
    } : {y: null, id: ''}) : [{y: null, id: 'point-2'}];
    rp[rp.length - 1] = {y: data.ratePlan, id: 'point-2'};
    let seriesRatePlan: any;
    this.chart.ref$.subscribe(res => {
        seriesRatePlan = res.get('series-ratePlan')
      }
    );
    seriesRatePlan.update({
      data: rp,
      pointStart
    }, true);

    //refresh Annotations after update
    this.refreshAnnotationsWorkAround(rF, rp);


    const yAxis: any = this.chart.ref.get('yAxis-percentage');
    yAxis.options.plotBands[0].from = data.lowerBound;
    yAxis.options.plotBands[0].to = data.upperBound;
    yAxis.update();
  }

  public clear() {
    const pointStart = moment().startOf('month').valueOf();

    let seriesConsumption: any = this.chart.ref.get('series-consumption');
    seriesConsumption.update({
      data: null,
      pointStart
    } as SeriesOptionsType, true);

    let seriesContracted: any = this.chart.ref.get('series-contracted');
    seriesContracted.update({
      data: null,
      type: 'line',
      pointStart
    } as SeriesOptionsType, true);

    let seriesPlanned: any = this.chart.ref.get('series-planned');
    seriesPlanned.update({
      data: null,
      pointStart
    } as SeriesOptionsType, true);

    let seriesRateFact: any = this.chart.ref.get('series-rateFact');
    seriesRateFact.update({
      data: [[null, 'point-1']],
      pointStart
    } as SeriesOptionsType, true);

    const rp: any[] = [[null, 'point-2']];
    // rp[rp.length - 1] = [data.ratePlan, 'point-2'];
    const seriesRatePlan: any = this.chart.ref.get('series-ratePlan');
    seriesRatePlan.update({
      data: rp,
      pointStart
    } as SeriesOptionsType, true);

    const yAxis: any = this.chart.ref.get('yAxis-percentage');
    yAxis.removePlotBand('plot-band-0'); // Remove plot band by ID
    yAxis.removePlotLine('plot-line-0'); // Remove plot line by ID
    yAxis.removePlotLine('plot-line-1'); // Remove plot line by ID
    yAxis.addPlotBand({
      id: 'plot-band-0',
      from: null,
      to: null
    });
  }

  public refreshWarnPlotLines(value: number, plotLines: number) {
    const yAxis: any = this.chart.ref.get('yAxis-percentage');
    yAxis.options.plotLines[plotLines].value = value;
    yAxis.update();
  }

  swapNaN(a: any, d: number = null): number {
    return a === 'NaN' ? d : a;
  }

  private refreshAnnotationsWorkAround(fact: number[], plan: number[]) {

    // Remove existing annotations
    while ((this.chart.ref as any).annotations.length > 0) {
      (this.chart.ref as any).removeAnnotation((this.chart.ref as any).annotations[0]);
    }

    // Add new annotations
    if (fact && fact.length > 0) {
      (this.chart.ref as any).addAnnotation({
        labelOptions: {
          backgroundColor: 'rgba(0, 0, 0, 0.7)'
        },
        labels: [
          {
            point: 'point-1',
            format: '{y:.2f} %'
          }
        ]
      });
    }

    if (plan && plan.length > 0) {
      (this.chart.ref as any).addAnnotation({
        labelOptions: {
          backgroundColor: 'rgba(0, 170, 255, 0.7)'
        },
        labels: [
          {
            point: 'point-2',
            format: '{y:.2f} %'
          }
        ]
      });
    }
  }
}
