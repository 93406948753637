<mat-card>
  <mat-card-title>
    <header class="docs">
      <h2>{{'AdminTabs.scheduler' | translate}}</h2>
    </header>
  </mat-card-title>
  <mat-card-content>
    <table class="table table-striped">
      <thead>
      <tr>
        <th><span>{{'scheduler.taskName' | translate}}</span></th>
        <th><span>{{'scheduler.description' | translate}}</span></th>
        <th><span>{{'scheduler.lastStatus' | translate}}</span></th>
        <th><span>{{'scheduler.lastExeTime' | translate}}</span></th>
        <th><span>{{'scheduler.nextSchedTime' | translate}}</span></th>
        <th><span>{{'scheduler.cron' | translate}}</span></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let task of scheduledTasks">
        <td>{{task.taskName}}</td>
        <td>{{task.description}}</td>
        <td>{{task.lastManualCompletionStatus}}</td>
        <td>{{task.lastExecutionTime | cezDateTime}}</td>
        <td>{{task.nextScheduledExecutionTime | cezDateTime}}</td>
        <td>{{task.cronExpression}}</td>

        <td>
          <button class="btn btn-sm" [ngClass]="{'btn-info' : !task.isSaving}" (click)="runtask(task)" [disabled]="task.isSaving">
            <span class="fa fa-play"></span>
          </button>
        </td>
      </tr>
      </tbody>
    </table>
  </mat-card-content>
</mat-card>
