import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {CoverageTransactionsUtil} from './coverage-transactions.util';
import {OfferClaimSessionStore} from '../shared/offer-claim/model/OfferClaimSessionStore';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {DialogService} from '../shared/dialog/dialog.service';
import {TranslateService} from '@ngx-translate/core';
import {CoverageTransactionsService} from './coverage-transactions.service';
import {NewClaimDialogConfig} from './offer-claim/new-claim-dialog/new-claim-dialog.model';
import {NewClaimDialogComponent} from './offer-claim/new-claim-dialog/new-claim-dialog.component';
import {CartItemDto} from '../shared/offer-claim/model/CartItemDto';
import {CartItemWithCurrentStatusDto} from '../shared/offer-claim/model/CartItemWithCurrentStatusDto';
import {OfferClaimDto} from '../shared/offer-claim/model/OfferClaimDto';
import {OfferClaimWithCurrentStatusDto} from '../shared/offer-claim/model/OfferClaimWithCurrentStatusDto';
import {SaleStatus} from '../shared/offer-claim/model/sale-status.enum';
import * as _ from 'lodash';

export enum OfferClaimEventType {
    INIT = 'INIT',
    CREATE = 'CREATE',
    EDIT = 'EDIT',
    DELETE = 'DELETE',
    STATUS_CHANGE = 'STATUS_CHANGE',
    PRICE_EXPIRED = 'PRICE_EXPIRED',
    FINALIZING_PURCHASE = 'FINALIZING_PURCHASE',
    REQUEST_FOR_QUOTATION = 'REQUEST_FOR_QUOTATION',
    REQUEST_FOR_QUOTATION_REVOKE = 'REQUEST_FOR_QUOTATION_REVOKE',
    LIST_REFRESH_INTERVAL = 'LIST_REFRESH_INTERVAL',
}

@Injectable({
    providedIn: 'root'
})
export class OfferClaimService {

    public updateCoverageTransactionsOverviewEvent: Subject<void> = new Subject();
    public updateOfferClaimsEvent: Subject<OfferClaimEventType | null> = new Subject();
    public localUpdateOfferClaimsEvent: Subject<OfferClaimEventType | null> = new Subject();

    private offerClaims: OfferClaimWithCurrentStatusDto[] = [];

    constructor(private modalService: NgbModal,
                private dialogService: DialogService,
                private translate: TranslateService,
                private coverageTransactionsService: CoverageTransactionsService) {
    }

    /**
     * It updates position progress bar.
     * */
    public sendUpdateCoverageTransactionsOverviewEvent(): void {
        this.updateCoverageTransactionsOverviewEvent.next();
    }

    /**
     * OfferClaim update happened (save, update, delete).
     * Retrieves all offerClaims from DB and sends localUpdateOfferClaims event after data received.
     * */
    public sendUpdateOfferClaimsEvent(eventType?: OfferClaimEventType): void {
        this.updateOfferClaimsEvent.next(eventType);
    }

    /**
     * Refresh offerClaim based calculations and chart without DB query. (Some state changed locally for example saleStatus).
     * */
    public sendLocalUpdateOfferClaimsEvent(eventType?: OfferClaimEventType): void {
        this.localUpdateOfferClaimsEvent.next(eventType);
    }

    public getOfferClaimsBySaleStatus(saleStatus: SaleStatus): OfferClaimWithCurrentStatusDto[] {
        return this.getOfferClaims()
            .filter((item: OfferClaimWithCurrentStatusDto): boolean =>
                CoverageTransactionsUtil.getSaleStatus(item) === saleStatus);
    }

    public getOfferClaims(): OfferClaimWithCurrentStatusDto[] {
        return _.cloneDeep(this.offerClaims);
    }

    public setOfferClaims(items: OfferClaimWithCurrentStatusDto[]): void {
        this.offerClaims = _.cloneDeep(items);
    }

    /**
     * Save offerClaims to localStorage. Its saleStatus will be used later.
     * */
    public localSaveOfferClaims(storeValue: { key: string, value: OfferClaimSessionStore[] }): void {
        CoverageTransactionsUtil.setCartItems(storeValue.key, JSON.stringify(storeValue.value));
    }

    /**
     * For the given cart items, search for local saved cart item and if present, updates its currentStatus.
     * */
    public setSaleStatusIfExistCart(cartItems: CartItemDto[]): CartItemWithCurrentStatusDto[] {
        const items: CartItemDto[] = _.cloneDeep(cartItems);

        return items.map((item: CartItemDto): CartItemWithCurrentStatusDto => {
            const cartItemWithCurrentStatusDto: CartItemWithCurrentStatusDto = item as CartItemWithCurrentStatusDto;
            const cachedCartItems: OfferClaimSessionStore[] = CoverageTransactionsUtil.getCartItems(item.deliveryPeriodId) || [];
            const cachedCartItem: OfferClaimSessionStore = cachedCartItems
                .find((cI: OfferClaimSessionStore): boolean => cI.offerClaimId === item.id);

            cartItemWithCurrentStatusDto.currentStatus = _.clone(cartItemWithCurrentStatusDto.saleStatus);
            if (!!cachedCartItem) {
                cartItemWithCurrentStatusDto.saleStatus = cachedCartItem.saleStatus;
            }

            return cartItemWithCurrentStatusDto;
        });
    }

    /**
     * For the given offer claims, search for local saved offer claims and if present, updates its currentStatus.
     * */
    public setSaleStatusIfExistOfferClaim(offerClaims: OfferClaimDto[]): OfferClaimWithCurrentStatusDto[] {
        if (!offerClaims || offerClaims.length === 0) {
            return [];
        }
        const items: OfferClaimDto[] = _.cloneDeep(offerClaims);
        const cachedOfferClaims: OfferClaimSessionStore[] = CoverageTransactionsUtil.getCartItems(items[0].deliveryPeriod.id) || [];

        return items.map((item: OfferClaimDto): OfferClaimWithCurrentStatusDto => {
            const offerClaimWithCurrentStatus: OfferClaimWithCurrentStatusDto = item as OfferClaimWithCurrentStatusDto;
            offerClaimWithCurrentStatus.currentStatus = _.clone(offerClaimWithCurrentStatus.saleStatus);

            const cachedOfferClaim: OfferClaimSessionStore = cachedOfferClaims
                .find((cI: OfferClaimSessionStore): boolean => cI.offerClaimId === item.id);
            if (!!cachedOfferClaim && (offerClaimWithCurrentStatus.timeRemain > 0 || !offerClaimWithCurrentStatus.createdByPartner)) {
                offerClaimWithCurrentStatus.saleStatus = cachedOfferClaim.saleStatus;
            }

            return offerClaimWithCurrentStatus;
        });
    }

    /**
     * Open new-claim-dialog. You can create new OfferClaim or edit existing with this method.
     * Save will trigger UpdateOfferClaims event!
     *
     * @param config Configuration for new-claim-dialog
     * @param doAfterSave Method to execute after save
     * @param doAfterNotSave Method to execute after closing dialog without save
     * */
    public addNewOrEditOfferClaim(config: NewClaimDialogConfig, doAfterSave?: () => void, doAfterNotSave?: () => void): void {
        const modalRef: NgbModalRef = this.modalService.open(NewClaimDialogComponent, {backdrop: 'static'});

        modalRef.componentInstance.dialogConfig = config;

        modalRef.componentInstance.modalClose.subscribe((itemSaved: boolean): void => {
            modalRef.close();
            if (itemSaved) {
                if (!!config.claim && !!config.claim.id) {
                    this.sendUpdateOfferClaimsEvent(OfferClaimEventType.CREATE);
                } else {
                    this.sendUpdateOfferClaimsEvent(OfferClaimEventType.EDIT);
                }
                if (!!doAfterSave) {
                    doAfterSave();
                }
            } else {
                if (!!doAfterNotSave) {
                    doAfterNotSave();
                }
            }
        });
    }

    /**
     * Open delete offerClaim confirm dialog and delete offerClaim list with the given ids.
     * Deletion will trigger UpdateOfferClaims event!
     *
     * @param offerClaimIds List of offerClaim ids to delete
     * @param success Optional function to execute after successful deletion
     * @param error Optional function to execute after unsuccessful deletion
     * @param confirmTitle Optional title for confirm dialog
     * @param confirmMessage Optional message for confirm dialog
     * */
    public deleteOfferClaims(offerClaimIds: number[],
                             success?: () => void,
                             error?: () => void,
                             confirmTitle: string = 'offerClaim.deleteDialog.title',
                             confirmMessage: string = 'offerClaim.deleteDialog.message'): void {
        this.dialogService.confirm(this.translate.instant(confirmTitle), this.translate.instant(confirmMessage)).subscribe((value: boolean): void => {
            if (value) {
                this.coverageTransactionsService.deleteOfferClaimById(offerClaimIds).subscribe(() => {
                    this.dialogService.deleteSuccess();
                    this.sendUpdateOfferClaimsEvent(OfferClaimEventType.DELETE);
                    if (!!success) {
                        success();
                    }
                }, () => {
                    if (!!error) {
                        error();
                    }
                });
            }
        });
    }

}
