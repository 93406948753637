import {HttpErrorResponse, HttpParams} from '@angular/common/http';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {debounceTime, distinctUntilChanged, finalize, takeUntil, tap} from 'rxjs/operators';
import {FileUploadQueueComponent} from '../fileupload/matFileUploadQueue/file-upload-queue.component';
import {ExcelImportResult} from './model/excel-import-result.model';
import {ExcelType} from './model/excel-type.enum';
import {Resolution} from './model/resolution.enum';
import {TimeSeriesType} from './model/time-series-type.enum';
import {Unit} from './model/unit.enum';
import {TimeSeriesActivateComponent} from './time-series-activate/time-series-activate.component';
import {ChartData} from './time-series-chart/chart-data.model';
import {TimeSeriesChartComponent} from './time-series-chart/time-series-chart.component';
import {TimeInterval} from './time-series-interval/time-interval.model';
import {TimeIntervalValidators} from './time-series-interval/time-interval.validators';
import {TimeSeriesService} from './time-series.service';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {Moment} from 'moment-timezone';
import {HedgeConsumptionPlanImportType} from './model/hedge-consumption-plan-import.types';
import {TimeSeries} from './model/timeseries';
import {LoadingOverlayService} from '../services/loading-overlay.service';
import {DateInterval} from '../dto/date-interval.model';
import {DialogService} from '../dialog/dialog.service';
import {MeasureUnit} from './time-series-interval/measure-unit.enum';
import {CanLeave, CanLeaveService} from '../can-leave/can-leave.service';
import {IntervalUtil} from '../../core/util/interval-util';

import {MomentInterval} from '../dto/moment.interval';
import {MatExpansionPanel} from "@angular/material/expansion";
import * as moment from 'moment';
import {
    HedgeConsumptionPlanService
} from '../../coverage-transactions/hedge-consumption-plan/hedge-consumption-plan.service';
import {
    ExpectedPlanType,
    HedgeConsumptionPlanResponse
} from "../../coverage-transactions/hedge-consumption-plan/hedge-consumption-plan.type";
import {PodValueService} from "../services/pod-value.service";
import {DateFormatUtil} from '../../core/util/date-format.util';
import {
    CoverageTransactionsPeriodTabService
} from "../../coverage-transactions/coverage-transactions-period-tab.service";
import {DeliveryPeriodDto} from "../dto/delivery-period-dto";


@Component({
    selector: 'cez-time-series-import',
    templateUrl: './time-series-import.component.html',
    styleUrls: ['./time-series-import.component.scss'],
    providers: [TimeIntervalValidators],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeSeriesImportComponent implements OnInit, CanLeave {

    @ViewChild('importPanel', {static: false}) importPanel: MatExpansionPanel;
    @ViewChild('fileUploadQueue', {static: false}) fileUploadQueue: FileUploadQueueComponent;
    @ViewChild('chart', {static: true}) chart: TimeSeriesChartComponent;
    @ViewChild('activatePanel', {static: false}) activatePanel: TimeSeriesActivateComponent;
    @ViewChild('planDataButtonStateCheck', {static: false}) planDataButtonStateCheck: ElementRef;

    @Input() title: string;
    @Input() titleAddonTemplate: TemplateRef<any>;
    @Input() isEditable: boolean = true;
    @Input() uiName: string;
    @Input() companyId: number;
    @Input() agreementId: number;
    @Input() deliveryPeriodId: number;
    @Input() isMultipleFiles: boolean;
    @Input() isHedgeConsumptionPlan: boolean = false;
    @Input() isExpectedPlan: boolean = false;
    @Input() intervalMin: moment.Moment;
    @Input() intervalMax: moment.Moment;
    @Input() deliveryPeriodChange: Subject<[moment.Moment, moment.Moment]>;
    @Input() hedgeImportParamsChange: Subject<HedgeConsumptionPlanImportType>;

    @Input() excelTypeOptions: ExcelType[] = [ExcelType.VECTOR, ExcelType.MATRIX, ExcelType.MATRIX_T];
    @Input() timeSeriesTypeOptions: TimeSeriesType[] = [TimeSeriesType.PLANED_CONSUMPTION, TimeSeriesType.CONSUMPTION_SCHEDULE, TimeSeriesType.PRODUCTION_SCHEDULE, TimeSeriesType.HEDGE_CONSUMPTION_PLAN];
    @Input() resolutionOptions: Resolution[] = [Resolution.FIVE_MINUTELY, Resolution.QUARTER_HOURLY, Resolution.HOURLY, Resolution.DAILY, Resolution.WEEKLY, Resolution.MONTHLY, Resolution.QUARTER_YEARLY, Resolution.YEARLY];

    @Input() defaultExcelType: ExcelType = this.excelTypeOptions[0];
    @Input() defaultTimeSeriesType: TimeSeriesType = this.timeSeriesTypeOptions[0];
    @Input() defaultResolution: Resolution = this.resolutionOptions[0];

    @Input() allowDifferentMeasureUnits: boolean = false;

    @Input() exportChartTitle: string | undefined;
    @Input() sourceWidth: string | undefined;
    @Input() sourceHeight: string | undefined;
    @Input() fontSize: string | undefined;
    @Input() activatePermissionCheck: boolean = false;
    @Input() activatePermission: string = '';

    @Output() intervalChange: EventEmitter<MomentInterval> = new EventEmitter<MomentInterval>();
    @Output() activation: EventEmitter<void> = new EventEmitter<void>();

    private destroy: Subject<void> = new Subject<void>();


    clearInputValues: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    deleteFile: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    form: FormGroup = this.formBuilder.group({
        excelType: [this.defaultExcelType, Validators.required],
        timeSeriesType: [this.defaultTimeSeriesType, Validators.required],
        timeInterval: [{
            start: moment().startOf('day').add(1, 'day'),
            end: moment().startOf('day').add(2, 'day'),
            resolution: Resolution.HOURLY,
            measureUnit: MeasureUnit.MW
        }, null, this.timeIntervalValidators.timeIntervalValidator()]
    });

    formExpectedPlanMonths: FormGroup;

    newSeriesData: ChartData;
    originalSeriesData: ChartData;
    factSeriesData: ChartData;
    latestSeriesData: ChartData;

    warning: string;
    agreementParams: HttpParams;

    panelDescription: string;
    selectedResolution: Resolution = Resolution.HOURLY;
    selectedPlan: string;
    sumYearlyValue: number;
    sumMonthlyValue: number;
    currentPeriod: DateInterval;
    numberOfMonthsInTolerancePeriod: number = 0;
    monthInTolerancePeriod: number[] = [];
    readonly maxMonthNumber: number = 12;
    chartType: string = 'line';
    hedgeConsumptionPlanData: HedgeConsumptionPlanImportType;
    calculatedTimeSeries: TimeSeries[];
    isPlanDataSaveEnabled: boolean = false;

    selectedDeliveryPeriod: DeliveryPeriodDto;

    constructor(private formBuilder: FormBuilder,
                private timeSeriesService: TimeSeriesService,
                private loadingOverlay: LoadingOverlayService,
                private hedgeConsumptionPlanService: HedgeConsumptionPlanService,
                private podValueService: PodValueService,
                private timeIntervalValidators: TimeIntervalValidators,
                private changeDetector: ChangeDetectorRef,
                private dialogService: DialogService,
                private translateService: TranslateService,
                private canLeaveService: CanLeaveService,
                private coverageTransactionsPeriodTabService: CoverageTransactionsPeriodTabService) {
    }

    ngOnInit(): void {
        this.setIntervalStartForHedge();
        this.intervalValueChanges();
        this.formValueChanges();

        this.agreementParams = this.agreementId ? new HttpParams().set('agreementId', this.agreementId.toString()) : new HttpParams();

        this.setImportParamsForHedge();

        this.form.get('timeSeriesType').setValue(this.defaultTimeSeriesType);
        this.form.get('excelType').setValue(this.defaultExcelType);

        this.getDefaultFormat();
        this.showSeries();
        this.subscribeDeliveryPeriodChange()

    }


    private subscribeDeliveryPeriodChange(): void {
        this.coverageTransactionsPeriodTabService.getSelectedDeliveryPeriod()
            .pipe(takeUntil(this.destroy))
            .subscribe((item) => {
                if (!!item) {
                    setTimeout(() => {
                        this.selectedDeliveryPeriod = item;
                    });
                }
            });
    }

    canLeave(): Observable<boolean> {
        return this.canLeaveService.canLeave();
    }

    public getBrowseButtonTooltip(): string {
        if (this.isHedgeConsumptionPlan) {
            return this.translateService.instant('timeSeriesImport.browseButton.toolTipForHedge');
        }
        return this.translateService.instant('timeSeriesImport.browseButton.toolTip');
    }

    public getActivateButtonTooltip(): string {
        if (this.isHedgeConsumptionPlan) {
            return this.translateService.instant('timeSeriesImport.activateButton.toolTipForHedge');
        }
        return this.translateService.instant('timeSeriesImport.activateButton.toolTip');
    }

    private formValueChanges() {
        this.form.valueChanges.subscribe(v => {
            this.panelDescription =
                this.translateService.instant('timeSeriesImport.timeSeriesTypes.' + v.timeSeriesType)
                + ' / '
                + this.translateService.instant('timeSeriesImport.excelTypes.' + v.excelType)
                + ' / ' + this.translateService.instant('timeInterval.resolutions.' + v.timeInterval.resolution);

            if (v.timeInterval.start) {
                this.panelDescription +=
                    ' / '
                    + DateFormatUtil.formatToLocalDate(v.timeInterval.start)
                    + ' - '
                    + DateFormatUtil.formatToLocalDate(v.timeInterval.end.clone().subtract(1, 'days'));
            }
        });
    }

    private getDefaultFormat() {
        this.timeSeriesService.getDefaultFormat(this.uiName, this.companyId)
            .pipe(finalize(() => this.showSeries()))
            .subscribe(result => {
                if (Boolean(result)) {
                    this.form.get('timeSeriesType').setValue(result.tsType);
                    this.form.get('excelType').setValue(result.templateType);
                    if (!this.isHedgeConsumptionPlan) {
                        this.form.get('timeInterval').setValue({
                            start: moment(result.timeInterval.start),
                            end: moment(result.timeInterval.end),
                            resolution: result.timeInterval.resolution,
                            measureUnit: result.timeInterval.measureUnit
                        });
                    }
                }
            });
    }

    private intervalValueChanges() {
        this.form.controls['timeInterval'].valueChanges.subscribe((timeInterval1: TimeInterval) => {
            this.intervalChange.emit({start: moment(timeInterval1.start), end: moment(timeInterval1.end)});
            this.setAgreementParams();

            if (this.selectedResolution === timeInterval1.resolution) {
                return;
            }

            let neededRefresh: boolean = true;
            if ((this.selectedResolution === Resolution.HOURLY || this.selectedResolution === Resolution.QUARTER_HOURLY) &&
                (timeInterval1.resolution === Resolution.HOURLY || timeInterval1.resolution === Resolution.QUARTER_HOURLY)) {
                neededRefresh = false;
            }

            this.selectedResolution = timeInterval1.resolution;

            this.isExpectedPlan = this.isHedgeConsumptionPlan && this.selectedResolution === Resolution.MONTHLY;
            if (this.isExpectedPlan) {
                this.chartType = 'column';
            } else {
                this.chartType = 'line';
            }

            if (neededRefresh && this.isHedgeConsumptionPlan) {
                this.deleteSeries(false);
                this.showSeries();
            }

            const interval: DateInterval = {from: this.intervalMin, to: this.intervalMax};
            this.getNumberOfMonthsOfPeriod(interval);
        });
    }

    private setAgreementParams(): void {
        const timeInterval1: TimeInterval = this.form.controls['timeInterval'].value;

        if (this.allowDifferentMeasureUnits) {
            this.agreementParams = new HttpParams()
                .set('deliveryStart', timeInterval1.start.toISOString())
                .set('deliveryEnd', timeInterval1.end.toISOString())
                .set('deliveryPeriodId', this.deliveryPeriodId)
                .set('agreementId', this.agreementId.toString())
                .set('measureUnit', timeInterval1.measureUnit);
        } else {
            this.agreementParams = new HttpParams()
                .set('deliveryStart', timeInterval1.start.toISOString())
                .set('deliveryEnd', timeInterval1.end.toISOString())
                .set('deliveryPeriodId', this.deliveryPeriodId)
                .set('agreementId', this.agreementId.toString());
        }
    }

    private setImportParamsForHedge() {
        if (this.isHedgeConsumptionPlan) {
            this.hedgeImportParamsChange.subscribe((params: HedgeConsumptionPlanImportType) => {
                this.hedgeConsumptionPlanData = params;
                this.agreementId = params.agreementId;
                this.deliveryPeriodId = params.deliveryPeriodId;
                this.setAgreementParams();
                this.changeDetector.markForCheck();
            });
        }
    }

    private setIntervalStartForHedge() {
        if (this.isHedgeConsumptionPlan) {
            this.deliveryPeriodChange.subscribe(([start, end]: [moment.Moment, moment.Moment]) => {
                if (this.newSeriesData) {
                    this.deleteSeries();
                }

                const now: Moment = moment.tz('Europe/Budapest').startOf('day');
                const intervalStart: moment.Moment = start || moment.utc(now.add(1, 'day')).local();
                const intervalEnd: moment.Moment = end || intervalStart.clone().add(1, 'day');
                this.form.get('timeInterval').setValue({
                    start: intervalStart.clone(),
                    end: intervalEnd.clone(),
                    resolution: this.defaultResolution,
                    measureUnit: null
                });

                this.changeDetector.markForCheck();
            });
        }
    }

    export(includeTimeSeries: boolean) {
        let params = {agreementId: String(this.agreementId)};
        if (this.allowDifferentMeasureUnits) {
            params = Object.assign(params, {measureUnit: this.form.get('timeInterval').value.measureUnit});
        }

        this.timeSeriesService.export(
            this.form.get('excelType').value,
            this.form.get('timeSeriesType').value,
            this.form.get('timeInterval').value,
            includeTimeSeries,
            params
        );

        if (Boolean(this.uiName)) {
            this.timeSeriesService.saveDefaultFormat(this.form, this.uiName, this.companyId).subscribe();
        }
    }


    public getAgreementParams(): HttpParams {
        return this.agreementParams;
    }

    onUpload(event: { file: File, success: boolean, response?: ExcelImportResult, error?: HttpErrorResponse }) {
        this.warning = null;

        if (event.error) {
            return;
        }

        this.canLeaveService.setModified(true);

        this.activatePanel.deleteSeries();
        this.activatePanel.addNewFile(event.file);

        this.newSeriesData = {
            timeSeries: event.response.timeSeries,
            name: this.translateService.instant('timeSeriesImport.newTimeSeriesLabel'),
            unit: this.getUnit(this.form.get('timeSeriesType').value)
        };
        this.chart.addNewSeries(this.newSeriesData);

        const originalTimeInterval: TimeInterval = this.form.get('timeInterval').value;
        const resultTimeInterval: TimeInterval = {
            start: this.getResultTimeStart(event.response),
            end: this.getResultTimeEnd(event.response),
            resolution: event.response.timeSeries.timeInterval.resolution,
            measureUnit: event.response.timeSeries.timeInterval.measureUnit
        };

        if (this.isHedgeConsumptionPlan) {
            this.form.get('timeInterval').setValue(resultTimeInterval);
        } else {
            this.form.get('timeInterval').setValue(IntervalUtil.mergeIntervals(originalTimeInterval, resultTimeInterval));
        }
        this.form.get('timeSeriesType').setValue(event.response.timeSeriesImportType);

        if (this.hasSettingsChanged(event.response)) {
            this.showSeries();
        }

        if (event.response.warnings) {
            this.warning = this.timeSeriesService.warningHandle(event.response.warnings);
        }
    }

    private getResultTimeStart(response: ExcelImportResult): moment.Moment {
        const start: moment.Moment = moment(response.timeSeries.timeInterval.start);
        if (this.isHedgeConsumptionPlan && start.isBefore(this.intervalMin)) {
            return this.intervalMin;
        }

        return start;
    }

    private getResultTimeEnd(response: ExcelImportResult): moment.Moment {
        return moment(response.timeSeries.timeInterval.end);
    }

    showSeries(clear: boolean = false) {
        if (clear) {
            this.chart.clearTimeSeries();
            this.chart.deleteSeries([], true);
        }
        this.showOriginalSeries();
        this.showFactSeries();
    }

    private showOriginalSeries() {
        if (this.isExpectedPlan) {
            this.showOriginalMonthlySeries();
        } else {
            if (this.isHedgeConsumptionPlan) {
                this.loadingOverlay.turnOn();
            }


            if (this.form.get('timeSeriesType').value === 'CONSUMPTION_SCHEDULE') {
                this.timeSeriesService.getTimeSeries(TimeSeriesType.LATEST_SUBMITTED_CONSUMPTION_SCHEDULE, this.form.get('timeInterval').value, this.agreementId).subscribe(timeSeries => {
                    this.latestSeriesData = {
                        timeSeries: timeSeries,
                        name: this.translateService.instant('timeSeriesImport.timeSeriesTypes.' + TimeSeriesType.LATEST_SUBMITTED_CONSUMPTION_SCHEDULE),
                        unit: this.getUnit(TimeSeriesType.LATEST_SUBMITTED_CONSUMPTION_SCHEDULE)
                    };
                    this.chart.addNewSeries(this.latestSeriesData, this.chartType, '#aba8a6');
                    this.loadingOverlay.turnOff();


                    this.fetchTimeSeriesByValue();

                }, () => {
                    this.loadingOverlay.turnOff();
                });
            } else {
                this.fetchTimeSeriesByValue();
            }


        }
    }

    showOriginalMonthlySeries() {
        const interval: TimeInterval = {
            start: moment(this.hedgeConsumptionPlanData.deliveryStart),
            end: moment(this.hedgeConsumptionPlanData.deliveryEnd),
            resolution: Resolution.MONTHLY
        };

        this.timeSeriesService.check(interval).subscribe(() => {
        }, () => {
        }, () => {
            this.loadingOverlay.turnOn();

            this.hedgeConsumptionPlanService.getConsumptionMonthlySum(interval, this.agreementId).subscribe((data: number[]) => {
                const timeSeries: TimeSeries = {
                    timeInterval: {
                        start: moment(this.form.get('timeInterval').value.start),
                        end: moment(this.form.get('timeInterval').value.end),
                        resolution: Resolution.MONTHLY
                    },
                    data: data
                };
                this.originalSeriesData = {
                    timeSeries: timeSeries,
                    name: this.translateService.instant('timeSeriesImport.timeSeriesTypes.' + this.form.get('timeSeriesType').value),
                    unit: Unit.MWh
                };
                this.chart.addOriginalSeries(this.originalSeriesData, this.chartType);
                this.loadingOverlay.turnOff();
            }, () => {
                this.loadingOverlay.turnOff();
            });
        });
    }

    activate() {
        this.deleteSeries();
        this.showSeries(true);
        this.activation.emit();
    }

    deleteSeries(isNeededExpectedMonthsReset: boolean = true) {
        if (this.isExpectedPlan && isNeededExpectedMonthsReset) {
            this.iterateOnMonths((i: number) => {
                this.formExpectedPlanMonths.controls['expectedPlanMonths' + i].setValue(null, {emitEvent: false});
            });
        }

        if (this.isHedgeConsumptionPlan) {
            this.deleteFile.next(true);
        }

        this.clearInputValues.next(true);
        this.isPlanDataSaveEnabled = false;

        if (this.isHedgeConsumptionPlan) {
            this.chart.deleteSeries([this.translateService.instant('timeSeriesImport.timeSeriesTypes.FACT_CONSUMPTION'), this.translateService.instant('toleranceThreshold.expectedPlan')]);
        } else {
            this.chart.deleteSeries([], true);
            this.chart.clearTimeSeries();
        }
        this.fileUploadQueue?.removeAll();
        this.newSeriesData = null;
        this.warning = '';

        this.canLeaveService.setModified(false);
        this.showSeries();
    }

    expectedPlanChanged(selectedPlan: string): void {
        this.isPlanDataSaveEnabled = false;
        this.selectedPlan = selectedPlan;
    }

    sumYearlyValueChanged(sumYearlyValue: number): void {
        this.isPlanDataSaveEnabled = true;
        this.sumYearlyValue = sumYearlyValue;
        this.calculatedPlan(sumYearlyValue, ExpectedPlanType.SUM_YEARLY);
    }

    sumMonthlyValueChanged(sumMonthlyValue: number): void {
        this.isPlanDataSaveEnabled = true;
        this.sumMonthlyValue = sumMonthlyValue;
        this.calculatedPlan(sumMonthlyValue, ExpectedPlanType.SUM_MONTHLY);
    }

    savePlanData(): void {
        if (!!this.calculatedTimeSeries) {
            this.loadingOverlay.turnOn();

            this.hedgeConsumptionPlanService.saveCalculatedPlan(this.calculatedTimeSeries, this.agreementId, this.deliveryPeriodId).subscribe(() => {
                this.deleteSeries();
                this.showSeries();
                this.loadingOverlay.turnOff();
                this.dialogService.saveSuccess();
            }, () => {
                this.loadingOverlay.turnOff();
            });
        }
    }

    private calculatedPlan(value: number, type: ExpectedPlanType, expectedPlanMonths: number[] = null): void {
        this.loadingOverlay.turnOn();
        this.hedgeConsumptionPlanService.getCalculatedPlan(
            this.hedgeConsumptionPlanData.deliveryStart,
            this.hedgeConsumptionPlanData.deliveryEnd,
            value,
            type,
            this.agreementId,
            expectedPlanMonths
        ).subscribe((plan: HedgeConsumptionPlanResponse) => {
            this.calculatedTimeSeries = plan.timeSeries;
            const timeSeries: TimeSeries = {
                data: plan.monthlySum,
                timeInterval: {
                    resolution: Resolution.MONTHLY,
                    start: moment(this.hedgeConsumptionPlanData.deliveryStart),
                    end: moment(this.hedgeConsumptionPlanData.deliveryEnd)
                }
            };

            this.newSeriesData = {
                timeSeries: timeSeries,
                name: this.translateService.instant('toleranceThreshold.expectedPlan'),
                unit: Unit.MWh
            };

            this.changeIntervalOnMonthlyResolution();

            this.chart.addNewSeries(this.newSeriesData, this.chartType);
            this.isPlanDataSaveEnabled = true;

            this.loadingOverlay.turnOff();
        }, () => {
            this.loadingOverlay.turnOff();
        });
    }

    private changeIntervalOnMonthlyResolution() {
        if (!moment(this.hedgeConsumptionPlanData.deliveryStart).isSame(this.form.get('timeInterval').value.start) ||
            !moment(this.hedgeConsumptionPlanData.deliveryEnd).isSame(this.form.get('timeInterval').value.end)) {

            this.form.get('timeInterval').setValue({
                start: moment(this.hedgeConsumptionPlanData.deliveryStart),
                end: moment(this.hedgeConsumptionPlanData.deliveryEnd),
                resolution: this.form.get('timeInterval').value.resolution
            }, {emitEvent: false});


            this.showOriginalMonthlySeries();
        }
    }

    private hasSettingsChanged(response: ExcelImportResult): boolean {
        const timeInterval: TimeInterval = this.form.get('timeInterval').value;

        return moment(response.timeSeries.timeInterval.start).diff(timeInterval.start) !== 0
            || moment(response.timeSeries.timeInterval.end).diff(timeInterval.end) !== 0
            || response.timeSeries.timeInterval.resolution !== timeInterval.resolution
            || response.timeSeriesImportType !== this.form.get('timeSeriesType').value.start;
    }

    private getNumberOfMonthsOfPeriod(currentPeriod: DateInterval): void {
        this.currentPeriod = currentPeriod;

        this.numberOfMonthsInTolerancePeriod = moment(this.currentPeriod.to).add(1, 'months').diff(moment(this.currentPeriod.from), 'month');

        this.formExpectedPlanMonths = this.formBuilder.group({});
        this.monthInTolerancePeriod = [];

        this.iterateOnMonths((i: number) => {
            const control: AbstractControl = this.formBuilder.control(null);
            control.disable({emitEvent: false});

            this.setToleranceExpectedPlanControlState(control, i, new Date().getMonth(), new Date().getFullYear(), currentPeriod);

            this.valueChanges(control.valueChanges, 1500);
            this.formExpectedPlanMonths.addControl('expectedPlanMonths' + i, control);
            this.monthInTolerancePeriod.push(i);
        });
    }

    private iterateOnMonths(func: (i: number) => void): void {
        const monthOfYear: number = this.getMonthOfYear();
        for (let i = monthOfYear; i < Math.min(monthOfYear + this.numberOfMonthsInTolerancePeriod, this.maxMonthNumber); i++) {
            func.call(this, i);
        }
    }

    private setToleranceExpectedPlanControlState(control: AbstractControl | FormControl,
                                                 monthsInExpectedPlan: number,
                                                 currentMonth: number,
                                                 currentYear: number,
                                                 currentPeriod: DateInterval): void {
        if (moment(currentPeriod.to).year() < currentYear) {
            control.disable();
        } else if (moment(currentPeriod.to).year() == currentYear) {
            for (let j = 0; j < monthsInExpectedPlan - currentMonth; j++) {
                control.enable();
            }
        } else if (moment(currentPeriod.to).year() > currentYear) {
            control.enable();
        }
    }

    private valueChanges(value: Observable<any>, dueTime: number = 0) {
        value.pipe(
            distinctUntilChanged(),
            debounceTime(dueTime),
            tap(() => {
                let expectedPlan: number[] = [];
                this.iterateOnMonths((i: number) => {
                    expectedPlan.push(this.formExpectedPlanMonths.controls['expectedPlanMonths' + i].value);
                });

                this.calculatedPlan(null, ExpectedPlanType.EXPECTED_PLAN_MONTHS, expectedPlan);

                this.isPlanDataSaveEnabled = true;
                this.planDataButtonStateCheck.nativeElement.focus();
            })
        ).subscribe();
    }

    private getMonthOfYear(): number {
        if (moment(new Date()).year() <= moment(this.currentPeriod.from).year()) {
            return moment(this.currentPeriod.from).month();
        }

        return 0;
    }

    private getUnit(type: TimeSeriesType): Unit {
        if (!this.hedgeConsumptionPlanData) {
            return type === TimeSeriesType.PLANED_CONSUMPTION ? Unit.kW : Unit.MW;
        }

        if (this.selectedResolution === Resolution.MONTHLY) {
            return Unit.MWh;
        }

        return Unit.MW;
    }

    private showFactSeries() {
        if (this.isHedgeConsumptionPlan) {
            this.loadingOverlay.turnOn();
            const timeInterval: TimeInterval = this.form.get('timeInterval').value;
            this.podValueService.getConsumptionFact(
                timeInterval.start, timeInterval.end, timeInterval.resolution, this.deliveryPeriodId).subscribe(response => {
                this.factSeriesData = {
                    timeSeries: {
                        timeInterval: {
                            start: moment(response.startTime),
                            end: moment(response.endTime),
                            resolution: response.resolution as Resolution,
                            measureUnit: MeasureUnit.MW
                        },
                        data: !!response.valuesKW ? response.valuesKW.map(d => d / 1000) : null // kW->MW
                    },
                    name: this.translateService.instant('timeSeriesImport.timeSeriesTypes.FACT_CONSUMPTION'),
                    unit: this.getUnit(this.form.get('timeSeriesType').value)
                };
                this.chart.addTimeSeries(this.factSeriesData, this.chartType, '#b8b3ad', true);
                this.loadingOverlay.turnOff();
            }, () => {
                this.loadingOverlay.turnOff();
            });
        }
    }

    private fetchTimeSeriesByValue() {
        //original
        this.timeSeriesService.getTimeSeries(this.form.get('timeSeriesType').value, this.form.get('timeInterval').value, this.agreementId).subscribe(timeSeries => {
            this.originalSeriesData = {
                timeSeries: timeSeries,
                name: this.translateService.instant('timeSeriesImport.timeSeriesTypes.' + this.form.get('timeSeriesType').value),
                unit: this.getUnit(this.form.get('timeSeriesType').value)
            };
            this.chart.addOriginalSeries(this.originalSeriesData, this.chartType);
            this.loadingOverlay.turnOff();
        }, () => {
            this.loadingOverlay.turnOff();
        });
    }
}
