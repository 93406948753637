import {CommonModule} from '@angular/common';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {DiagramOptionsComponent} from './diagram-options/diagram-options.component';
import {AdditionalTimeSeriesComponent} from './additional-timeseries/additional-time-series.component';
import {DiagramComponent} from './diagram/diagram.component';
import {DisplayModeComponent} from './display-mode/display-mode.component';
import {MarketPriceComponent} from './market-price.component';
import {MarketProductsComponent} from './marketproducts/market-products.component';
import {CezPortalSharedModule} from '../shared';

@NgModule({
    imports: [
        CommonModule,
        CezPortalSharedModule
    ],
    exports: [
        MarketPriceComponent
    ],
    declarations: [MarketPriceComponent, DiagramComponent, MarketProductsComponent, AdditionalTimeSeriesComponent, DiagramOptionsComponent, DisplayModeComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MarketPriceModule {
}
