import {Component, OnDestroy, OnInit} from '@angular/core';
import {CoverageTransactionsService} from '../coverage-transactions.service';
import {CartItemDto} from '../../shared/offer-claim/model/CartItemDto';
import {SaleStatus} from '../../shared/offer-claim/model/sale-status.enum';
import {OfferClaimSessionStore} from '../../shared/offer-claim/model/OfferClaimSessionStore';
import * as _ from 'lodash';
import {DialogService} from '../../shared/dialog/dialog.service';
import {TranslateService} from '@ngx-translate/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {OfferClaimDto} from '../../shared/offer-claim/model/OfferClaimDto';
import {AgreementModel} from '../../shared/dto/agreement.model';
import {DeliveryPeriodForOffer} from '../../shared/offer-claim/model/DeliveryPeriodForOffer';
import {ProductType} from '../../shared/agreement-details/agreement-enums.model';
import {ProductType as ProductTypeFroRate} from '../../shared/daily-rates/model/product-type.enum';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';
import {DeliveryPeriod} from '../../shared/dto/delivery.periods';
import {Market} from '../../shared/offer-claim/model/market.enum';
import {DailyPricesService} from '../../shared/services/daily-prices.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {OfferPeriod} from '../../shared/offer-claim/model/offer-period.enum';
import {OfferType} from '../../shared/offer-claim/model/offer-type.enum';
import {DailyRatesDto} from '../../shared/daily-rates/model/DailyRatesDto';
import {CoverageTransactionsUtil, EvaluationStatusConfig} from '../coverage-transactions.util';
import {ScheduledQuotationDialogComponent} from '../scheduled-quotation-dialog/scheduled-quotation-dialog.component';
import {CountdownHighlighterBase} from '../countdown-highlighter-base.components';
import {CartItemWithCurrentStatusDto} from "../../shared/offer-claim/model/CartItemWithCurrentStatusDto";
import {Router} from '@angular/router';
import {NewClaimDialogConfigBuilder, NewClaimDialogMode} from '../offer-claim/new-claim-dialog/new-claim-dialog.model';
import {OfferClaimService} from '../offer-claim.service';
import {StateStorageService} from '../../shared/auth';
import {DailyRateType} from '../../shared/daily-rates/model/DailyRatesType.enum';
import {DailyPriceStateChange} from '../../shared/daily-rates/model/DailyPriceStateChange';
import * as moment from 'moment';
import {
    DeliveryPeriodOpenPositionStateDTO,
    OpenPositionStateDTO
} from "../offer-claim/offer-claim-list/offer-claim-list.open-position-status.model";
import {UtilService} from "../../shared/services/util.service";
import {OfferClaimFieldsForOpenPositionDTO} from "../offer-claim/offer-claim-list/offer-claim-list.component";
import {ProgressBarConfig} from "../../shared/directives/progressbar.directive";
import {SortUtil} from "../../core/util/sort.util";
import {CountdownEvent} from 'ngx-countdown';
import {DateFormatUtil} from '../../core/util/date-format.util';

@Component({
  selector: 'jhi-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent extends CountdownHighlighterBase implements OnInit, OnDestroy {

  public readonly EVALUATION_STATUS_CONFIG: Map<SaleStatus, EvaluationStatusConfig> = CoverageTransactionsUtil.getEvaluationStatusConfig();
  public readonly EVALUATION_STATUSES: SaleStatus[] = Array.from(this.EVALUATION_STATUS_CONFIG.keys());

  public agreements: AgreementModel[] = [];
  public cartItems: CartItemWithCurrentStatusDto[] = [];
  public hasEvaluationStatus: boolean = false;
  public hasTimeRemain: boolean = false;
  public hasCartItems: boolean = true;
  public SaleStatus: typeof SaleStatus = SaleStatus;

  private originalCartItems: CartItemDto[] = [];
  private selectedCompanyId: number;
  private deliveryPeriods: DeliveryPeriodForOffer[] = [];
  private getCartItemsDebounced: () => void = _.debounce(() => this.getCartItems(), 900);
  private isActiveDailyPrices: boolean = false;
  private dailyPriceType: DailyRateType | null;
  private destroy: Subject<any> = new Subject();
  private selectedRate: DailyRatesDto;
  private hasRequestForQuotation: boolean = true;
  protected openPositionState: OpenPositionStateDTO;

  constructor(private stateStorageService: StateStorageService,
              private dialogService: DialogService,
              private translate: TranslateService,
              private dailyPricesService: DailyPricesService,
              private coverageTransactionsService: CoverageTransactionsService,
              private modalService: NgbModal,
              private router: Router,
              private offerClaimService: OfferClaimService) {
    super();
  }

  ngOnInit(): void {
    this.selectedCompanyId = this.stateStorageService.getSelectedCompanyId();
    this.getAgreementByPartner();
    this.getCartItems();
    this.setDailyPricesState();
    this.subscribeToRateSelection();
    this.subscribeToRateSelectionForCart();
    this.hasRequestForQuotationByPartner();
  }

  private setDailyPricesState(): void {
    this.dailyPricesService.dailyPricesStateChange.pipe(takeUntil(this.destroy)).subscribe((change: DailyPriceStateChange): void => {
      const type: DailyRateType | null = change.type;
      this.isActiveDailyPrices = type != null && type === DailyRateType.FIRM;
      if (type === DailyRateType.FIRM || (type === null && this.dailyPriceType === DailyRateType.FIRM)) {
        this.getCartItemsDebounced();
      }
      this.dailyPriceType = type;
    });
  }

  private subscribeToRateSelection(): void {
    this.selectedRate = this.dailyPricesService.selectedRate;
    this.dailyPricesService.changedSelectedRate.pipe(takeUntil(this.destroy)).subscribe((rate: DailyRatesDto) => {
      this.rateSelection(rate);
    });
  }

  private subscribeToRateSelectionForCart(): void {
    this.selectedRate = this.dailyPricesService.selectedRate;
    this.dailyPricesService.changedSelectedRateForCart.pipe(takeUntil(this.destroy)).subscribe((rate: DailyRatesDto) => {
      this.rateSelection(rate);
    });
  }

  rateSelection(rate: DailyRatesDto) {
    this.selectedRate = rate;
    if (!!rate) {
      this.setCartItemFromRate();
    }
  }

  public addNewOrModifyCartItem(item?: CartItemDto, fromDailyRate: boolean = false): void {
    this.dailyPricesService.navigateTo = null;
    const offerClaim: OfferClaimDto = !!item ? this.convertCartItemToOfferClaim(item) : new OfferClaimDto();

    if (fromDailyRate) {
      offerClaim.averagingTransaction = false;
    }

    this.offerClaimService.addNewOrEditOfferClaim(NewClaimDialogConfigBuilder
        .builder()
        .withMode(NewClaimDialogMode.PARTNER_MODE)
        .withClaim(offerClaim)
        .withDailyRate(this.selectedRate)
        .withAllDeliveryPeriod(this.deliveryPeriods)
        .withDisabledDeliveryPeriods(this.getDisabledDeliveryPeriods())
        .withSelectedDeliveryPeriod(this.findSelectedDeliveryPeriodForOffer(this.deliveryPeriods, item))
        .withLockedAveraging(fromDailyRate)
        .build(),
      () => {
        this.dailyPricesService.setSelectedRate(null);
        if (this.dailyPricesService.navigateBack === 'overviewTab') {
          this.router.navigate(['/hedge-transaction/overview']);
        } else {
          this.getCartItems();
        }
      });
  }

  public deleteCartItem(cartItemDto: CartItemDto): void {
    this.offerClaimService.deleteOfferClaims([cartItemDto.id], () => this.getCartItems(), () => this.getCartItems());
  }

  public cleanCart(): void {
    const cartItemsToDelete: number[] = this.originalCartItems
      .filter((cI: CartItemDto) => cI.saleStatus === SaleStatus.NEW)
      .map((cI: CartItemDto) => cI.id);

    this.offerClaimService.deleteOfferClaims(
      cartItemsToDelete,
      () => this.getCartItems(),
      () => this.getCartItems(),
      'offerClaim.button.cleanCart',
      'offerClaim.deleteDialog.cleanCartMessage'
    );
  }

  public remainTimeCountDownFinished(cartItem: CartItemDto): void {
    CoverageTransactionsUtil.removeCartItems(cartItem.deliveryPeriodId);
    this.getCartItemsDebounced();
  }

  public statusSelectionChanged(changedOfferClaim: CartItemWithCurrentStatusDto): void {
    const chosenCartItems: OfferClaimDto[] = this.cartItems
      .filter((cI: CartItemDto) => cI.saleStatus === SaleStatus.ACCEPTED)
      .map((cI: CartItemDto) => this.convertCartItemToOfferClaim(cI));

    this.coverageTransactionsService.validateFinalizingPurchase(chosenCartItems).subscribe(() => {
      // groups cart items by deliveryPeriodId and convert carts to OfferClaimSessionStore
      const groupedCartItems: { [key: number]: OfferClaimSessionStore[] } = _.chain(this.cartItems)
        .groupBy('deliveryPeriodId')
        .mapValues((cartItems: CartItemWithCurrentStatusDto[]): OfferClaimSessionStore[] =>
          cartItems.map((item: CartItemWithCurrentStatusDto): OfferClaimSessionStore => ({
            deliveryPeriodId: item.deliveryPeriodId,
            offerClaimId: item.id,
            saleStatus: item.saleStatus
          }))
        ).value();

      Object.keys(groupedCartItems).forEach((deliveryPeriodId: string): void => {
        CoverageTransactionsUtil.setCartItems(deliveryPeriodId, JSON.stringify(groupedCartItems[deliveryPeriodId]));
      });
      this.loadProgressValuesForProgressBars();
    }, error => {
      const errors: string[] = error.error.violations
        .map((violation) => this.translate.instant('validation.' + violation.errorCode));

      this.dialogService.errorAlert(this.translate.instant('offerClaim.button.finalizingAllActivePurchase'), errors).subscribe(() => {
        changedOfferClaim.saleStatus = SaleStatus.REJECTED;
      });
    });
  }

  public requestForQuotation(): void {
    const newStatusCartItems: OfferClaimDto[] = this.cartItems.filter((cI: CartItemWithCurrentStatusDto) => this.getConditionForRFQ(cI))
      .map((cI: CartItemDto) => this.convertCartItemToOfferClaim(cI));
    const modalRef: NgbModalRef = this.modalService.open(ScheduledQuotationDialogComponent, {});
    modalRef.componentInstance.claims = newStatusCartItems;
    modalRef.result.then((scheduledQuotation) => {
      if (!scheduledQuotation) {
        return;
      }

      this.coverageTransactionsService.requestForQuotation(scheduledQuotation).subscribe(() => {
        this.dialogService.alert(
          this.translate.instant('offerClaim.scheduledQuotation.dialog.successTitle'),
          this.translate.instant('offerClaim.scheduledQuotation.dialog.successContent'),
        ).subscribe();
        this.refreshCart(newStatusCartItems);
      });
    });
  }

  private getConditionForRFQ(cI: CartItemWithCurrentStatusDto): boolean {
    if (!!this.isActiveDailyPrices) {
      return !cI.price && !cI.timeRemain && CoverageTransactionsUtil.getCartSaleStatus(cI) === SaleStatus.NEW;
    }

    return CoverageTransactionsUtil.getCartSaleStatus(cI) === SaleStatus.NEW;
  }

  public finalizingAllPurchase(): void {
    const chosenCartItems: OfferClaimDto[] = this.cartItems.filter((cI: CartItemDto) =>
      cI.saleStatus === SaleStatus.ACCEPTED || cI.saleStatus === SaleStatus.REJECTED)
      .map((cI: CartItemDto) => this.convertCartItemToOfferClaim(cI));

    if (chosenCartItems.length === this.cartItems.length) {
      this.coverageTransactionsService.finalizingPurchase(chosenCartItems).subscribe(() => {
        this.refreshCart(chosenCartItems);
      });
    } else {
      this.dialogService.confirm(this.translate.instant('offerClaim.button.finalizingAllActivePurchase'), this.translate.instant('offerClaim.confirmDialog.finalizingAllActivePurchase'))
        .subscribe((ok: boolean): void => {
          if (ok) {
            this.coverageTransactionsService.finalizingPurchase(chosenCartItems).subscribe(() => {
              this.refreshCart(chosenCartItems);
            });
          }
        });
    }
  }

  public isNewCartItemButtonDisabled(): boolean {
    return !this.hasEmptyDeliveryPeriod() && this.originalCartItems.filter((cartItem: CartItemDto) =>
      cartItem.saleStatus !== SaleStatus.EVALUATION && cartItem.saleStatus !== SaleStatus.RFQ).length === 0;
  }

  public isModifyCartItemButtonDisabled(cartItem: CartItemDto): boolean {
    const originalItem: CartItemDto = this.originalCartItems
      .find((currCartItem: CartItemDto) => currCartItem.id === cartItem.id);
    return [SaleStatus.RFQ, SaleStatus.EVALUATION].indexOf(originalItem.saleStatus) !== -1;
  }

  public isDeleteCartItemButtonDisabled(cartItem: CartItemDto): boolean {
    return cartItem.saleStatus !== SaleStatus.NEW;
  }

  public isItemStatusChangeDisabled(cartItem: CartItemDto): boolean {
    const productSaleStatus: SaleStatus = this.originalCartItems
      .find((currCartItem: CartItemDto) => currCartItem.id === cartItem.id).saleStatus;
    return productSaleStatus !== SaleStatus.EVALUATION;
  }

  public isCleanCartButtonDisabled(): boolean {
    return this.originalCartItems.filter((cartItem: CartItemDto) => cartItem.saleStatus === SaleStatus.NEW)
      .length === 0;
  }

  public isRequestForQuotationButtonDisabled(): boolean {
    return this.hasRequestForQuotation || this.originalCartItems.filter((cartItem: CartItemDto) => cartItem.saleStatus === SaleStatus.NEW)
      .length === 0;
  }

  public isFinalizingAllPurchaseButtonDisabled(): boolean {
    const evaluationStatusCartItemIds: number[] = this.originalCartItems.filter((cartItem: CartItemDto) => cartItem.saleStatus === SaleStatus.EVALUATION)
      .map((cartItem: CartItemDto) => cartItem.id);
    const allEvaluationCartItemsStatusHasChosen: boolean = this.cartItems.filter((cartItem: CartItemDto) => evaluationStatusCartItemIds.indexOf(cartItem.id) !== -1)
      .filter((cartItem: CartItemDto) => cartItem.saleStatus === SaleStatus.EVALUATION)
      .length === 0;
    return evaluationStatusCartItemIds.length === 0 || !allEvaluationCartItemsStatusHasChosen;
  }

  isRevokeRequestForQuotationButtonDisabled(): boolean {
    const hasRequestForQuotation: boolean = !!_.find(this.originalCartItems, ['saleStatus', SaleStatus.RFQ]);
    return (!this.originalCartItems || this.originalCartItems.length === 0 || !hasRequestForQuotation);
  }

  public isThereAveragingOrHudexTransaction(): boolean {
    return !!this.cartItems && this.cartItems
      .filter((cI: CartItemDto) => cI.averagingTransaction || cI.market === Market.HUDEX)
      .length > 0;
  }

  public calcHasTimeRemain(): void {
    this.hasTimeRemain = false;
    this.cartItems.forEach((cartItem: CartItemDto) => {
      if (cartItem.timeRemain != null) {
        this.hasTimeRemain = true;
        return;
      }
    });
  }

  public getProductName(cartItem: CartItemDto): string {
    const periodNumber: any = _.isNil(cartItem.periodNumber) ? '' : cartItem.periodNumber;
    return `${cartItem.period}${periodNumber}-${new Date(cartItem.startDate).getFullYear()} HU ${cartItem.type}`;
  }

  public revokeRequestForQuotation(): void {
    this.dialogService.confirm(
      this.translate.instant('offerClaim.button.revokeRequestForQuotation'),
      this.translate.instant('offerClaim.confirmDialog.revokeRequestForQuotation'),
    ).subscribe((confirmed: boolean) => {
      if (confirmed) {
        const newStatusCartItems: OfferClaimDto[] = this.cartItems.filter((cI: CartItemWithCurrentStatusDto) => this.getConditionForRFQ(cI))
          .map((cI: CartItemDto) => this.convertCartItemToOfferClaim(cI));
        this.coverageTransactionsService.revokeRequestForQuotationFromCart(this.selectedCompanyId)
          .pipe(takeUntil(this.destroy))
          .subscribe(() => this.refreshCart(newStatusCartItems), () => this.refreshCart(newStatusCartItems));
      }
    });
  }

  hasRequestForQuotationByPartner(): void {
    this.coverageTransactionsService.hasRequestForQuotationByPartner(this.selectedCompanyId)
      .pipe(takeUntil(this.destroy))
      .subscribe((hasRequestForQuotation: boolean) => {
        this.hasRequestForQuotation = hasRequestForQuotation;
      });
  }

  canEditOrEvaluate(cartItem: CartItemWithCurrentStatusDto): boolean {
    return this.canEdit(cartItem) || this.canEvaluate(cartItem);
  }

  hasEditableItem(): boolean {
    const editableCartItem: CartItemWithCurrentStatusDto = this.cartItems.find((cartItem: CartItemWithCurrentStatusDto) => this.canEdit(cartItem));
    return editableCartItem !== undefined;
  }

  hasEvaluationItem(): boolean {
    const evaluationCartItem: CartItemWithCurrentStatusDto = this.cartItems.find((cartItem: CartItemWithCurrentStatusDto) => this.canEvaluate(cartItem));
    return evaluationCartItem !== undefined;
  }

  canEdit(cartItem: CartItemWithCurrentStatusDto): boolean {
    return cartItem.currentStatus === SaleStatus.NEW;
  }

  canEvaluate(cartItem: CartItemWithCurrentStatusDto): boolean {
    return cartItem.currentStatus === SaleStatus.EVALUATION;
  }

  private refreshCart(itemsToRefresh) {
    this.removeOfferClaimsFromSessionStorage(itemsToRefresh);
    this.getCartItems();
    this.hasRequestForQuotationByPartner();
  }

  private refreshAgreementList(agreements: AgreementModel[]) {
    this.deliveryPeriods = CoverageTransactionsUtil.convertAgreementsToDeliveryPeriodsForOffer(agreements)
      .filter((deliveryPeriod: DeliveryPeriodForOffer) => moment(deliveryPeriod.deliveryPeriod.endTime).isAfter(moment()));

    this.setCartItemFromRate();
  }

  private setCartItemFromRate(): void {
    const rate: DailyRatesDto = this.dailyPricesService.selectedRate;

    if (!rate) {
      return;
    }

    let selectedPeriod: DeliveryPeriodForOffer;

    let year: number;
    let period: string;
    let periodNumber: number;
    if (rate.type == ProductTypeFroRate.MONTHLY || rate.type == ProductTypeFroRate.QUARTERLY) {
      year = Number(rate.name.substring(0, 4));
      period = rate.name.substring(4, 5);
      periodNumber = Number(rate.name.substring(5, 7));
    } else if (rate.type == ProductTypeFroRate.YEARLY) {
      year = Number(rate.name);
      period = OfferPeriod.Y;
      periodNumber = 1;
    }

    this.deliveryPeriods.forEach((deliveryPeriod: DeliveryPeriodForOffer) => {
      const startYear: number = moment(deliveryPeriod.deliveryPeriod.startTime).tz('Europe/Budapest').year();
      if (year === startYear) {
        selectedPeriod = deliveryPeriod;
      }
      return;
    });

    if (!selectedPeriod) {
      return null;
    }

    const cartItem: any = {
      price: rate.currentPrice,
      period: period,
      periodNumber: periodNumber,
      market: Market.OTC,
      type: OfferType.BL,
      saleStatus: SaleStatus.NEW,
      agreementId: selectedPeriod.agreement.id,
      productType: selectedPeriod.agreement.productType,
      deliveryPeriodId: selectedPeriod.deliveryPeriod.id,
      deliveryPeriodStartTime: selectedPeriod.deliveryPeriod.startTime,
      deliveryPeriodEndTime: selectedPeriod.deliveryPeriod.endTime,
      startDate: selectedPeriod.deliveryPeriod.startTime,
      endDate: selectedPeriod.deliveryPeriod.endTime
    };

    setTimeout(() => this.addNewOrModifyCartItem(cartItem, true));
  }

  private getCartItems(): void {
    this.coverageTransactionsService.getOfferClaimsByPartnerId(this.selectedCompanyId).subscribe((cartItems: CartItemDto[]) => {
      cartItems = cartItems.filter((cartItem: CartItemDto) => cartItem.saleStatus !== SaleStatus.ACCEPTED);
      this.originalCartItems = _.cloneDeep(cartItems);
      this.hasEvaluationStatus = !!cartItems.find((cartItem: CartItemDto) => cartItem.saleStatus === SaleStatus.EVALUATION);
      this.cartItems = this.offerClaimService.setSaleStatusIfExistCart(cartItems).sort(this.sortCartItems);
      this.hasCartItems = this.cartItems && this.cartItems.length > 0;
      this.calcHasTimeRemain();
      this.loadProgressValuesForProgressBars();
    });
  }

  private convertCartItemToOfferClaim(cartItemDto: CartItemDto): OfferClaimDto {
    const deliveryPeriod: DeliveryPeriodForOffer = this.findSelectedDeliveryPeriodForOffer(this.deliveryPeriods, cartItemDto);
    return !cartItemDto ? new OfferClaimDto() : {
      ...cartItemDto,
      deliveryPeriod: !!deliveryPeriod ? deliveryPeriod.deliveryPeriod : null,
      tranche: cartItemDto.productType === ProductType.STEP_BY_STEP ? cartItemDto.quantity : null,
      quantity: cartItemDto.productType === ProductType.CASH_OUT ? cartItemDto.quantity : null,
      quotationRequester: cartItemDto.quotationRequester,
      createdByPartner: cartItemDto.createdByPartner,
      offerClaimHead: cartItemDto.offerClaimHead,
      averagingTransaction: cartItemDto.averagingTransaction,
      averagingStartDate: cartItemDto.averagingStartDate,
      averagingEndDate: cartItemDto.averagingEndDate,
      quantityInTimeSeries: false,
      priceType: cartItemDto.priceType
    } as OfferClaimDto;
  }

  private findSelectedDeliveryPeriodForOffer(deliveryPeriods: DeliveryPeriodForOffer[], cartItem: CartItemDto): DeliveryPeriodForOffer {
    if (!cartItem) return null;
    return deliveryPeriods.find((deliveryPeriod: DeliveryPeriodForOffer) => deliveryPeriod.deliveryPeriod.id === cartItem.deliveryPeriodId);
  }

  private sortCartItems: (pI: CartItemDto, cI: CartItemDto) => number = (prevItem: CartItemDto, currItem: CartItemDto) => {
    if (moment(prevItem.deliveryPeriodStartTime).isBefore(moment(currItem.deliveryPeriodStartTime))) {
      return -1;
    }
    if (moment(prevItem.deliveryPeriodStartTime).isAfter(moment(currItem.deliveryPeriodStartTime))) {
      return 1;
    }
    if (moment(prevItem.startDate).isBefore(moment(currItem.startDate))) {
      return -1;
    }
    if (moment(prevItem.startDate).isAfter(moment(currItem.startDate))) {
      return 1;
    }
    if (moment(prevItem.endDate).isBefore(moment(currItem.endDate))) {
      return -1;
    }
    if (moment(prevItem.endDate).isAfter(moment(currItem.endDate))) {
      return 1;
    }
    return 0;
  }

  private hasEmptyDeliveryPeriod(): boolean {
    const deliveryPeriods: DeliveryPeriod[] = this.deliveryPeriods.map((dp: DeliveryPeriodForOffer) => dp.deliveryPeriod);
    const cartItemDeliveryPeriodIds: number[] = this.cartItems.map((cartItem: CartItemDto) => cartItem.deliveryPeriodId);
    return deliveryPeriods.filter((dp: DeliveryPeriod) => cartItemDeliveryPeriodIds.indexOf(dp.id) === -1).length > 0;
  }

  private getDisabledDeliveryPeriods(): DeliveryPeriodForOffer[] {
    const disabledDeliveryPeriodIds: number[] = this.cartItems
      .filter((cartItem: CartItemDto) => cartItem.saleStatus === SaleStatus.EVALUATION || cartItem.saleStatus === SaleStatus.RFQ)
      .map((cartItem: CartItemDto) => cartItem.deliveryPeriodId);
    return this.deliveryPeriods.filter((dp: DeliveryPeriodForOffer) => disabledDeliveryPeriodIds.indexOf(dp.deliveryPeriod.id) !== -1);
  }

  private removeOfferClaimsFromSessionStorage(cartItems: OfferClaimDto[]): void {
    const deliveryPeriodIds: Set<number> = new Set(cartItems.map((cartItem: OfferClaimDto) => cartItem.deliveryPeriod.id));
    deliveryPeriodIds.forEach((deliveryPeriodId: number) =>
      CoverageTransactionsUtil.removeCartItems(String(deliveryPeriodId)));
  }

  private destroyDailyPricesInterval(): void {
    this.destroy.next(true);
    this.destroy.complete();
  }

  private getAgreementByPartner(): void {
    this.coverageTransactionsService.getAgreementsByPartner(this.selectedCompanyId).subscribe((agreements: AgreementModel[]) => {
      this.agreements = agreements;
      this.refreshAgreementList(agreements);
    });
  }

  public translatePurchaseStatus(cartItemInput: CartItemWithCurrentStatusDto): string {
    if (!cartItemInput) {
      return '';
    }

    if (!cartItemInput.purchaseStatus) {
      return '';
    }

    if (this.isAveragingTransaction(cartItemInput)) {
      return this.translate.instant('offerClaimHistory.averagingPurchaseStatus.' + cartItemInput.purchaseStatus);
    } else {
      return this.translate.instant('offerClaimHistory.purchaseStatus.' + cartItemInput.purchaseStatus);
    }
  }

  private isAveragingTransaction(cartItem: CartItemWithCurrentStatusDto): boolean {
    return !!cartItem
      && !!cartItem.averagingStartDate
      && !!cartItem.averagingEndDate;
  }

  ngOnDestroy(): void {
    this.destroyDailyPricesInterval();
  }

  getSumOfNetPrices(): number {
    return CoverageTransactionsUtil.getSumOfNetPrices(this.cartItems);
  }

  getPeriodProgressBarLabel(periodState: DeliveryPeriodOpenPositionStateDTO): string {
    return this.translate.instant("offerClaim.progressBar.periodBarLabel",
      {deliveryPeriod: `${DateFormatUtil.formatToLocalDate(periodState.startDate)} - ${DateFormatUtil.formatToLocalDate(periodState.endDate)}`});
  }

  private loadProgressValuesForProgressBars() {
    let dtos: OfferClaimFieldsForOpenPositionDTO[] = this.cartItems.map(cartItems => {
      return {
        offerClaimId: cartItems.id,
        isAccepted: cartItems.saleStatus === SaleStatus.ACCEPTED
      };
    });

    this.coverageTransactionsService.getAcceptedHedgeLimitDetails(dtos).subscribe((openPositionStateDTO: OpenPositionStateDTO) => {
      this.sortOpenPositionStates(openPositionStateDTO);
      this.openPositionState = openPositionStateDTO;
    });
  }

  getProgressBarConfig(agreementOpenPositionPercent: number): ProgressBarConfig {
    let agreementValue: number = +UtilService.round(agreementOpenPositionPercent, 2);
    return {
      minValue: 0,
      maxValue: 100,
      currentValue: agreementValue,
      warnBefore: undefined,
      warnAfter: 90
    };
  }

  private sortOpenPositionStates(openPositionStateDTO: OpenPositionStateDTO) {
    openPositionStateDTO.agreementOpenPositionStates.forEach(agreementPosition => {
      agreementPosition.deliveryPeriodOpenPositionStates.sort((a, b) =>
        SortUtil.sortDate(a.startDate, b.startDate));
    });

    openPositionStateDTO.agreementOpenPositionStates.sort((a, b) =>
      SortUtil.sortDate(a.deliveryPeriodOpenPositionStates[0].startDate, b.deliveryPeriodOpenPositionStates[0].startDate));
  }

    onCountdownEvent($event: CountdownEvent, cartItem: CartItemWithCurrentStatusDto) {
        switch ($event.action) {
            case 'start':
                this.startCountDown(cartItem.id, cartItem.timeRemain);
                break;
            case 'done':
                this.remainTimeCountDownFinished(cartItem);
                break;
        }
    }
}
