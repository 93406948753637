<form [formGroup]="filterFrom">
    <div>
        <div class="d-inline-block mr-4 mb-3 filter-item">
            <mat-form-field style="max-width: 100px;">
                <mat-select
                        placeholder="{{'consumption.display-mode.resolution' | translate }}"
                        [formControl]="resolution">
                    <span *ngFor="let entry of timeSeriesResolutions | keys">
                        <mat-option [value]="entry.value.value" *ngIf="entry.value.enabled">
                            {{entry.value.description}}
                        </mat-option>
                    </span>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="d-inline-block mb-3 filter-item">
            <div class="d-flex flex-row justify-content-center mr-4">
                <mat-slide-toggle color="primary" (change)="listenFilterItemChange('min', $event.checked)"
                                  *ngIf="showMinMax"
                                  [formControl]="minSelected">{{'consumption.diagram.diagram-options.min' | translate}}
                </mat-slide-toggle>
                <mat-slide-toggle color="primary" (change)="listenFilterItemChange('max', $event.checked)"
                                  *ngIf="showMinMax"
                                  [formControl]="maxSelected">{{'consumption.diagram.diagram-options.max' | translate}}
                </mat-slide-toggle>
                <mat-slide-toggle color="primary" (change)="listenFilterItemChange('avg', $event.checked)"
                                  *ngIf="showAvg"
                                  [formControl]="avgSelected">{{'consumption.diagram.diagram-options.avg' | translate}}
                </mat-slide-toggle>
                <mat-slide-toggle color="primary" *ngIf="showTemperature"
                                  [formControl]="tempSelected">{{'consumption.diagram.diagram-options.temp' | translate}}
                </mat-slide-toggle>
                <mat-slide-toggle color="primary"
                                  *ngIf="_filterModel.resolution === 'MIN_15' && _filterModel.selectedPods?.length==1"
                                  [formControl]="perfSelected">{{'consumption.diagram.diagram-options.performance' | translate}}
                </mat-slide-toggle>

                <mat-slide-toggle color="primary" *ngIf="showSchedule"
                                  matTooltip="A funkció bekapcsolásával az összes POD kijelölésre fog kerülni" [matTooltipPosition]="'above'"
                                  (change)="listenFilterItemChange('schedule', $event.checked)"
                                  [formControl]="scheduleSelected">{{'consumption.diagram.diagram-options.schedule' | translate}}
                </mat-slide-toggle>
            </div>
        </div>

    </div>
</form>
<div class="filter-details-container">
    <div class="filter-details-content">
        <p class="filter-details hidden" [ngClass]="showFilterDetails ? 'visible' : ''">
            <span *ngFor="let podGroup of _filterModel.selectedPodGroups; let last = last;">{{podGroup?.name}}<span
                    *ngIf="!last">, </span></span>
            <span *ngIf="_filterModel.selectedPodGroups.length>0"> | </span>
            <span *ngFor="let pod of _filterModel.selectedPods; let last = last;">{{pod?.name}} {{pod?.podCode}}<span
                    *ngIf="!last">, </span></span>
            <span *ngIf="_filterModel.selectedPods.length>0"> | </span>
            <span *ngFor="let period of _filterModel.periodParameters; let first = first;">
            <span *ngIf="period.dateTo != null && period.dateFrom != null">
            <span *ngIf="!first">, </span>
                {{period.dateFrom | cezNgbDate | cezDate}} - {{period.dateTo | cezNgbDate | cezDate}}
            </span>
        </span>
        </p>
    </div>
    <mat-icon *ngIf="!showFilterDetails" (click)="showFilterDetails = true">keyboard_arrow_down</mat-icon>
    <mat-icon *ngIf="showFilterDetails" (click)="showFilterDetails = false">keyboard_arrow_up</mat-icon>
</div>
