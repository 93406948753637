import {Pipe, PipeTransform} from '@angular/core';
import {DateFormatUtil} from '../../core/util/date-format.util';

@Pipe({
    name: 'cezDate'
})
export class CezDatePipe implements PipeTransform {

    public transform(date: any): string | null {
        return DateFormatUtil.formatToLocalDate(date);
    }

}
