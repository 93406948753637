import {Pipe, PipeTransform} from "@angular/core";
import {DateFormatUtil} from '../../core/util/date-format.util';

@Pipe({
    name: 'cezDateTime'
})
export class CezDateTimePipe implements PipeTransform {

    public transform(date: any): string | null {
        return DateFormatUtil.formatToLocalDateTime(date);
    }

}
