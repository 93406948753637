<h6 mat-dialog-title>{{ 'offerClaim.cutoffDealDialog.title' | translate }}</h6>
<div mat-dialog-content>
    <div *ngFor="let cutoffDeals of data; let isLast = last"
         class="row border-top cutoff-deal-container"
         [class.border-bottom]="isLast">
        <div class="col-3 pt-2">
            <p>
                {{ 'offerClaim.tableField.deliveryPeriod' | translate }}: <br>
                <span *ngIf="cutoffDeals.localDeliveryPeriod">
                    {{ cutoffDeals.localDeliveryPeriod.start | cezDate }}
                    - {{ cutoffDeals.localDeliveryPeriod.end | cezDate }}
                </span>
            </p>
            <p>
                {{ 'offerClaim.tableField.salesforceDeliveryPeriod' | translate }}: <br>
                <span *ngIf="cutoffDeals.salesForceDeliveryPeriod; else sfDeliveryPeriodNotExist">
                    {{ cutoffDeals.salesForceDeliveryPeriod.start | cezDate }}
                    - {{ cutoffDeals.salesForceDeliveryPeriod.end | cezDate }}
                </span>
                <ng-template #sfDeliveryPeriodNotExist><i>{{ 'offerClaim.cutoffDealDialog.notExist' | translate }}</i>
                </ng-template>
            </p>
        </div>
        <div class="col-9">
            <table class="table table-striped">
                <thead>
                <td class="table-content-text border-top-0">
                    {{ 'offerClaim.tableField.dealId' | translate }}
                </td>
                <td class="table-content-text border-top-0">
                    {{ 'offerClaim.tableField.product' | translate }}
                </td>
                <td class="table-content-text border-top-0">
                    {{ 'offerClaim.tableField.purchaseType' | translate }}
                </td>
                <td class="table-content-text border-top-0" style="width: 20px;">
                    <mat-icon class="cursor--pointer"
                              (click)="copyToClipboard(cutoffDeals.deals)">content_copy</mat-icon>
                </td>
                </thead>
                <tbody>
                <tr *ngFor="let offerClaim of cutoffDeals.deals">
                    <td class="table-content-text">
                        {{ offerClaim.dealId }}
                    </td>
                    <td class="table-content-text">
                        {{ getProductName(offerClaim) }}
                    </td>
                    <td class="table-content-text">
                        {{ getPurchaseStatus(offerClaim) }}
                    </td>
                    <td class="table-content-text"></td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<div mat-dialog-actions align="end">
    <button mat-raised-button
            color="primary"
            [mat-dialog-close] cdkFocusInitial>
        {{ 'common.action.ok' | translate }}
    </button>
</div>
