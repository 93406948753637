import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {MAT_DATE_FORMATS, MatDateFormats} from '@angular/material/core';
import {MatDatepicker} from '@angular/material/datepicker';
import * as moment from 'moment';

import {Moment} from 'moment-timezone';
import {DateFormatUtil} from '../../core/util/date-format.util';

@Component({
    selector: 'monthly-date-picker',
    templateUrl: 'monthly-date-picker.component.html',
    styleUrls: ['monthly-date-picker.component.scss'],
    providers: [
        {provide: MAT_DATE_FORMATS, useFactory: (): MatDateFormats => DateFormatUtil.getIntervalMaterialDateFormats()}
    ]
})
export class MonthlyDatePickerComponent implements OnInit {


    @Input() set inputDate(data: Moment) {
        if (data) {
            const date = this.dateCtrl.value;
            date.year(data.year());
            date.month(data.month());
            this.dateCtrl.setValue(date);
        }
    }

    @Input() min: Moment;
    @Input() placeHolder: string;
    @Output() selectDate: EventEmitter<Moment> = new EventEmitter();
    dateCtrl: UntypedFormControl = new UntypedFormControl(moment().startOf('month'));
    prevDate: Moment = moment();

    ngOnInit() {
        if (!this.placeHolder) {
            this.placeHolder = 'DateInterval.default';
        }
    }

    chosenMonthHandler(date: Moment, datepicker: MatDatepicker<Moment>) {
        this.sendEvent(date);
        datepicker.close();
    }

    sendEvent(selectedDate: Moment) {
        if (!selectedDate) {
            return;
        }

        const date = this.min && this.min.isAfter(selectedDate) ? this.min : selectedDate;

        if (!this.prevDate.isSame(date)) {
            this.dateCtrl.setValue(date.startOf('month'));
            this.prevDate = date.startOf('month').clone();
            this.selectDate.emit(this.dateCtrl.value.startOf('month').clone());
        }
    }

}
