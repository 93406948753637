export type HState =
    'ONE'
    | 'TWO'
    | 'THREE'
    | 'FOUR'

export const HState = {
    ONE: 'ONE' as HState,
    TWO: 'TWO' as HState,
    THREE: 'THREE' as HState,
    FOUR: 'FOUR' as HState
};
