import {Component, Inject} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';
import DataUpdateTrackingModel from '../../dto/data-update-tracking.model';
import {DataUpdateTrackingService} from '../../services/data-update-tracking.service';
import {DataUpdateModel, DeliveryPeriodDataUpdateModel} from '../../dto/data-update.model';
import {TranslateService} from '@ngx-translate/core';
import {DateFormatUtil} from '../../../core/util/date-format.util';

@Component({
    selector: 'rts-last-updates',
    templateUrl: './last-updates.component.html',
    styleUrl: './last-updates.component.scss'
})
export class LastUpdatesComponent {

    constructor(@Inject(MAT_DIALOG_DATA) public data: DataUpdateTrackingModel,
                private dataUpdateTrackingService: DataUpdateTrackingService,
                private translate: TranslateService) {
    }

    public getDateValue(dataUpdateModel: DataUpdateModel): string {
        return DateFormatUtil.formatToLocalDate(dataUpdateModel.lastModifiedDate);
    }

    public getFormattedLastUpdate(dataUpdateModel: DeliveryPeriodDataUpdateModel) {
        return dataUpdateModel?.lastModifiedBy ?
            DateFormatUtil.formatToLocalDate(dataUpdateModel.lastModifiedDate) :
            this.translate.instant('dataUpdateTracking.noUpload');
    }

    public getFormattedLastUpdateBy(dataUpdateModel: DeliveryPeriodDataUpdateModel): string {
        return dataUpdateModel?.lastModifiedBy
            ? '(' + dataUpdateModel.lastModifiedBy + ')'
            : '';
    }

    public isLastUpdateStale(dataUpdateModel: DeliveryPeriodDataUpdateModel) {
        return dataUpdateModel ?
            this.dataUpdateTrackingService.isLastUpdateStale(dataUpdateModel, dataUpdateModel.deliveryPeriodDto) :
            false;
    }

}
