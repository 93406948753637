import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import * as moment from 'moment';
import {Moment} from 'moment';
import {TimeSeriesResolution} from '../model/timeseries-resolution';
import {DisplayModeChangeEventModel} from './display-mode-change-event.model';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';

@Component({
    selector: 'jhi-display-mode',
    templateUrl: './display-mode.component.html',
    styleUrls: []
})
export class DisplayModeComponent implements OnInit {

    readonly min = moment("2000-01-01");
    readonly max = moment().add(100, 'years').startOf('day');

    timeSeriesResolutions = TimeSeriesResolution;

    form: UntypedFormGroup;

    @Input() from: Moment;
    @Input() to: Moment;
    @Input() resolution: TimeSeriesResolution;

    @Output() settingsChanged: EventEmitter<DisplayModeChangeEventModel> = new EventEmitter<DisplayModeChangeEventModel>();

    constructor(private fb: UntypedFormBuilder) {
    }

    ngOnInit() {
        this.form = this.fb.group({
            'from': [this.from.clone(), Validators.required],
            'to': [this.to.clone().subtract(1, 'day'), Validators.required],
            'resolution': [this.resolution || TimeSeriesResolution.HOURLY, Validators.required]
        });

        this.form.valueChanges
            .pipe(distinctUntilChanged(), debounceTime(1000))
            .subscribe(value => {
                if (this.form.valid) {
                    this.settingsChanged.emit({
                        from: value.from,
                        to: value.to.clone().add(1, 'day'),
                        resolution: value.resolution
                    });
                }
            });

        this.form.controls['from'].valueChanges.subscribe((startDate: Moment) => {
            const endDate: Moment = this.form.controls['to'].value;
            if (startDate && endDate && startDate.isAfter(endDate)) {
                this.form.controls['to'].setValue(startDate.clone(), {emitEvent: false});
            }
        });

        this.form.controls['to'].valueChanges.subscribe((endDate: Moment) => {
            const startDate: Moment = this.form.controls['from'].value;
            if (startDate && endDate && startDate.isAfter(endDate)) {
                this.form.controls['from'].setValue(endDate.clone(), {emitEvent: false});
            }
        });
    }

}
