import * as moment from 'moment';
import {StateStorageService} from "../../shared/auth";
import {LocatorService} from "../../shared/services/locator.service";
import {Moment} from 'moment-timezone';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {MatDateFormats} from '@angular/material/core';
import {LocaleSpecification} from 'moment/moment';
import {AxisDateTimeLabelFormatsOptions} from 'highcharts';

export type LangKey = 'HU' | 'EN';

export class DateFormatUtil {

    public static readonly HU_LANG_KEY: LangKey = 'HU';
    public static readonly EN_LANG_KEY: LangKey = 'EN';

    public static readonly PATTERN = {
        TIME: {
            HU: 'HH:mm',
            EN: 'hh:mm a'
        },
        DAYTIME: {
            HU: 'EEEE HH:mm',
            EN: 'EEEE hh:mm a'
        },
        DATE: {
            HU: 'YYYY.MM.DD',
            EN: 'DD/MM/YYYY'
        },
        DATETIME: {
            HU: 'YYYY.MM.DD HH:mm',
            EN: 'DD/MM/YYYY hh:mm a'
        },
        YEARMONTH: {
            SIMPLE: {
                HU: 'YYYY MM',
                EN: 'MM YYYY'
            },
            VERBOSE: {
                HU: 'YYYY MMMM',
                EN: 'MMMM YYYY'
            }
        },
        DASHED: 'YYYY-MM-DD',
        YEAR: 'YYYY',
    };

    public static readonly INTERVAL_MAT_DATE_FORMATS: { HU: MatDateFormats, EN: MatDateFormats } = {
        HU: {
            parse: {
                dateInput: 'YYYY MMMM'
            },
            display: {
                dateInput: 'YYYY MMMM',
                monthYearLabel: 'YYYY MMM',
                dateA11yLabel: 'LL',
                monthYearA11yLabel: 'YYYY MMMM'
            }
        },
        EN: {
            parse: {
                dateInput: 'MMMM YYYY'
            },
            display: {
                dateInput: 'MMMM YYYY',
                monthYearLabel: 'MMM YYYY',
                dateA11yLabel: 'LL',
                monthYearA11yLabel: 'MMMM YYYY'
            }
        }
    };

    public static formatToLocalTime(date: Date | Moment | string | null | undefined): string | null {
        return date ? moment(date).format(this.getTimeFormat()) : null;
    }

    public static formatToLocalDayTime(date: Date | Moment | string | null | undefined): string | null {
        return date ? moment(date).format(this.getDayTimeFormat()) : null;
    }

    public static formatToLocalDate(date: Date | Moment | string | null | undefined): string | null {
        return date ? moment(date).format(this.getDateFormat()) : null;
    }

    public static formatToLocalDateTime(date: Date | Moment | string | null | undefined): string | null {
        return date ? moment(date).format(this.getDateTimeFormat()) : null;
    }

    public static formatToYearMonth(date: Date | Moment | string | null | undefined, verbose: boolean = false): string | null {
        return date ? moment(date).format(this.getYearMonthFormat(verbose)) : null;
    }

    public static formatToDashed(date: Date | Moment | string): string {
        return moment(date).format(DateFormatUtil.PATTERN.DASHED);
    }

    public static formatToYear(date: Date | Moment | string): string {
        return moment(date).format(DateFormatUtil.PATTERN.YEAR);
    }

    public static formatNgbToLocalDate(date: NgbDateStruct): string {
        if (!date) {
            return '';
        }
        const d: Moment = moment({year: date.year, month: date.month - 1, date: date.day});

        return d.isValid() ? DateFormatUtil.formatToLocalDate(d) : '';
    }

    public static transformNgbToDate(date: NgbDateStruct): Date {
        if (!date) {
            return null;
        }
        const d: Moment = moment({year: date.year, month: date.month - 1, date: date.day});

        return d.toDate();
    }

    public static transformDateToNgb(date: Date | Moment | string | null | undefined): NgbDateStruct | null {
        if (!date) {
            return null;
        }
        const d: Moment = moment(date);

        return d.isValid() ? {year: d.year(), month: d.month() + 1, day: d.date()} : null;
    }

    public static getTimeFormat(langKey?: LangKey): string {
        return DateFormatUtil.PATTERN.TIME[langKey || this.getLangKey()];
    }

    public static getDayTimeFormat(langKey?: LangKey): string {
        return DateFormatUtil.PATTERN.DAYTIME[langKey || this.getLangKey()];
    }

    public static getDateFormat(langKey?: LangKey): string {
        return DateFormatUtil.PATTERN.DATE[langKey || this.getLangKey()];
    }

    public static getDateTimeFormat(langKey?: LangKey): string {
        return DateFormatUtil.PATTERN.DATETIME[langKey || this.getLangKey()];
    }

    public static getYearMonthFormat(verbose: boolean = false, langKey?: LangKey): string {
        return verbose ?
            DateFormatUtil.PATTERN.YEARMONTH.VERBOSE[langKey || this.getLangKey()] :
            DateFormatUtil.PATTERN.YEARMONTH.SIMPLE[langKey || this.getLangKey()];
    }

    public static getIntervalMaterialDateFormats(langKey?: LangKey): MatDateFormats {
        return DateFormatUtil.INTERVAL_MAT_DATE_FORMATS[langKey || this.getLangKey()];
    }

    public static getHighchartsDateTimeLabelFormats(): AxisDateTimeLabelFormatsOptions {
        return this.getLangKey() === DateFormatUtil.EN_LANG_KEY ?
            {
                minute: '%H:%M',
                hour: '%H:%M',
                day: '%d/%m/%Y',
                month: '%m/%Y',
                week: '%d/%m/%Y',
                year: '%Y'
            } :
            {
                minute: '%H:%M',
                hour: '%H:%M',
                day: '%Y.%m.%d',
                month: '%Y.%m.',
                week: '%Y.%m.%d',
                year: '%Y'
            };
    }

    public static registerMomentLocaleData(): void {
        moment.updateLocale('en', {
            longDateFormat: {
                l: DateFormatUtil.PATTERN.DATE.EN,
                L: DateFormatUtil.PATTERN.DATE.EN,
                LL: 'DD MMMM YYYY',
                LLL: 'DD MMMM YYYY HH:mm',
                LLLL: 'dddd, DD MMMM YYYY HH:mm'
            }
        } as LocaleSpecification);

        moment.updateLocale('hu', {
            longDateFormat: {
                l: DateFormatUtil.PATTERN.DATE.HU,
                L: DateFormatUtil.PATTERN.DATE.HU,
                LL: 'YYYY MMMM DD',
                LLL: 'YYYY MMMM DD HH:mm',
                LLLL: 'YYYY MMMM DD, dddd HH:mm'
            }
        } as LocaleSpecification);
    }

    private static getLangKey(): LangKey {
        const stateStorageService: StateStorageService = <StateStorageService>LocatorService.injector?.get(StateStorageService);
        return stateStorageService?.getLanguage()?.toUpperCase() === DateFormatUtil.EN_LANG_KEY ?
            DateFormatUtil.EN_LANG_KEY : DateFormatUtil.HU_LANG_KEY;
    }

}
