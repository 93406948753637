import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Moment} from 'moment';
import {Observable} from 'rxjs';
import {AdditionalTimeSeriesDto} from '../../price/model/additional-time-series';
import {ProductDto} from '../../price/model/productDto';
import {TimeSeriesResolution} from '../../price/model/timeseries-resolution';
import {TimeSeriesData} from '../../price/model/timeseries/time-series-data';
import {SpotDto} from '../../price/model/spot-dto';
import {DateFormatUtil} from '../../core/util/date-format.util';

@Injectable({
    providedIn: 'root'
})
export class MarketPriceService {

    constructor(private http: HttpClient) {}

    public listProducts(): Observable<Array<ProductDto>> {
        return this.http.get<ProductDto[]>('/api/market-price/product');
    }

    public listSpotTimeSeries(): Observable<Array<AdditionalTimeSeriesDto>> {
        return this.http.get<AdditionalTimeSeriesDto[]>('/api/market-price/spot-timeseries');
    }

    public listSpotTimeSeriesAsDtos(): Observable<Array<SpotDto>> {
        return this.http.get<SpotDto[]>('/api/market-price/spot-timeseries-as-dtos');
    }

    public listAdditionalTimeSeries(): Observable<Array<AdditionalTimeSeriesDto>> {
        return this.http.get<AdditionalTimeSeriesDto[]>('/api/market-price/additional-timeseries');
    }

    public getTimeSeries(headerId: number, projectionProfileName: string, from: Moment, to: Moment, resolution: TimeSeriesResolution): Observable<TimeSeriesData> {
        let params: HttpParams = new HttpParams({
            fromObject: {
                headerId: headerId.toFixed(0),
                from: DateFormatUtil.formatToDashed(from),
                to: DateFormatUtil.formatToDashed(to),
                resolution: resolution
            }
        });

        if (projectionProfileName !== undefined) {
            params = params.append('projectionProfileName', projectionProfileName);
        }

        return this.http.get<TimeSeriesData>('/api/market-price/timeseries-data', {params: params});
    }

    public getSpotInDelivery(headerId: number, from: Moment, to: Moment, resolution: TimeSeriesResolution): Observable<TimeSeriesData> {
        const params = new HttpParams()
            .set('headerId', headerId.toString())
            .set('from', DateFormatUtil.formatToDashed(from))
            .set('to', DateFormatUtil.formatToDashed(to))
            .set('resolution', resolution);


        return this.http.get<TimeSeriesData>('/api/market-price/spot-in-delivery', {params: params});
    }
}
