import {DecimalPipe} from '@angular/common';
import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Chart} from 'angular-highcharts';
import {finalize} from 'rxjs/operators';
import {DiagramService} from '../../shared/services/diagram.service';
import {NgBDatePickerConvertService} from '../../shared/services/ngb.datepicker.convert.service';
import {PodValueService} from '../../shared/services/pod-value.service';
import {FilterModel} from '../filter-basic/filter.model';
import {DateFormatUtil} from '../../core/util/date-format.util';

@Component({
    selector: 'jhi-performance-heatmap-diagram',
    templateUrl: './performance-heatmap-diagram.component.html',
    styles: []
})
export class PerformanceHeatmapDiagramComponent implements OnInit {

    @Input() widget: boolean;
    chartData: any[];
    loading: boolean;
    performanceHeatMapChart: Chart;
    filterModel: FilterModel;

    constructor(private dateConverter: NgBDatePickerConvertService,
                private translateService: TranslateService,
                private podValueService: PodValueService,
                private diagramService: DiagramService,
                private decimalPipe: DecimalPipe
    ) {
    }

    ngOnInit() {
        if (!this.widget) {
            this.performanceHeatMapChart = this.diagramService.createDummyChart();
        }
    }

    getReportParametersForRun(filterModel: FilterModel) {
        this.loading = true;
        this.filterModel = filterModel;
        let dateTo = {
            year: filterModel.periodParameters[0].dateTo.year,
            month: filterModel.periodParameters[0].dateTo.month,
            day: filterModel.periodParameters[0].dateTo.day
        };
        //dateTo.day += 1;
        let apiParams = {
            startTime: this.dateConverter.convertToDate(filterModel.periodParameters[0].dateFrom).toISOString(),
            endTime: this.dateConverter.convertToDate(dateTo).toISOString(),
            resolution: 'DAY',
            podIds: filterModel.selectedPods.map(r => {
                return r.id;
            }),
            podGroupIds: filterModel.selectedPodGroups.map(r => r.id)
        };

        this.podValueService.getSpectrumData(apiParams)
            .pipe(finalize(() => this.loading = false))
            .subscribe(r => {
                this.calculateData(r.daily15MinKWValues, r.resolution, r.startTime);
            });
    }

    calculateData(valuesKw: number[][], resolution: string, startTime: string) {
        let category_resolution: number = 96;
        let actData: any[][] = [];

        for (let dayIndex = 0; dayIndex < valuesKw.length; dayIndex++) {
            let currentDay = this.diagramService.addDateByResolutionForPerformanceAnalysis(startTime, dayIndex, 'DAY');

            for (let valueIndex = 0; valueIndex < valuesKw[dayIndex].length; valueIndex++) {
                let current15Minutes = this.diagramService.addDateByResolutionForPerformanceAnalysis(currentDay.toISOString(), valueIndex, 'MIN_15');

                let xAxisDisplay = new Date(Date.UTC(currentDay.getFullYear(), currentDay.getMonth(), currentDay.getDate(), 0, 0));
                let yAxisDisplay = current15Minutes.getHours() + current15Minutes.getMinutes() / 100;
                let dataIndex = dayIndex * category_resolution + valueIndex;
                actData[dataIndex] = [xAxisDisplay.getTime(), yAxisDisplay, valuesKw[dayIndex][valueIndex]];
            }
        }

        this.chartData = [{
            name: 'Spektrum elemzés',
            color: '#f24f00',
            data: actData
        }];

        this.calculateChart();
    }

    getMinValue() {
        let min = this.chartData[0].data.map(r => r[2]).reduce(function (a, b) {
            return (a <= b) ? a : b;
        });
        console.log('Min érték:' + min);
        return min;
    }

    getMaxValue() {
        let max = this.chartData[0].data.map(r => r[2]).reduce(function (a, b) {
            return (a >= b) ? a : b;
        });

        return max;
    }

    getMinDate() {
        return this.chartData[0].data[0][0];
    }

    getMaxDate() {
        let length = this.chartData[0].data.length;
        return this.chartData[0].data[length - 1][0];
    }

    calculateChart() {
        let _this = this;
        let currentLang = this.translateService.currentLang;
        let diagramService = this.diagramService;
        this.diagramService.createTitle(this.filterModel).subscribe(title =>
            this.performanceHeatMapChart = new Chart({
                boost: {
                  useGPUTranslations: true
                },
                chart: {
                    type: 'heatmap',
                  zooming: {
                    type: 'xy'
                  },
                    height: this.widget ? null : '638.5px',
                    style: {
                        fontFamily: '\'Nimbus\''
                    },
                    events: this.diagramService.getWatermark(this.widget),
                    resetZoomButton: {
                        theme: {
                            style: 'opacity: 0.3',
                            states: {
                                hover: {
                                    style: 'opacity: 1'
                                }
                            }
                        }
                    }
                },
                title: {
                    text: this.widget ? '' : title,
                    style: {
                        fontSize: this.widget ? '12px' : '18px'
                    }
                },
                xAxis: [{
                    type: 'datetime',
                    min: this.getMinDate(),
                    max: this.getMaxDate(),
                    labels: {
                        align: 'right',
                        rotation: -45,
                        formatter: function () {
                            return DateFormatUtil.formatToLocalDate(new Date(this.value));
                        }
                    },
                    tickLength: 16
                }, { // slave axis
                    type: 'datetime',
                    linkedTo: 0,
                    opposite: true,
                    labels: {
                        formatter: function () {
                            return new Date(this.value).toLocaleString(currentLang, {weekday: 'short'});
                        }
                    },
                    tickInterval: 24 * 3600 * 1000
                }],
                yAxis: {
                    title: {
                        text: null
                    },
                    labels: {
                        // tickPositions: [1, 6, 12, 18, 24]
                    },

                    minPadding: 0,
                    maxPadding: 0,
                    startOnTick: false,
                    endOnTick: false,
                    reversed: true,
                    min: 0,
                    max: 24,
                    tickPositions: [1, 6, 12, 18, 24],
                    tickWidth: 1
                },
                lang: {
                    decimalPoint: ','
                },
                tooltip: {
                    useHTML: true,
                    formatter: function () {
                        return diagramService.getHeatMapTooltip(this);
                    }
                },
                credits: {
                    enabled: false
                },
                legend: {
                    align: 'right',
                    layout: 'vertical',
                    padding: 20,
                    verticalAlign: 'top',
                    symbolHeight: 493,
                    y: 40,
                    enabled: !this.widget,
                    navigation: {
                        // enabled: false
                    }
                },
                exporting: {
                    filename: title.replace(/\//ig, '_'),
                    buttons: {
                        contextButton: {
                            align: 'left',
                            x: -10,
                            y: -10
                        }
                    },
                    sourceWidth: 1180,
                    sourceHeight: 638,
                    scale: 1,
                    chartOptions: {
                        title: {
                            style: {
                                fontSize: '12px'
                            }
                        },
                        legend: {
                            enabled: true,
                            align: 'right',
                            layout: 'vertical',
                            padding: 20,
                            verticalAlign: 'top',
                            symbolHeight: 495,
                            y: 40,
                            itemStyle: {
                                fontSize: '8px'
                            }
                        },

                        xAxis: {
                            labels: {
                                style: {
                                    fontSize: '8px'
                                }
                            },
                            title: {
                                style: {
                                    fontSize: '10px'
                                }
                            }
                        },
                        yAxis: {
                            labels: {
                                style: {
                                    fontSize: '10px'
                                }
                            },
                            title: {
                                style: {
                                    fontSize: '10px'
                                }
                            }
                        }
                    },
                },
                colorAxis: {
                    stops: [
                        [0, '#c4463a'],
                        [0.5, '#fffbbc'],
                        [1, '#3060cf']
                    ],
                    tickPositions: [Math.round(this.getMaxValue()),
                        Math.round((this.getMinValue() + this.getMaxValue()) / 2),
                        Math.round(this.getMinValue())],
                    min: this.getMaxValue(),
                    max: this.getMinValue(),
                    minColor: '#c4463a',
                    maxColor: '#3060cf',
                    labels: {
                        formatter: function () {
                            return _this.decimalPipe.transform(this.value, '1.0-0') + ' kW';
                        }
                    }
                },

                series: [{
                   type: 'heatmap',
                   boostThreshold: 100,
                   borderWidth: 0,
                   data: this.chartData[0].data,
                   nullColor: '#EFEFEF',
                   colsize: 24 * 36e5, // one day
                   turboThreshold: Number.MAX_VALUE // #3404, remove after 4.0.5 release
                }]
            }));
    }

}
