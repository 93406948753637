import {Injectable} from '@angular/core';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';
import {Moment} from 'moment';
import {DateFormatUtil} from '../../core/util/date-format.util';

@Injectable()
export class NgBDatePickerConvertService {

    public convertToDate(ngbDate: NgbDateStruct): Date {
        return DateFormatUtil.transformNgbToDate(ngbDate);
    }

    public convertFromDate(dateString: Date | string): NgbDateStruct {
        return DateFormatUtil.transformDateToNgb(dateString);
    }

    public convertFromMoment(moment: Moment): NgbDateStruct {
        return DateFormatUtil.transformDateToNgb(moment);
    }
}
