<mat-card>

  <div class="card-header-row" *ngIf="!!title || !!titleAddonTemplate">
    <mat-card-title class="card-header-row__text-container">{{ title | translate }}</mat-card-title>
    <div class="text-right mb-3 card-header-row__button-container">
      <ng-container [ngTemplateOutlet]="titleAddonTemplate"></ng-container>
    </div>
  </div>

  <div class="row">

    <!--Export rész  -->
    <mat-accordion class="mb-2 col-xl-5 col-lg-6">

      <mat-expansion-panel #exportPanel>
        <mat-expansion-panel-header>
          <mat-panel-title *ngIf="exportPanel.expanded" class="cez-panel-title">
            {{'timeSeriesImport.header.exportPanel' | translate}}
          </mat-panel-title>
          <mat-panel-title *ngIf="!exportPanel.expanded && !form.controls.timeInterval.invalid"
                           class="cez-panel-description align-items-center">
            {{panelDescription}}
          </mat-panel-title>
          <mat-panel-description *ngIf="form.controls.timeInterval.invalid"
                                 class="cez-panel-description align-items-center">
            <mat-error *ngIf="form.controls.timeInterval.invalid">
              {{'validation.' + form.controls.timeInterval.errors.error | translate}}
            </mat-error>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <form [formGroup]="form" class="mt-2">
          <div class="row">
            <mat-form-field class="col-sm-6" *ngIf="!isHedgeConsumptionPlan">
              <mat-select placeholder="{{'timeSeriesImport.timeSeriesType' | translate}}"
                          [formControlName]="'timeSeriesType'">
                <mat-option *ngFor="let t of timeSeriesTypeOptions | keys" [value]="t.value">
                  {{'timeSeriesImport.timeSeriesTypes.groupped.' + t.value  | translate}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="col-sm-6" *ngIf="!isHedgeConsumptionPlan">
              <mat-select placeholder="{{'timeSeriesImport.excelType' | translate}}"
                          [formControlName]="'excelType'">
                <mat-option *ngFor="let e of excelTypeOptions | keys" [value]="e.value">
                  {{'timeSeriesImport.excelTypes.' + e.value | translate}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <cez-time-interval #ti
                             [min]="intervalMin"
                             [max]="intervalMax"
                             [formControlName]="'timeInterval'"
                             [isExpectedPlan]="isExpectedPlan"
                             [isHedgeConsumptionPlan]="isHedgeConsumptionPlan"
                             [resolutions]="resolutionOptions"
                             [clearInputValues]="clearInputValues"
                             [allowDifferentMeasureUnits]="allowDifferentMeasureUnits"
                             [deliveryPeriod]="selectedDeliveryPeriod"
                             (expectedPlanChange)="expectedPlanChanged($event)"
                             (sumYearlyValueChange)="sumYearlyValueChanged($event)"
                             (sumMonthlyValueChange)="sumMonthlyValueChanged($event)"></cez-time-interval>

        </form>
      </mat-expansion-panel>

      <div class="row no-gutters mt-1 mb-2">
        <div class="col-md-4 col-6">
          <button mat-raised-button color="primary"
                  matTooltip="{{'timeSeriesImport.showButton.toolTip' | translate}}"
                  [disabled]="form.invalid" type="button"
                  class="btn font-size-14 w-100"
                  (click)="showSeries(true); exportPanel.close()">
            <i class="material-icons">slideshow</i>
            {{'timeSeriesImport.showButton.name' | translate}}
          </button>
        </div>
        <div class="col-md-4 col-6 pl-1 pr-md-1">
          <button mat-raised-button color="primary"
                  matTooltip="{{'timeSeriesImport.exportButton.toolTip' | translate}}"
                  [disabled]="form.invalid || isExpectedPlan" type="button"
                  class="btn font-size-14 w-100"
                  (click)="export(true); exportPanel.close()">
            <i class="material-icons">receipt</i>
            {{'timeSeriesImport.exportButton.name' | translate}}
          </button>
        </div>
        <div class="col-md-4 col-12 pt-1 pt-md-0">
          <button mat-raised-button color="primary"
                  matTooltip="{{'timeSeriesImport.templateButton.toolTip' | translate}}"
                  [disabled]="form.invalid || isExpectedPlan" type="button"
                  class="btn font-size-14 w-100"
                  (click)="export(false); exportPanel.close()">
            <i class="material-icons">receipt</i>
            {{'timeSeriesImport.templateButton.name' | translate}}
          </button>
        </div>
      </div>


    </mat-accordion>

    <!--Import rész  -->
    <mat-accordion *ngIf="isEditable && 'SCHEDULE_WRITE' | hasPermission" class="mb-2 col-xl-4 col-lg-3">
      <mat-expansion-panel #importPanel
                           [disabled]="!fileUploadQueue.files || fileUploadQueue.files.length === 0"
                           [expanded]="fileUploadQueue.files && fileUploadQueue.files.length > 0">

        <mat-expansion-panel-header>
          <mat-panel-title>
            {{newSeriesData ? ('timeSeriesImport.header.newFile' | translate) : ('timeSeriesImport.header.importPanel' | translate)}}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div *ngIf="warning" class="alert alert-warning alert-dismissible" [innerHTML]="warning"></div>

        <cez-file-upload-queue #fileUploadQueue [isMultipleFiles]="isMultipleFiles">
          <cez-file-upload [file]="file" [id]="i"
                           [httpUrl]="'api/timeseries/import/preview'"
                           (onUpload)="onUpload($event)"
                           [httpRequestParams]="getAgreementParams()"
                           [autoUpload]="true"
                           *ngFor="let file of fileUploadQueue.files; let i = index"></cez-file-upload>
        </cez-file-upload-queue>
      </mat-expansion-panel>

      <button mat-raised-button
              [matTooltip]="getBrowseButtonTooltip()"
              color="primary" (click)="fileInput.click()" type="button"
              class="btn-block mt-1">
        <i class="material-icons">find_in_page</i>
        {{'timeSeriesImport.browseButton.name' | translate}}
      </button>
      <input #fileInput [hidden]="true" multiple type="file" [fileUploadInputFor]="fileUploadQueue"
             accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
    </mat-accordion>
    <!--Activate rész-->
    <div *ngIf="isEditable && 'SCHEDULE_WRITE' | hasPermission" class="mb-2 col-xl-3 col-lg-3">
      <jhi-time-series-activate #activatePanel
                                [agreementId]="agreementId"
                                [hedgeImportParams]="agreementParams"
                                [deleteFile]="deleteFile"
                                [activateButtonTooltip]="getActivateButtonTooltip()"
                                [activatePermissionCheck]="activatePermissionCheck"
                                [activatePermission]="activatePermission"
                                (activation)="activate()"
                                (deletion)="deleteSeries()"></jhi-time-series-activate>
    </div>
  </div>

  <form [formGroup]="formExpectedPlanMonths" *ngIf="isExpectedPlan && selectedPlan === 'expectedPlan'">
    <div class="row justify-content-start ml-5 pl-4 pr-2">
      <div *ngFor="let p of monthInTolerancePeriod" class="col-xl-1 col-lg-1 col-sm-1 p-1 m-0">
        <mat-form-field class="w-100">
          <input type="number" matInput
                 formControlName="{{'expectedPlanMonths' + p}}"
                 placeholder="{{'toleranceThreshold.expectedPlanMonths.' + p | translate}} (MWh)">
        </mat-form-field>
      </div>
    </div>
  </form>

  <!--Chart rész -->
  <div class="row">
    <div class="col-sm-12">
      <jhi-time-series-chart #chart
                             [exportChartTitle]="exportChartTitle"
                             [sourceWidth]="sourceWidth"
                             [sourceHeight]="sourceHeight"
                             [fontSize]="fontSize"></jhi-time-series-chart>
    </div>
  </div>

  <!--Tervadatok gombok rész -->
  <div class="btn-toolbar justify-content-center d-flex" *ngIf="isEditable && isExpectedPlan && 'AGREEMENT_WRITE' | hasPermission">
    <div class="btn-group btn-group-toggle flex" style="margin-top: 15px;">
      <button mat-raised-button color="primary"
              matTooltip="{{'timeSeriesImport.cancelButton.toolTip' | translate}}"
              type="button"
              writeProtected="CONSUMPTION_PLAN_WRITE"
              (clickIfHasPermission)="deleteSeries()"
              [disabled]="!isPlanDataSaveEnabled"
              class="btn btn-block font-size-14 mt-1 mr-1 w-100">
        <i class="material-icons">cancel</i>{{'agreement.hedgeConsumptionPlan.discardPlanData' | translate}}
      </button>

      <button mat-raised-button color="primary"
              matTooltip="{{'timeSeriesImport.activateButton.toolTip' | translate}}"
              type="button"
              writeProtected="CONSUMPTION_PLAN_WRITE"
              (clickIfHasPermission)="savePlanData()"
              [disabled]="!isPlanDataSaveEnabled"
              class="btn btn-block font-size-14 mt-1 w-100">
        <i class="material-icons">done</i>{{'agreement.hedgeConsumptionPlan.savePlanData' | translate}}
      </button>
    </div>
  </div>

  <input *ngIf="isExpectedPlan" #planDataButtonStateCheck class="plan-data-button-state-check"/>

</mat-card>





