import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MAT_DATE_FORMATS, MatDateFormats} from '@angular/material/core';
import {Moment} from 'moment-timezone';
import {DateInterval} from '../dto/date-interval.model';
import {DateFormatUtil} from '../../core/util/date-format.util';

@Component({
    selector: 'monthly-date-interval',
    templateUrl: 'monthly-date-interval.component.html',
    styleUrls: ['monthly-date-interval.component.scss'],
    providers: [
        {provide: MAT_DATE_FORMATS, useFactory: (): MatDateFormats => DateFormatUtil.getIntervalMaterialDateFormats()}
    ]
})

export class MonthlyDateIntervalComponent implements OnInit {

    closedDateTo: Moment;
    @Input() min: Moment;

    @Input() dateFrom: Moment;

    @Input() set dateTo(value: Moment) {
        this.closedDateTo = value.clone().subtract(1, 'month');
    }

    @Output() selectInterval: EventEmitter<DateInterval> = new EventEmitter();

    ngOnInit() {

    }

    chosenFromMonthHandler(startDate: Moment) {
        this.dateFrom = startDate;

        if ((this.dateFrom).isAfter(this.closedDateTo)) {
            this.closedDateTo = this.dateFrom.clone();
        }

        this.sendEvent();
    }

    chosenEndMonthHandler(endDate: Moment) {
        this.closedDateTo = endDate;

        if ((this.dateFrom).isAfter(this.closedDateTo)) {
            this.dateFrom = this.closedDateTo.clone();
        }

        this.sendEvent();
    }

    private sendEvent() {
        this.selectInterval.emit({from: this.dateFrom.clone(), to: this.closedDateTo.clone().add(1, 'month')});
    }
}
