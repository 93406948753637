<mat-card>
  <mat-card-title>
    <span jhiTranslate="userManagement.home.title">Users</span>
    <button class="btn btn-primary float-right" [routerLink]="['/', { outlets: { popup: ['user-management-new'] } }]">
      <span jhiTranslate="userManagement.home.createLabel">Create a new User</span>
    </button>
  </mat-card-title>
  <mat-card-content>
    <p-table #usersTable
             [value]="users"
             [lazy]="true"
             [paginator]="true"
             [totalRecords]="totalItems"
             [rows]="itemsPerPage"
             (onLazyLoad)="lazyLoadUsers($event)"
             class="table table-striped">
      <ng-template pTemplate="header">
        <tr>
          <th>
            <span jhiTranslate="global.field.id">ID</span>
          </th>
          <th>
            <span jhiTranslate="userManagement.login">Login</span>
          </th>
          <th>
            <span jhiTranslate="userManagement.lastName">Last Name</span>
          </th>
          <th>
            <span jhiTranslate="userManagement.firstName">First Name</span>
          </th>
          <th>
            <span jhiTranslate="userManagement.email">Email</span>
          </th>
          <th></th>
          <th>
            <span jhiTranslate="userManagement.langKey">Lang Key</span>
          </th>
          <th>
            <span jhiTranslate="userManagement.createdDate">User created</span>
          </th>
          <th>
            <span jhiTranslate="userManagement.lastLogin">Last login</span>
          </th>
          <th>
            <span jhiTranslate="userManagement.profiles">Profiles</span>
          </th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-user>
        <tr>
          <td>
            <a [routerLink]="['../user-management', user.login]">{{user.id}}</a>
          </td>
          <td>{{user.login}}</td>
          <td>{{user.lastName}}</td>
          <td>{{user.firstName}}</td>
          <td>{{user.email}}</td>
          <td>
            <span class="badge badge-danger hand" (click)="setActive(user, true)" *ngIf="!user.activated" jhiTranslate="userManagement.deactivated">Deactivated</span>
            <span class="badge badge-success hand" (click)="setActive(user, false)" *ngIf="user.activated" jhiTranslate="userManagement.activated">Activated</span>
          </td>
          <td>{{user.langKey}}</td>
          <td>{{user.createdDate | cezDateTime}}</td>
          <td>{{user.lastLogin | cezDateTime}}</td>
          <td>
            <div *ngFor="let groupNames of user.groupNames">
              <span class="tag tag-info">{{ groupNames }}</span>
            </div>
          </td>
          <td class="text-right">
            <div class="btn-group flex-btn-group-container">
              <button type="submit" [routerLink]="['/', { outlets: { popup: 'user-management/'+ user.login + '/edit'} }]" replaceUrl="true"
                      class="btn btn-info btn-sm">
                <span class="fa fa-pencil"></span>
              </button>
              <button type="submit" [routerLink]="['/', { outlets: { popup: 'user-management/'+ user.login + '/delete'} }]" replaceUrl="true"
                      class="btn btn-danger btn-sm" [disabled]="currentAccount.login==user.login">
                <span class="fa fa-remove"></span>
              </button>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </mat-card-content>
</mat-card>
