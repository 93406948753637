<h1 mat-dialog-title>{{ 'dataUpdateTracking.title' | translate }}</h1>
<div mat-dialog-content>
    <div class="row p-2 border-bottom border-top">
        <div class="col-4 m-0 text-left font-weight-bold"> {{ 'dataUpdateTracking.podValue' | translate }}</div>
        <div class="col-4"></div>
        <div *ngIf="data.podValue"
             class="col-2 pl-2 m-0 text-left table-content-text">{{ getDateValue(data.podValue) }}</div>
        <div class="col-2"></div>
    </div>
    <div class="row p-2 border-bottom">
        <div class="col-4 m-0 text-left font-weight-bold"> {{ 'dataUpdateTracking.hedgeConsumptionPlan' | translate }}</div>
        <div class="col-8 pt-3">
            <table class="plan-table">
                <tbody>
                <tr class="row border-bottom-0" *ngFor="let hedgeConsumptionPlan of data.hedgeConsumptionPlans">
                    <td class="col-6 table-content-text">{{ hedgeConsumptionPlan.deliveryPeriodDto.startTime | cezDate }}
                        - {{ hedgeConsumptionPlan.deliveryPeriodDto.endTime | cezDate }}
                    </td>
                    <td [ngClass]="{'text-danger': isLastUpdateStale(hedgeConsumptionPlan)}"
                        class="col-3 pl-2 text-left table-content-text ">
                        {{ getFormattedLastUpdate(hedgeConsumptionPlan) }}
                    </td>
                    <td class="col-3 table-content-text">
                        {{getFormattedLastUpdateBy(hedgeConsumptionPlan)}}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row p-2 border-bottom">
        <div class="col-4 m-0 text-left font-weight-bold"> {{ 'dataUpdateTracking.hourlyShapedPrices' | translate }}
        </div>
        <div class="col-4"></div>
        <div *ngIf="data.hourlyShapedPrices"
             class="col-2 pl-2 m-0 text-left table-content-text">{{ getDateValue(data.hourlyShapedPrices) }}</div>
        <div class="col-2"></div>
    </div>
    <div class="row p-2 border-bottom">
        <div class="col-4 m-0 text-left font-weight-bold"> {{ 'dataUpdateTracking.spotPrices' | translate }}</div>
        <div class="col-4"></div>
        <div *ngIf="data.spotPrices"
             class="col-2 pl-2 m-0 text-left table-content-text">{{ getDateValue(data.spotPrices) }}</div>
        <div class="col-2"></div>
    </div>
</div>
<div mat-dialog-actions align="end">
    <button mat-raised-button
            color="primary"
            [mat-dialog-close] cdkFocusInitial>
        {{ 'common.action.ok' | translate }}
    </button>
</div>
