<mat-card>
    <mat-card-content class="table-container">
        <table class="table table-striped">
            <thead>
            <tr>
                <th class="text-center">
                    <span jhiTranslate="offerClaim.tableField.dealId"></span>
                </th>
                <th class="text-center">
                    <span jhiTranslate="offerClaim.tableField.partner"></span>
                </th>
                <th class="text-center">
                    <span jhiTranslate="offerClaimHistory.tableField.purchaseType"></span>
                </th>
                <th class="text-center">
                    <span jhiTranslate="offerClaim.tableField.purchaseDate"></span>
                </th>
                <th class="text-center">
                    <span jhiTranslate="offerClaim.tableField.deliveryPeriod"></span>
                </th>
                <th class="text-center">
                    <span jhiTranslate="offerClaim.tableField.averagingStatus"></span>
                </th>
                <th class="text-center">
                    <span jhiTranslate="offerClaim.tableField.productName"></span>
                </th>
                <th class="text-center">
                    <span jhiTranslate="offerClaim.tableField.averagingStart"></span>
                </th>
                <th class="text-center">
                    <span jhiTranslate="offerClaim.tableField.averagingEnd"></span>
                </th>
                <th class="text-center quantity-column-width">
                    <span jhiTranslate="offerClaimHistory.tableField.capacity"></span>
                </th>
                <th class="text-center quantity-column-width">
                    <span jhiTranslate="offerClaimHistory.tableField.quantity"></span>
                </th>
                <th class="text-center quantity-column-width">
                    <span jhiTranslate="offerClaim.tableField.averageUnitPrice"></span>
                </th>
                <th class="text-center quantity-column-width">
                    <span jhiTranslate="offerClaim.tableField.netSum"></span>
                </th>
                <th class="text-center">
                    <span jhiTranslate="offerClaim.tableField.approvedUser"></span>
                </th>
            </tr>
            <tr>
                <th class="filter-th" id="deal-id-filter-form">
                    <mat-form-field class="pt-2 pb-2 head-select text-left">
                        <mat-label>{{ 'global.choose' | translate }}</mat-label>
                        <input matInput
                               type="text"
                               aria-label="Number"
                               [formControl]="dealIdControl"
                               [matAutocomplete]="auto">
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="filterAveragingTransaction()"
                                          panelWidth="auto">
                            <mat-option>{{ 'multiSelectText.checkAll' | translate }}</mat-option>
                            <mat-option *ngFor="let dealId of filteredDealIds | async" [value]="dealId">{{ dealId }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </th>
                <th>
                    <mat-select [(ngModel)]="selectedPartner"
                                (ngModelChange)="filterAveragingTransaction()"
                                class="head-select text-center" name="partners"
                                placeholder="{{'global.choose' | translate}}">
                        <mat-option class="text-left" [value]="null">{{ 'global.choose' | translate }}</mat-option>
                        <mat-option class="text-left" *ngFor="let partner of partners"
                                    [value]="partner">{{ partner.name }}
                        </mat-option>
                    </mat-select>
                </th>
                <th></th>
                <th></th>
                <th>
                    <mat-select [(ngModel)]="selectedDeliveryPeriod"
                                (ngModelChange)="filterAveragingTransaction()"
                                class="head-select text-center" name="deliveryPeriod"
                                placeholder="{{'global.choose' | translate}}">
                        <mat-option class="text-left" [value]="null">{{ 'global.choose' | translate }}</mat-option>
                        <mat-option class="text-left" *ngFor="let dp of deliveryPeriods" [value]="dp">
                            {{ dp.validFrom | cezDate }} - {{ dp.validTo | cezDate }}
                        </mat-option>
                    </mat-select>
                </th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let aT of averagingTransactionsFiltered">
                <td class="text-left">{{ aT.dealId }}</td>
                <td class="text-left">{{ aT.partnerAccountName }}</td>
                <td class="text-center">{{ translatePurchaseStatus(aT) }}</td>
                <td class="text-center">{{ aT.purchaseDate | cezDate }}</td>
                <td class="text-center">{{ aT.deliveryPeriodStartTime | cezDate }}
                - {{ aT.deliveryPeriodEndTime | cezDate }}
                </td>
                <td class="text-center">{{ 'offerClaim.averagingStatus.' + aT.averagingStatus | translate }}</td>
                <td class="text-left">{{ aT.productName }}</td>
                <td class="text-center">{{ aT.averagingStartDate | cezDate }}</td>
                <td class="text-center">{{ aT.averagingEndDate | cezDate }}</td>
                <td class="text-right">{{ aT.capacity | number:'1.2-2' }}</td>
                <td class="text-right">{{ aT.quantity | number:'1.2-2' }}</td>
                <td class="text-right">{{ aT.averageUnitPrice | number:'1.2-2' }}</td>
                <td class="text-right">{{ aT.netSum | number:'1.0-0' }}</td>
                <td class="text-center">{{ aT.approvedUserName }}</td>
            </tbody>
        </table>
    </mat-card-content>
</mat-card>
