import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NgxMaterialTimepickerTheme} from 'ngx-material-timepicker';
import {OfferClaimDto} from '../../shared/offer-claim/model/OfferClaimDto';
import {ScheduledQuotationDto} from './scheduled-quotation.types';

import {ScheduledQuotationService} from './scheduled-quotation.service';
import {ReactiveFormUtil} from '../../shared/form/reactive-form.util';
import {BusinessCalendarService} from '../../shared/services/business-calendar.service';
import moment from "moment";
import {DateFormatUtil} from '../../core/util/date-format.util';

@Component({
    selector: 'jhi-scheduled-quotation-dialog',
    templateUrl: './scheduled-quotation-dialog.component.html',
    styleUrls: ['./scheduled-quotation-dialog.component.scss']
})
export class ScheduledQuotationDialogComponent implements OnInit {

    isSaving: boolean = false;

    scheduledQuotationForm: FormGroup;
    isScheduledControl: FormControl;
    scheduledQuotationDayControl: FormControl;
    visibleScheduledQuotationTimeControl: FormControl;
    hiddenScheduledQuotationTimeControl: FormControl;

    warningTextCheckbox: Boolean = false;
    onlyAveraging: boolean = false;

    cezTimePickerTheme: NgxMaterialTimepickerTheme = {
        container: {
            buttonColor: '#f24f00'
        },
        dial: {
            dialBackgroundColor: '#f24f00'
        },
        clockFace: {
            clockHandColor: '#f24f00'
        }
    };

    /**
     * set this to true if the checkbox has to be checked at init
     */
    private isTimedQuotationControlInitialValue: boolean = false;
    private _claims: OfferClaimDto[] = [];

    @Input()
    public get claims(): OfferClaimDto[] {
        return this._claims;
    }

    public set claims(claims: OfferClaimDto[]) {
        this._claims = claims;

        this.onlyAveraging = claims
            .filter((claim: OfferClaimDto) => claim.averagingTransaction)
            .length === claims.length;

        this.scheduledQuotationService.setClaims(this._claims);
    }

    constructor(public activeModal: NgbActiveModal,
                public scheduledQuotationService: ScheduledQuotationService,
                public businessCalendarService: BusinessCalendarService,
                private formBuilder: FormBuilder) {
    }

    public ngOnInit(): void {
        this.createForm();
        this.createFormValueChanges();
        this.getMarketDataParams();
    }

    public onSubmit(): void {
        if (this.scheduledQuotationForm.invalid) {
            return;
        }

        this.activeModal.close(this.getResult());
    }

    public hasAveragingClaim(): boolean {
        return !!this._claims && this._claims
            .filter((cI: OfferClaimDto) => cI.averagingTransaction)
            .length > 0;
    }

    public isOtcMarketClosed(): boolean {
        return this.scheduledQuotationService.isOtcMarketClosed();
    }

    private createForm(): void {
        this.isScheduledControl = new FormControl({value: this.isTimedQuotationControlInitialValue, disabled: true});
        this.scheduledQuotationDayControl = new FormControl({
            value: null,
            disabled: !this.isTimedQuotationControlInitialValue
        });
        this.visibleScheduledQuotationTimeControl = new FormControl({
            value: null,
            disabled: !this.isTimedQuotationControlInitialValue
        });
        this.hiddenScheduledQuotationTimeControl = new FormControl({
            value: null,
            disabled: !this.isTimedQuotationControlInitialValue
        });

        this.scheduledQuotationForm = this.formBuilder.group({
            isScheduled: this.isScheduledControl,
            scheduledQuotationDay: this.scheduledQuotationDayControl,
            scheduledQuotationTime: this.visibleScheduledQuotationTimeControl,
        });
    }

    private getResult(): ScheduledQuotationDto {
        const result: ScheduledQuotationDto = {
            claims: this.claims,
            scheduledQuotation: null
        };

        if (this.isScheduledControl.value) {
            let day: Date = new Date(this.scheduledQuotationDayControl.value);
            let momentDay: moment.Moment = moment(day);

            result.scheduledQuotation = this.scheduledQuotationService
                .setTimeByString(this.visibleScheduledQuotationTimeControl.value, momentDay)
                .toDate();
        }

        return result;
    }

    private createFormValueChanges(): void {
        this.isScheduledControl.valueChanges.subscribe((enabled: boolean) => {
            this.enableDisableFormControls(enabled);
            this.setFormControlValue(enabled);
            this.setFormControlValidators(enabled);
            this.setDisableStyleOnInputs(enabled);
        });

        this.scheduledQuotationDayControl.valueChanges.subscribe((date: moment.Moment) => {
            this.scheduledQuotationService.selectedDay(date);
        });

        this.visibleScheduledQuotationTimeControl.valueChanges.subscribe((time: string) => {
            this.updateHiddenTimeControlValue(time);
        });

        this.hiddenScheduledQuotationTimeControl.valueChanges.subscribe((time: string) => {
            this.visibleScheduledQuotationTimeControl.setValue(time, {emitEvent: false});
        });
    }

    private enableDisableFormControls(enabled: boolean): void {
        if (enabled) {
            this.scheduledQuotationDayControl.enable();
            this.visibleScheduledQuotationTimeControl.enable();
        } else {
            this.scheduledQuotationDayControl.disable();
            this.visibleScheduledQuotationTimeControl.disable();
        }
    }

    private setFormControlValue(enabled: boolean): void {
        if (enabled) {
            this.scheduledQuotationService.setMinMax();
            this.scheduledQuotationDayControl.setValue(this.scheduledQuotationService.dayMin, {emitEvent: false});
            this.visibleScheduledQuotationTimeControl.setValue(this.scheduledQuotationService.timeMin, {emitEvent: false});
            this.hiddenScheduledQuotationTimeControl.setValue(this.scheduledQuotationService.timeMin, {emitEvent: false});
        } else {
            this.scheduledQuotationDayControl.setValue(null, {emitEvent: false});
            this.visibleScheduledQuotationTimeControl.setValue(null, {emitEvent: false});
        }
    }

    private setFormControlValidators(enabled: boolean): void {
        if (enabled) {
            this.scheduledQuotationDayControl.setValidators([
                Validators.required,
                ReactiveFormUtil.minDateValidator(this.scheduledQuotationService.dayMin),
                ReactiveFormUtil.maxDateValidator(this.scheduledQuotationService.dayMax),
                ReactiveFormUtil.externalConditionValidator(() => this.isDateSelectable())
            ]);
            this.visibleScheduledQuotationTimeControl.setValidators([
                Validators.required,
                Validators.minLength(5),
                ReactiveFormUtil.externalConditionValidator(() => this.isInTheValidInterval()),
                ReactiveFormUtil.externalConditionValidator(() => this.isHourDivisibleByX(15))
            ]);
        } else {
            this.scheduledQuotationDayControl.clearValidators();
            this.visibleScheduledQuotationTimeControl.clearValidators();
        }
    }

    private getMarketDataParams(): void {
        this.scheduledQuotationService.loadParameters().subscribe(() => {
            this.isScheduledControl.enable();
        });
    }

    private updateHiddenTimeControlValue(time: string): void {
        if (!time) {
            return;
        }

        let timeMoment: moment.Moment = this.scheduledQuotationService.setTimeByString(time);
        timeMoment = this.scheduledQuotationService.getDateWithClosestRoundedMinute(timeMoment);

        let timeString: string = DateFormatUtil.formatToLocalTime(timeMoment);
        timeString = timeString > this.scheduledQuotationService.timeMax
            ? this.scheduledQuotationService.timeMax
            : timeString;

        timeString = timeString < this.scheduledQuotationService.timeMin
            ? this.scheduledQuotationService.timeMin
            : timeString;

        this.hiddenScheduledQuotationTimeControl.setValue(timeString, {emitEvent: false});
    }

    protected isDateSelectable(): boolean {
        return this.businessCalendarService.isDaySelectable(moment(this.scheduledQuotationDayControl.value));
    }

    protected datepickerDayFilter = (date: any) => {
        return this.businessCalendarService.isDaySelectable(moment(date));
    }

    private isHourDivisibleByX(division: number): boolean {
        const time: string = String(this.visibleScheduledQuotationTimeControl.value).split(":")[1];

        return parseFloat(time) % division === 0;
    }

    private isInTheValidInterval(): boolean {
        let scheduledDayIsToday: boolean = this.scheduledDayIsToday();

        let timeMin: moment.Moment = scheduledDayIsToday
            ? moment(this.scheduledQuotationService.timeMin, DateFormatUtil.getTimeFormat())
            : this.scheduledQuotationService.getTradingWindowStart();

        let timeMax: moment.Moment = scheduledDayIsToday
            ? moment(this.scheduledQuotationService.timeMax, DateFormatUtil.getTimeFormat())
            : this.scheduledQuotationService.getTradingWindowEnd();

        let scheduledTime: moment.Moment = moment(this.visibleScheduledQuotationTimeControl.value, DateFormatUtil.getTimeFormat());

        return this.isInsideTheInterval(timeMin, timeMax, scheduledTime);
    }

    private scheduledDayIsToday(): boolean {
        let scheduledDay: moment.Moment = moment(this.scheduledQuotationDayControl.value);
        let today: moment.Moment = moment();

        return scheduledDay.isSame(today, 'day');
    }

    private isInsideTheInterval(intervalMinTime: moment.Moment, intervalMaxTime: moment.Moment, actualTime: moment.Moment): boolean {
        if (actualTime.hour() < intervalMinTime.hour() || (actualTime.hour() == intervalMinTime.hour() && actualTime.minute() < intervalMinTime.minute())) {
            return false;
        } else if (actualTime.hour() > intervalMaxTime.hour() || (actualTime.hour() == intervalMaxTime.hour() && actualTime.minute() > intervalMaxTime.minute())) {
            return false;
        }

        return true;
    }

    private setDisableStyleOnInputs(enabled: boolean) {
        let schedulingDeadlineDayInput: HTMLElement = document.getElementById('schedulingDeadlineDay');
        let schedulingDeadlineTimeInput: HTMLElement = document.getElementById('visibleScheduledQuotationTime');

        if (enabled) {
            schedulingDeadlineDayInput.classList.add('readonly-seams-like-editable');
            schedulingDeadlineTimeInput.classList.add('readonly-seams-like-editable');
        } else {
            schedulingDeadlineDayInput?.classList?.remove('readonly-seams-like-editable');
            schedulingDeadlineTimeInput?.classList?.remove('readonly-seams-like-editable');
        }
    }
}
