import {AfterViewInit, Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {StateStorageService} from '../shared/auth/state-storage.service';
import {TermsOfServicesService} from '../shared/services/terms-of-services.service';
import {Account} from '../shared/user/account.model';

import {LoginService} from './login.service';

@Component({
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit, AfterViewInit {
  showError: boolean;
  twoFactorAuthShow: boolean;
  password: string;
  twoFactorCode: string;
  rememberMe: boolean;
  username: string;
  credentials: any;
  loading: boolean;
  errorText: string;
  currLang: string;


  constructor(
    private loginService: LoginService,
    private stateStorageService: StateStorageService,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private router: Router,
    private termsOfServicesService: TermsOfServicesService,
    private translateService: TranslateService
  ) {
    this.credentials = {};
  }

  ngOnInit() {
    this.loginService.logout();

    if (this.loginService.logoutCause) {
      this.translateService.get(`login.messages.error.${this.loginService.logoutCause}`).subscribe(text => {
        this.errorText = text;
        this.showError = true;
      });
      this.loginService.logoutCause = null;
    }
    this.currLang = this.translateService.currentLang;
  }

  ngAfterViewInit() {
    //this.renderer.invokeElementMethod(this.elementRef.nativeElement.querySelector('#username'), 'focus', []);
    this.renderer.selectRootElement(this.elementRef.nativeElement.querySelector('#username')).focus();

  }

  cancel() {
    this.credentials = {
      username: null,
      password: null,
      rememberMe: true
    };
    this.showError = false;
    this.errorText = '';
  }

  login() {
    this.loading = true;
    if (!this.twoFactorCode)
      this.twoFactorAuthShow = false;
    this.showError = false;
    this.errorText = '';

    this.loginService.login({
      username: this.username,
      password: this.password,
      rememberMe: this.rememberMe,
      twoFactorCode: this.twoFactorCode
    }).then((account: Account) => {
      if (this.router.url === '/register' || (/activate/.test(this.router.url)) ||
        this.router.url === '/finishReset' || this.router.url === '/requestReset' || this.router.url === '/login') {
        if (account.twoFactorAuthRequired && !account.twoFactorKeyActivated)
          this.router.navigate(['/twofactorauth/activate']);
        else
          this.termsOfServicesService.termsOfServiceNeedsToBeAccepted().subscribe(termsOfServiceNeedsToBeAccepted => {
            if (termsOfServiceNeedsToBeAccepted) {
              this.router.navigate(['termsofservice']);
              this.stateStorageService.storeTermsOfServiceNeedsToBeAcceptedState(true);
            } else {
              this.stateStorageService.storeTermsOfServiceNeedsToBeAcceptedState(false);
              this.router.navigate(['company/selector']);
            }
          });
      }
      this.loading = false;
    }).catch((err) => {
      if (err.status === 422 && err.error.violations && err.error.violations.find(value => value.errorCode === 'AUTH_SECOND_FACTOR_REQUIRED')) {
        this.twoFactorAuthShow = true;
      } else {
        this.showError = true;
        if (err.status === 504 || err.status === 404)
          this.errorText = this.translateService.instant('login.messages.error.serverDown');

        else if (err.status === 401 || (err.status === 500 && err.error.message.indexOf('AUTH_ACCESS_DENIED') > -1))
          this.errorText = this.translateService.instant('login.messages.error.authentication');

        else if (err.status === 500 &&  !!err.error.exception && err.error.exception.indexOf('org.springframework.security.access.AccessDeniedException') > -1)
          this.errorText = this.translateService.instant('login.messages.error.twoFactorInvalidError');

        else if (err.status === 500 && err.error.message.indexOf('AUTH_SECOND_FACTOR_KEY_ACTIVATION_TIME_EXPIRED') > -1)
          this.errorText = this.translateService.instant('login.messages.error.twoFactorExpiredError');

        else if (err.status === 500 && err.error.message.indexOf('AUTH_SECOND_FACTOR_KEY_NOT_SET') > -1)
          this.errorText = this.translateService.instant('login.messages.error.twoFactorNotSetError');

        else if (err.status === 500 && err.error.message.indexOf('AUTH_SECOND_FACTOR_KEY_ALREADY_ACTIVATED,') > -1)
          this.errorText = this.translateService.instant('login.messages.error.twoFactorAlreadyActivatedError');
        else
          this.errorText = this.translateService.instant('login.messages.error.common');
      }
      this.twoFactorCode = '';
      this.loading = false;
    });
  }

  register() {
    this.router.navigate(['/register']);
  }

  requestResetPassword() {
    this.router.navigate(['/reset', 'request']);
  }

  changeLanguage(lang: string) {
    if (lang !== this.currLang) {
      this.stateStorageService.storeLanguage(lang);
      this.currLang = lang;
      location.reload();
    }
  }

}
