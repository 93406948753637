import {Component, OnInit, ViewChild} from '@angular/core';
import {DeliveryPeriod} from '../../shared/dto/delivery.periods';
import {CoverageTransactionsAdminService} from '../coverage-transactions.admin.service';
import {TranslateService} from '@ngx-translate/core';
import {DialogService} from '../../shared/dialog/dialog.service';
import {OfferClaimHistoryDto} from '../../shared/offer-claim/model/OfferClaimHistoryDto';
import * as _ from 'lodash';
import {HistorySaleStatus} from '../../shared/offer-claim/model/history-sale-status.enum';
import {Table} from 'primeng/table';
import {FilterService, SortEvent, SortMeta} from 'primeng/api';
import {PurchaseStatus} from '../../shared/offer-claim/model/purchase-status.enum';
import {DateIntervalFilter, NumberFilter} from '../filter.type';
import {Observable} from "rxjs";
import {FormControl} from "@angular/forms";
import {map, startWith} from "rxjs/operators";
import {BootstrapAlertService} from "../../shared/services/bootstrap-alert.service";
import moment from 'moment';

@Component({
    selector: 'jhi-offer-claim-history',
    templateUrl: './offer-claim-history.component.html',
    styleUrls: ['./offer-claim-history.component.scss']
})
export class OfferClaimHistoryComponent implements OnInit {

    public readonly CUSTOM_PRODUCT_NAME: string = 'Custom';
    public readonly TABLE_SORT_ASC: number = 1;
    private readonly DATE_FILTER_FIELDS: string[] = ['purchaseDate', 'startDate', 'endDate'];
    private readonly NUMBER_FILTER_FIELDS: string[] = ['price', 'quantityMw', 'quantity', 'netSum'];

    public readonly TABLE_SORT_DESC: number = -1;

    public offerClaimHistoryDto: OfferClaimHistoryDto[] = [];
    public deliveryPeriods: DeliveryPeriod[] = [];
    public dealIds: string[] = [];
    public partners: string[] = [];
    public products: string[] = [];
    public productTypes: string[] = [];
    public purchaseStatuses: PurchaseStatus[] = [];
    public statusList: HistorySaleStatus[];
    public approvers: string[] = [];
    public bidders: string[] = [];
    public deliveryPeriodFilterControl: FormControl = new FormControl();

    @ViewChild('historyTable', {static: true}) historyTable: Table;

    public dateFilterMap: Map<string, DateIntervalFilter> = new Map<string, DateIntervalFilter>();
    public numberFilterMap: Map<string, NumberFilter> = new Map<string, NumberFilter>();

    filteredDealIds: Observable<string[]> = new Observable<string[]>();
    dealIdControl = new FormControl('');


    constructor(private translate: TranslateService,
                private dialogService: DialogService,
                private bootstrapAlertService: BootstrapAlertService,
                private coverageTransactionsAdminService: CoverageTransactionsAdminService,
                public filterService: FilterService) {
    }

    ngOnInit(): void {
        this.DATE_FILTER_FIELDS.forEach(field =>
            this.dateFilterMap.set(field, {start: undefined, end: undefined}));
        this.NUMBER_FILTER_FIELDS.forEach(field =>
            this.numberFilterMap.set(field, {min: undefined, max: undefined}));

        this.dateFilterMap.forEach((filter: DateIntervalFilter, field: string) => {
            this.filterService.register('dateBetween-' + field, (value): boolean => this.dateBetweenFilter(filter, value));
        });

        this.numberFilterMap.forEach((filter: NumberFilter, field: string) => {
            this.filterService.register('numberBetween-' + field, (value): boolean => this.numberBetweenFilter(filter, value));
        });
        this.filterService.register('deliveryPeriod-contains', (value) => this.deliveryPeriodFilter(this.deliveryPeriodFilterControl.value, value));

        this.getOfferClaims();
    }

    private _filterDealIds(value: string): string[] {
        const filterValue = value.toLowerCase();

        return this.dealIds.filter(option => option.toLowerCase().includes(filterValue));
    }

    public onKeyDown(event: any) {
        // not allow negative prices
        if (event.key === '-' || (this.translate.currentLang === 'hu' && event.key === '.')) {
            event.preventDefault();
        }
    }

    public exportClaimHistory(): void {
        let exportData: any[] = this.offerClaimHistoryDto;
        if (this.historyTable.filteredValue != null) {
            exportData = this.historyTable.filteredValue;
        }
        this.coverageTransactionsAdminService.exportClaimHistory(exportData, this.translate.currentLang);
    }

    public translateProductType(productType: string) {
        return this.translate.instant('agreement.agreementData.services.productTypeValues.' + productType);
    }

    public translateHistoryStatus(status: string) {
        return this.translate.instant('offerClaimHistory.historySaleStatus.' + status);
    }

    public translatePurchaseStatus(offerClaimHistoryInput: OfferClaimHistoryDto | string) {
        if (!offerClaimHistoryInput) {
            return '';
        }

        if (typeof offerClaimHistoryInput === 'string') {
            return this.translate.instant('offerClaimHistory.purchaseStatus.filter.' + offerClaimHistoryInput);
        }

        const offerClaimHistory: OfferClaimHistoryDto = offerClaimHistoryInput as OfferClaimHistoryDto;
        if (!offerClaimHistory.purchaseStatus) {
            return '';
        }

        if (this.isAveragingTransaction(offerClaimHistory)) {
            return this.translate.instant('offerClaimHistory.averagingPurchaseStatus.' + offerClaimHistory.purchaseStatus);
        } else {
            return this.translate.instant('offerClaimHistory.purchaseStatus.' + offerClaimHistory.purchaseStatus);
        }
    }

    delete(selectedForDelete: OfferClaimHistoryDto) {
        this.dialogService.confirm(this.translate.instant('filter.basic.deleteDialog.title'), this.translate.instant('filter.basic.deleteDialog.message'))
            .subscribe((value: boolean): void => {
                if (value) {
                    this.coverageTransactionsAdminService.delete(selectedForDelete.id)
                        .subscribe(r => {
                            this.getOfferClaims();
                            this.bootstrapAlertService.showSuccess(this.translate.instant("offerClaimHistory.info.dealHasBeenDeleted"));
                        });
                }
            });

    }


    sortOverview(event: SortEvent): void {
        event.data.sort((data1, data2) => {
            const multiSortMeta: SortMeta[] = event.multiSortMeta;
            let result: number = null;

            if (multiSortMeta === undefined) {
                return this.sortField(event.order, event.field, data1, data2);
            } else {
                multiSortMeta.forEach((meta) => {
                    let metaResult = this.sortField(meta.order, meta.field, data1, data2);
                    result = result || metaResult;
                });
            }

            return result;
        });
    }

    public getProductName(offerClaimHistory: OfferClaimHistoryDto): string {
        if (offerClaimHistory.product.includes(this.CUSTOM_PRODUCT_NAME)) {
            const type = offerClaimHistory.product.replace(this.CUSTOM_PRODUCT_NAME, '');
            return `${this.CUSTOM_PRODUCT_NAME.toUpperCase()}-${new Date(offerClaimHistory.startDate).getFullYear()} HU ${type}`;
        }

        const productStringArray = offerClaimHistory.product.split(' ');
        return `${productStringArray[2]}-${productStringArray[1]} HU ${productStringArray[0]}`;
    }

    private sortField(order: number, field: string, data1: OfferClaimHistoryDto, data2: OfferClaimHistoryDto) {
        let result = null;

        let value1 = data1[field];
        let value2 = data2[field];
        if (field === 'saleStatus') {
            value1 = this.translateHistoryStatus(data1[field]);
            value2 = this.translateHistoryStatus(data2[field]);
        }
        if (field === 'productType') {
            value1 = this.translateProductType(data1[field]);
            value2 = this.translateProductType(data2[field]);
        }
        if (field === 'purchaseStatus') {
            value1 = this.translatePurchaseStatus(data1);
            value2 = this.translatePurchaseStatus(data2);
        }

        if (value1 == null && value2 != null) {
            result = -1;
        } else if (value1 != null && value2 == null) {
            result = 1;
        } else if (value1 == null && value2 == null) {
            result = 0;
        } else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        } else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }

        return order * result;
    }

    private getOfferClaims(): void {
        this.coverageTransactionsAdminService.getOfferClaimHistory()
            .subscribe((offerClaimHistoryDtos: OfferClaimHistoryDto[]) => {
                this.offerClaimHistoryDto = offerClaimHistoryDtos.map(offerClaimHistory => {
                    offerClaimHistory.product = this.getProductName(offerClaimHistory);
                    return offerClaimHistory;
                });
                this.createFilters(this.offerClaimHistoryDto);

                this.filteredDealIds = this.dealIdControl.valueChanges.pipe(
                    startWith(''),
                    map(value => this._filterDealIds(value || '')),
                );
            });
    }

    private createFilters(offerClaimHistoryDtos: OfferClaimHistoryDto[]): void {
        offerClaimHistoryDtos.forEach((offerClaimHistoryDto: OfferClaimHistoryDto) => {
            const containsDeliveryPeriod: boolean = this.deliveryPeriods.filter((dp: DeliveryPeriod): boolean =>
                moment(dp.startTime).isSame(moment(offerClaimHistoryDto.deliveryPeriod.startTime)) &&
                moment(dp.endTime).isSame(moment(offerClaimHistoryDto.deliveryPeriod.endTime)))
                .length > 0;

            if (!_.includes(this.dealIds, offerClaimHistoryDto.dealId) && offerClaimHistoryDto.dealId != null) {
                this.dealIds.push(offerClaimHistoryDto.dealId);
            }
            if (!containsDeliveryPeriod) {
                this.deliveryPeriods.push(offerClaimHistoryDto.deliveryPeriod);
            }
            if (!_.includes(this.partners, offerClaimHistoryDto.partner)) {
                this.partners.push(offerClaimHistoryDto.partner);
            }
            if (!_.includes(this.products, offerClaimHistoryDto.product)) {
                this.products.push(offerClaimHistoryDto.product);
            }
            if (!_.includes(this.productTypes, offerClaimHistoryDto.productType)) {
                this.productTypes.push(offerClaimHistoryDto.productType);
            }
            if (!_.includes(this.approvers, offerClaimHistoryDto.purchaseApprovedPerson) && !!offerClaimHistoryDto.purchaseApprovedPerson) {
                this.approvers.push(offerClaimHistoryDto.purchaseApprovedPerson);
            }
            if (!_.includes(this.bidders, offerClaimHistoryDto.pricePublisher) && !!offerClaimHistoryDto.pricePublisher) {
                this.bidders.push(offerClaimHistoryDto.pricePublisher);
            }
        });
        this.dealIds.sort();
        this.partners.sort();
        this.products.sort();
        this.approvers.sort();
        this.bidders.sort();
        this.deliveryPeriods.sort((prev: DeliveryPeriod, curr: DeliveryPeriod): number => moment(prev.startTime).diff(moment(curr.startTime)));

        this.productTypes.sort((a, b) => this.translateProductType(a).localeCompare(this.translateProductType(b)));

        this.statusList = _.values(HistorySaleStatus);
        this.statusList.sort((a, b) => this.translateHistoryStatus(a).localeCompare(this.translateHistoryStatus(b)));

        this.purchaseStatuses = _.values(PurchaseStatus);
        this.purchaseStatuses.sort((a, b) => this.translatePurchaseStatus(a).localeCompare(this.translatePurchaseStatus(b)));
    }

    private isAveragingTransaction(offerClaim: OfferClaimHistoryDto): boolean {
        return !!offerClaim
            && !!offerClaim.averagingStartDate
            && !!offerClaim.averagingEndDate;
    }

    private dateBetweenFilter(filter: DateIntervalFilter, value: string): boolean {
        if (value === undefined || value === null) {
            return false;
        }

        if (!!filter.start) {
            let filterStart = this.convertToJsDate(filter.start);
            if (filterStart > new Date(value)) {
                return false;
            }
        }
        if (!!filter.end) {
            let filterEnd = this.convertToJsDate(filter.end);
            filterEnd.setHours(23, 59, 59, 999);
            if (filterEnd < new Date(value)) {
                return false;
            }
        }

        return true;
    }

    private numberBetweenFilter(filter: NumberFilter, value: number) {
        if (value === undefined || value === null) {
            return false;
        }

        if (!!filter.min && filter.min > value) {
            return false;
        }
        if (!!filter.max && filter.max < value) {
            return false;
        }

        return true;
    }

    private deliveryPeriodFilter(deliveryPeriod: DeliveryPeriod | undefined, claimDeliveryPeriod: DeliveryPeriod): boolean {
        if (!deliveryPeriod) {
            return true;
        }
        return moment(claimDeliveryPeriod.startTime).isSame(moment(deliveryPeriod.startTime)) &&
            moment(claimDeliveryPeriod.endTime).isSame(moment(deliveryPeriod.endTime));
    }

    private convertToJsDate(date): Date {
        if (date) {
            return new Date(date.year, date.month - 1, date.day);
        }
        return null;
    }
}




