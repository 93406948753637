import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';
import {Moment} from 'moment';

@Pipe({
    name: 'minusDay'
})
export class MinusDayPipe implements PipeTransform {
    transform(date: any, days: number = 1): Moment {
        return moment(date).subtract(days, 'day');
    }
}
