<mat-card class="pb-0">
    <div class="card-header-row">
        <mat-card-title
            class="card-header-row__text-container">{{ 'coverageTransactions.tabs.cart.title' | translate }}
        </mat-card-title>
        <div class="text-right mb-3 card-header-row__button-container">
            <button color="primary" mat-mini-fab
                    class="btn--bordered"
                    writeProtected="HEDGE_TRANSACTIONS_WRITE"
                    (clickIfHasPermission)="addNewOrModifyCartItem()"
                    [disabled]="isNewCartItemButtonDisabled()"
                    [matTooltip]="'common.action.new' | translate">
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </div>
    <mat-card-content>
        <div *ngIf="hasCartItems">
            <table class="table">
                <thead>
                <tr>
                    <th class="text-left">
                        <span jhiTranslate="offerClaim.tableField.saleStatus"></span>
                    </th>
                    <th class="text-left">
                        <span jhiTranslate="offerClaim.tableField.dealId"></span>
                    </th>
                    <th class="text-left">
                        <span jhiTranslate="offerClaimHistory.tableField.purchaseType"></span>
                    </th>
                    <th class="text-left">
                        <span jhiTranslate="offerClaim.tableField.market"></span>
                    </th>
                    <th class="text-left">
                        <span jhiTranslate="offerClaim.tableField.deliveryPeriod"></span>
                    </th>
                    <th class="text-left">
                        <span jhiTranslate="offerClaim.tableField.startDate"></span>
                    </th>
                    <th class="text-left">
                        <span jhiTranslate="offerClaim.tableField.endDate"></span>
                    </th>
                    <th class="text-left">
                        <span jhiTranslate="offerClaim.tableField.productName"></span>
                    </th>
                    <th class="text-right">
                        <span jhiTranslate="offerClaimHistory.tableField.capacity"></span>
                    </th>
                    <th class="text-right">
                        <span jhiTranslate="offerClaimHistory.tableField.quantity"></span>
                    </th>
                    <th class="text-right">
                        <span jhiTranslate="offerClaim.tableField.price"></span>
                    </th>
                    <th class="text-right">
                        <span jhiTranslate="offerClaim.tableField.netPrice"></span>
                    </th>
                    <th *ngIf="hasTimeRemain" class="text-right">
                        <span jhiTranslate="offerClaim.tableField.timeLeft"></span>
                    </th>
                    <th class="text-center" *ngIf="hasEvaluationItem()">
                        <span jhiTranslate="offerClaim.tableField.evaluation"></span>
                    </th>
                    <th class="text-right" *ngIf="hasEditableItem()">
                        <span jhiTranslate="offerClaim.tableField.edit"></span>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let cartItem of cartItems">
                    <td class="text-left">
                        <label class="cez-label"
                               [class.cez-label--orange]="canEditOrEvaluate(cartItem)"
                               [class.cez-label--grey]="!canEditOrEvaluate(cartItem)">
                            {{ 'offerClaim.saleStatus.' + cartItem.currentStatus | translate }}
                        </label>
                    </td>
                    <td class="text-left">{{ cartItem.dealId }}</td>
                    <td class="text-left">
                        <div *ngIf="cartItem.purchaseStatus" class="d-flex align-items-center">
                            <mat-icon *ngIf="cartItem.purchaseStatus === 'BUY'" color="primary">arrow_forward</mat-icon>
                            <mat-icon *ngIf="cartItem.purchaseStatus === 'SELL'">arrow_back</mat-icon>
                            {{ translatePurchaseStatus(cartItem) }}
                        </div>
                    </td>
                    <td class="text-left">{{ cartItem.market }}</td>
                    <td class="text-left">{{ cartItem.deliveryPeriodStartTime | cezDate }}
                        - {{ cartItem.deliveryPeriodEndTime | cezDate }}
                    </td>
                    <td class="text-left">{{ cartItem.startDate | cezDate }}</td>
                    <td class="text-left">{{ cartItem.endDate | cezDate }}</td>
                    <td class="text-left">{{ getProductName(cartItem) }}</td>
                    <td class="text-right">{{ cartItem.quantity | number: '1.2-2' }}</td>
                    <td class="text-right">{{ cartItem.quantityMwh | number: '1.2-2' }}</td>
                    <td class="text-right">{{ cartItem.price | number: '1.2-2' }}</td>
                    <td class="text-right">{{ cartItem.netPrice | number: '1.0-0' }}</td>
                    <td *ngIf="hasTimeRemain" class="text-right countdown-column">
                        <countdown *ngIf="cartItem.timeRemain != null"
                                   class="delay-color-change"
                                   [ngClass]="getRemainTimeClassList(cartItem.id, cartItem.timeRemain)"
                                   [config]="{
                           leftTime: cartItem.timeRemain,
                       }"
                                   (event)="onCountdownEvent($event, cartItem)"
                        />
                    </td>
                    <td *ngIf="hasEvaluationItem()" class="text-center">
                        <div *ngIf="canEvaluate(cartItem)">
                            <mat-button-toggle-group [(ngModel)]="cartItem.saleStatus"
                                                     (ngModelChange)="statusSelectionChanged(cartItem)"
                                                     class="button-toggle-group--icon btn-sm border-0">
                                <mat-button-toggle *ngFor="let status of EVALUATION_STATUSES"
                                                   [ngClass]="EVALUATION_STATUS_CONFIG.get(status).cssClass"
                                                   [value]="status"
                                                   matTooltip="{{'offerClaim.saleStatus.' + status | translate}}">
                                    <mat-icon>{{ EVALUATION_STATUS_CONFIG.get(status).icon }}</mat-icon>
                                </mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                    </td>
                    <td *ngIf="hasEditableItem()" class="text-right">
                        <div *ngIf="canEdit(cartItem)">
                            <button mat-mini-fab
                                    color="primary"
                                    class="btn-sm"
                                    [disabled]="isModifyCartItemButtonDisabled(cartItem)"
                                    matTooltip="{{'offerClaim.tooltip.modify' | translate}}"
                                    writeProtected="HEDGE_TRANSACTIONS_WRITE"
                                    (clickIfHasPermission)="addNewOrModifyCartItem(cartItem)">
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button mat-mini-fab
                                    cez-color="dark"
                                    class="ml-1 btn-sm"
                                    [disabled]="isDeleteCartItemButtonDisabled(cartItem)"
                                    matTooltip="{{'offerClaim.tooltip.delete' | translate}}"
                                    writeProtected="HEDGE_TRANSACTIONS_WRITE"
                                    (clickIfHasPermission)="deleteCartItem(cartItem)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </td>
                </tr>
                <tr class="net-price-sum-row" *ngIf="hasEvaluationItem()">
                    <td colspan="11" class="text-left">{{ 'offerClaim.tableField.netPriceSum' | translate }}</td>
                    <td class="text-right">{{ getSumOfNetPrices() | number:'1.0-0' }}</td>
                    <td colspan="3"></td>
                </tr>
                </tbody>
            </table>
            <div *ngIf="hasEvaluationItem() && !!openPositionState">
                <div class="container pt-2 pb-2"
                     *ngFor="let agreementState of openPositionState.agreementOpenPositionStates">
                    <div class="row justify-content-evenly align-items-start">
                        <div class="col-6">
                            <div class="row justify-content-start align-items-center"
                                 *ngFor="let periodState of agreementState.deliveryPeriodOpenPositionStates">
                                <div class="col-auto" *ngIf="!!periodState.deliveryPeriodOpenPositionPercent">
                                    <span class="progress-bar-label">
                                        {{ getPeriodProgressBarLabel(periodState) }}
                                    </span>
                                </div>
                                <div class="col-6 progress-col">
                                    <div class="progress">
                                        <div class="progress-bar"
                                             role="progressbar"
                                             [progressbar]="getProgressBarConfig(periodState.deliveryPeriodOpenPositionPercent)">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6" *ngIf="!!agreementState.agreementOpenPositionPercent">
                            <div class="row pb-2 justify-content-start align-items-center">
                                <div class="col-auto">
                                    <span class="progress-bar-label">
                                        {{ 'offerClaim.progressBar.agreementBarLabel' | translate }}
                                    </span>
                                </div>
                                <div class="col-6 progress-col">
                                    <div class="progress">
                                        <div class="progress-bar"
                                             role="progressbar"
                                             [progressbar]="getProgressBarConfig(agreementState.agreementOpenPositionPercent)">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pb-3 card-footer-row">
                <div class="card-footer-row__text-container">
                    <span *ngIf="isThereAveragingOrHudexTransaction()">
                        {{ 'offerClaim.transactionType.infoText' | translate }}
                    </span>
                </div>
                <div class="card-footer-row__button-container">
                    <button writeProtected="HEDGE_TRANSACTIONS_WRITE"
                            (clickIfHasPermission)="cleanCart()"
                            [disabled]="isCleanCartButtonDisabled()"
                            class="btn cez-color--dark ml-1">{{ 'offerClaim.button.cleanCart' | translate }}
                    </button>
                    <button writeProtected="HEDGE_TRANSACTIONS_WRITE"
                            (clickIfHasPermission)="requestForQuotation()"
                            [disabled]="isRequestForQuotationButtonDisabled()"
                            [matTooltipDisabled]="!isRequestForQuotationButtonDisabled()"
                            [matTooltip]="'offerClaim.button.disabledTooltip.requestForQuotation' | translate"
                            class="btn btn-primary ml-1">{{ 'offerClaim.button.requestForQuotation' | translate }}
                    </button>
                    <button writeProtected="HEDGE_TRANSACTIONS_WRITE"
                            (clickIfHasPermission)="revokeRequestForQuotation()"
                            [disabled]="isRevokeRequestForQuotationButtonDisabled()"
                            class="btn btn-primary ml-1">{{ 'offerClaim.button.revokeRequestForQuotation' | translate }}
                    </button>
                    <button writeProtected="HEDGE_TRANSACTIONS_WRITE"
                            (clickIfHasPermission)="finalizingAllPurchase()"
                            [disabled]="isFinalizingAllPurchaseButtonDisabled()"
                            class="btn btn-primary ml-1">{{ 'offerClaim.button.finalizingAllActivePurchase' | translate }}
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="!hasCartItems" class="card-no-data-row">
            <span class="card-no-data-row__text">{{ "coverageTransactions.tabs.cart.notPresent" | translate }}</span>
            <button class="btn--bordered card-no-data-row__button"
                    color="primary" mat-mini-fab>
                <mat-icon>add</mat-icon>
            </button>
            <span class="card-no-data-row__text">{{ "coverageTransactions.tabs.cart.onButton" | translate }}</span>
        </div>
    </mat-card-content>
</mat-card>
