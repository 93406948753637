<div mat-dialog-content>
    <p style="white-space: pre-wrap"
       [innerHTML]="description"></p>
</div>
<div mat-dialog-actions align="end">
    <button mat-raised-button
            [mat-dialog-close]="false"
            cdkFocusInitial
            class="cez-color--dark">
        {{'offerClaim.rfqConfirmDialog.redirectButton' | translate}}
    </button>
    <button mat-raised-button
            color="primary"
            [mat-dialog-close]="true">
        {{'offerClaim.rfqConfirmDialog.rfqButton' | translate}}
    </button>
</div>
