import {Injectable} from '@angular/core';
import {NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {DateFormatUtil} from '../../core/util/date-format.util';

@Injectable()
export class NgbDateMomentParserFormatter extends NgbDateParserFormatter {

    constructor() {
        super();
    }

    public format(ngbDate: NgbDateStruct): string {
        return DateFormatUtil.formatNgbToLocalDate(ngbDate);
    }

    public parse(date: string): NgbDateStruct {
        return DateFormatUtil.transformDateToNgb(date);
    }

}
