import {Injectable} from '@angular/core';
import {BusinessParameterKey, BusinessParameterService} from '../../shared/services/business-parameter.service';
import {forkJoin, Observable, Subject} from 'rxjs';
import {Market} from '../../shared/offer-claim/model/market.enum';
import * as moment from 'moment';
import {OfferClaimDto} from '../../shared/offer-claim/model/OfferClaimDto';
import {OfferPeriod} from '../../shared/offer-claim/model/offer-period.enum';
import {BusinessCalendarService} from '../../shared/services/business-calendar.service';
import {DateFormatUtil} from '../../core/util/date-format.util';
import {Moment} from 'moment-timezone';

@Injectable()
export class ScheduledQuotationService {

    public readonly MINUTES_GAP: number = 15;
    // Időzített ajánlatkérésnél ez a következő kiválasztható 15 percre kerekített időpont meghatározásához
    // van használva, legalább ennyi perc távolságra kell legyen a kiválasztható időpont, MINUTES_GAP-re kerekítve
    private readonly MINUTES_BEFORE_MARKET_CLOSURE: number = 30;

    dayMin: Date;
    dayMax: Date;
    timeMin: string = '';
    timeMax: string = '';

    private isFinished: Subject<void> = new Subject<void>();
    private claims: OfferClaimDto[];
    private _selectedDay: moment.Moment;
    private HUDEX_D_ACCESS_FROM: string;
    private HUDEX_D_ACCESS_UNTIL: string;
    private HUDEX_D_MINUS_1_ACCESS_FROM: string;
    private HUDEX_D_MINUS_1_ACCESS_UNTIL: string;
    private OTC_TRADING_WINDOW_FROM: string;
    private OTC_TRADING_WINDOW_FROM_SCHEDULING_OFFSET: string;
    private OTC_TRADING_WINDOW_UNTIL: string;
    private OTC_TRADING_WINDOW_UNTIL_SCHEDULING_OFFSET: string;
    private offerPeriodLastFixableDayOffset: Map<OfferPeriod, number> = new Map<OfferPeriod, number>([
        [OfferPeriod.W, 0],
        [OfferPeriod.M, 0],
        [OfferPeriod.Q, 0],
        [OfferPeriod.Y, 0],
        [OfferPeriod.CUSTOM, 0],
    ]);

    constructor(private businessParameterService: BusinessParameterService,
                private businessCalendarService: BusinessCalendarService) {
    }

    public setClaims(claims: OfferClaimDto[]): void {
        this.claims = claims;

        this.setMinMax();
    }

    public loadParameters(): Observable<void> {
        this.isFinished = new Subject<void>();

        forkJoin(
            this.getBusinessParameters(),
            this.businessCalendarService.loadWorkdaysAndHolidays()
        ).subscribe(() => {
            this.isFinished.next();
            this.isFinished.complete();
        });


        return this.isFinished;
    }

    public setMinMax(): void {
        if (!this.areParametersInitialized()) {
            return;
        }

        this.timeMin = '';
        this.timeMax = '';
        this.dayMin = this.businessCalendarService.determineNextXWorkDay(1, true).toDate();
        this.dayMax = this.businessCalendarService.determineNextXWorkDay(5, false).toDate();

        const isOTC: boolean = this.claims.some((item) => item.market === Market.OTC);
        const isHudex: boolean = this.claims.some((item) => item.market === Market.HUDEX);

        // order of these steps is important
        // luckily js compares these string and there's no need to map them to numbers or dates
        if (isOTC) {
            this.setOTCMin();
            this.setOTCMax();
        }

        if (isHudex) {
            this.setHudexMin();
            this.setHudexMax(isOTC);
        }

        this.correctTimeMax(isHudex);

        if (isOTC) {
            // Következő kiválasztható időpont az offset szabályoknak megfelelően "round(now) + offset"
            const nextSelectableWithOffset = this.roundNowToNextSelectableMinutes();

            if (nextSelectableWithOffset.isSameOrAfter(this.setTimeByString(this.timeMax))) {
                // A kereskedési időszak vége utánra esünk, áttolódik holnapra
                this.dayMin = moment(this.dayMin).add(1, 'days').toDate();
            } else if (this.isTodaySelected()) {
                // A kereskedési időszak vége előttre esünks, és a mai nap van kiválasztva, így lehet, hogy módosítani
                // kell a legkorábbi kiválasztható időpontot
                const nextSelectableWithOffsetString= DateFormatUtil.formatToLocalTime(nextSelectableWithOffset);
                if (nextSelectableWithOffsetString > this.timeMin) {
                    // A legkorábbi kiválasztható időpont módosul, hogy megfeleljen a fenti offset szabálynak
                    this.timeMin = nextSelectableWithOffsetString;
                }
            }
        } else {
            if (this.isBeforeMarketClosure()) {
                if (this.isTodaySelected()) {
                    this.timeMin = DateFormatUtil.formatToLocalTime(this.roundNowToNextSelectableMinutes());
                }
            } else {
                this.dayMin = moment(this.dayMin).add(1, 'days').toDate();
            }
        }

        if (!this.businessCalendarService.isDaySelectable(moment(this.dayMin))) {
            this.dayMin = this.addWorkDays(this.dayMin).toDate();
        }

    }

    public isOtcMarketClosed() {
        if (!this.areParametersInitialized()) {
            return true;
        }
        return !moment().isBetween(this.setTimeByString(this.OTC_TRADING_WINDOW_FROM), this.setTimeByString(this.OTC_TRADING_WINDOW_UNTIL), null, '()');
    }

    public setTimeByString(time: string, date: moment.Moment = moment()): moment.Moment {
        const [hours, minutes] = time.split(':').map(Number);

        date
            .hour(hours)
            .minute(minutes)
            .second(0)
            .millisecond(0);

        return date;
    }

    public selectedDay(date: moment.Moment): void {
        if (this._selectedDay !== date) {
            this._selectedDay = date;
            this.setMinMax();
        }
    }

    public getDateWithClosestRoundedMinute(date: moment.Moment = moment()): moment.Moment {
        const currentTime: moment.Moment = date.clone();
        const roundedToSelectableMinutes: number = Math.ceil(date.minute() / this.MINUTES_GAP) * this.MINUTES_GAP;

        return currentTime
            .minute(roundedToSelectableMinutes);
    }

    private roundNowToNextSelectableMinutes(): moment.Moment {
        return this.getDateWithClosestRoundedMinute()
            .add(this.MINUTES_BEFORE_MARKET_CLOSURE, 'minutes');
    }

    /**
     * The user should not select the last X minutes of the open market, so this subtracts it from `timeMax`
     * Only done in case of HUDEX market, OTC scheduling offsets are already handled at this point
     */
    private correctTimeMax(isHudex: boolean): void {
        if (isHudex) {
            const date: moment.Moment = this.setTimeByString(this.timeMax)
                .subtract(this.MINUTES_BEFORE_MARKET_CLOSURE, 'minutes');

            this.timeMax = DateFormatUtil.formatToLocalTime(date);
        }
    }

    private isBeforeMarketClosure(): boolean {
        const marketBeforeClosure: Moment = moment().add(this.MINUTES_BEFORE_MARKET_CLOSURE, 'minutes');
        return DateFormatUtil.formatToLocalTime(marketBeforeClosure) < this.timeMax;
    }

    private setOTCMin(): void {
        const from = this.setTimeByString(this.OTC_TRADING_WINDOW_FROM);
        this.timeMin = DateFormatUtil.formatToLocalTime(from.add(this.OTC_TRADING_WINDOW_FROM_SCHEDULING_OFFSET, "minutes"));
    }

    private setOTCMax(): void {
        const until = this.setTimeByString(this.OTC_TRADING_WINDOW_UNTIL);
        this.timeMax = DateFormatUtil.formatToLocalTime(until.subtract(this.OTC_TRADING_WINDOW_UNTIL_SCHEDULING_OFFSET, "minutes"));
    }

    public getTradingWindowStart(): moment.Moment {
        const from: moment.Moment = this.setTimeByString(this.OTC_TRADING_WINDOW_FROM);
        return from.add(this.OTC_TRADING_WINDOW_FROM_SCHEDULING_OFFSET, "minutes");
    }

    public getTradingWindowEnd(): moment.Moment {
        const until: moment.Moment = this.setTimeByString(this.OTC_TRADING_WINDOW_UNTIL);
        return until.subtract(this.OTC_TRADING_WINDOW_UNTIL_SCHEDULING_OFFSET, "minutes");
    }

    private setHudexMin(): void {
        const hudexMinUnion: string = this.HUDEX_D_ACCESS_FROM < this.HUDEX_D_MINUS_1_ACCESS_FROM
            ? this.HUDEX_D_ACCESS_FROM
            : this.HUDEX_D_MINUS_1_ACCESS_FROM;

        if (hudexMinUnion > this.timeMin) {
            this.timeMin = hudexMinUnion;
        }
    }

    private setHudexMax(isOTC: boolean): void {
        const hudexMaxUnion: string = this.HUDEX_D_ACCESS_UNTIL > this.HUDEX_D_MINUS_1_ACCESS_UNTIL
            ? this.HUDEX_D_ACCESS_UNTIL
            : this.HUDEX_D_MINUS_1_ACCESS_UNTIL;

        if (!isOTC || hudexMaxUnion < this.timeMax) {
            this.timeMax = hudexMaxUnion;
        }
    }

    private getBusinessParameters(): Observable<void> {
        const isFinished: Subject<void> = new Subject<void>();
        this.businessParameterService.getParameters([
            BusinessParameterKey.OTC_TRADING_WINDOW_FROM,
            BusinessParameterKey.OTC_TRADING_WINDOW_FROM_SCHEDULING_OFFSET,
            BusinessParameterKey.OTC_TRADING_WINDOW_UNTIL,
            BusinessParameterKey.OTC_TRADING_WINDOW_UNTIL_SCHEDULING_OFFSET,

            BusinessParameterKey.HUDEX_D_ACCESS_FROM,
            BusinessParameterKey.HUDEX_D_ACCESS_UNTIL,
            BusinessParameterKey.HUDEX_D_1_ACCESS_FROM,
            BusinessParameterKey.HUDEX_D_1_ACCESS_UNTIL,

            BusinessParameterKey.MARKETABILITY_WEEKLY_PARAMETER,
            BusinessParameterKey.MARKETABILITY_MONTHLY_PARAMETER,
            BusinessParameterKey.MARKETABILITY_QUARTERLY_PARAMETER,
            BusinessParameterKey.MARKETABILITY_YEARLY_PARAMETER,
        ]).subscribe((response: Map<BusinessParameterKey, string>) => {
            this.OTC_TRADING_WINDOW_FROM = response[BusinessParameterKey.OTC_TRADING_WINDOW_FROM];
            this.OTC_TRADING_WINDOW_FROM_SCHEDULING_OFFSET = response[BusinessParameterKey.OTC_TRADING_WINDOW_FROM_SCHEDULING_OFFSET];
            this.OTC_TRADING_WINDOW_UNTIL = response[BusinessParameterKey.OTC_TRADING_WINDOW_UNTIL];
            this.OTC_TRADING_WINDOW_UNTIL_SCHEDULING_OFFSET = response[BusinessParameterKey.OTC_TRADING_WINDOW_UNTIL_SCHEDULING_OFFSET];

            this.HUDEX_D_ACCESS_FROM = response[BusinessParameterKey.HUDEX_D_ACCESS_FROM];
            this.HUDEX_D_ACCESS_UNTIL = response[BusinessParameterKey.HUDEX_D_ACCESS_UNTIL];
            this.HUDEX_D_MINUS_1_ACCESS_FROM = response[BusinessParameterKey.HUDEX_D_1_ACCESS_FROM];
            this.HUDEX_D_MINUS_1_ACCESS_UNTIL = response[BusinessParameterKey.HUDEX_D_1_ACCESS_UNTIL];

            this.offerPeriodLastFixableDayOffset.set(OfferPeriod.W, Number(response[BusinessParameterKey.MARKETABILITY_WEEKLY_PARAMETER]));
            this.offerPeriodLastFixableDayOffset.set(OfferPeriod.M, Number(response[BusinessParameterKey.MARKETABILITY_MONTHLY_PARAMETER]));
            this.offerPeriodLastFixableDayOffset.set(OfferPeriod.Q, Number(response[BusinessParameterKey.MARKETABILITY_QUARTERLY_PARAMETER]));
            this.offerPeriodLastFixableDayOffset.set(OfferPeriod.Y, Number(response[BusinessParameterKey.MARKETABILITY_YEARLY_PARAMETER]));

            this.setMinMax();

            isFinished.next();
            isFinished.complete();
        });

        return isFinished;
    }

    private subtractWorkdays(date: moment.Moment | Date, amount: number = 1, isAddition?: boolean): moment.Moment {
        let currentDate: moment.Moment = moment(date);

        while (amount != 0) {
            currentDate = currentDate.subtract(isAddition ? -1 : 1, 'days');

            if (this.businessCalendarService.isDaySelectable(currentDate)) {
                amount -= 1;
            }
        }

        return currentDate;
    }

    private addWorkDays(date: moment.Moment | Date): moment.Moment {
        return this.subtractWorkdays(date, 1, true);
    }

    private isTodaySelected(): boolean {
        return moment().isSame(this._selectedDay || this.dayMin, 'day');
    }

    private areParametersInitialized(): boolean {
        return !!this.HUDEX_D_ACCESS_FROM;
    }
}
