import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {DeliveryPeriodDto} from '../../shared/dto/delivery-period-dto';
import {CoverageTransactionsUtil} from '../coverage-transactions.util';
import {AgreementModel} from '../../shared/dto/agreement.model';
import {
    CoverageTransactionsOverview,
    CoverageTransactionsOverviewDto,
    CoverageTransactionsOverviewRequest
} from '../overview/coverage-transactions-overview.model';
import {DeliveryPeriod} from '../../shared/dto/delivery.periods';
import * as _ from 'lodash';
import {CoverageTransactionsService} from '../coverage-transactions.service';
import * as moment from 'moment';
import {Moment} from 'moment';
import {CezDatePipe} from '../../shared/pipes/cez-date.pipe';
import {TranslateService} from '@ngx-translate/core';
import {CoverageTransactionsPeriodTabService} from '../coverage-transactions-period-tab.service';
import {TabSave} from '../../shared/directives/tab-save/tab-save.type';
import {OfferClaimService} from '../offer-claim.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ProductType} from '../../shared/agreement-details/agreement-enums.model';
import {DateFormatUtil} from '../../core/util/date-format.util';

@Component({
  selector: 'jhi-pinned-coverage-transaction-bar',
  templateUrl: './pinned-coverage-transaction-bar.component.html',
  styleUrls: ['./pinned-coverage-transaction-bar.component.scss']
})
export class PinnedCoverageTransactionBarComponent implements OnInit, OnDestroy {

  private readonly STORAGE_KEY: string = TabSave.STORAGE_KEY_PREFIX + 'DELIVERY_PERIOD_ID';

  public agreementList: AgreementModel[];
  public coverageTransactionsOverviewDisplay: CoverageTransactionsOverview[] = [];
  public selectedOverview: CoverageTransactionsOverview;

  private _deliveryPeriods: DeliveryPeriodDto[] = [];
  private destroy: Subject<void> = new Subject();

  @Input()
  public get agreements(): AgreementModel[] {
    return this.agreementList;
  }

  public set agreements(agreements: AgreementModel[]) {
    this.agreementList = agreements;
    if (!!agreements && agreements.length > 0) {
      this.setTableData(agreements);
    }
  }

  @Input()
  public get deliveryPeriods(): DeliveryPeriodDto[] {
    return this._deliveryPeriods;
  }

  public set deliveryPeriods(deliveryPeriodDtos: DeliveryPeriodDto[]) {
    this._deliveryPeriods = deliveryPeriodDtos.sort(CoverageTransactionsUtil.sortDeliveryPeriods);
    this.selectCurrentDeliveryPeriod();
  }

  public deliveryPeriod: DeliveryPeriodDto;

  constructor(private cezDatePipe: CezDatePipe,
              private translateService: TranslateService,
              private coverageTransactionsService: CoverageTransactionsService,
              private coverageTransactionsPeriodTabService: CoverageTransactionsPeriodTabService,
              private offerClaimService: OfferClaimService) { }

  ngOnInit(): void {
    this.coverageTransactionsPeriodTabService.getSelectedDeliveryPeriod()
      .subscribe((item) => {
        if (item !== this.deliveryPeriod) {
          this.deliveryPeriod = item;
          this.setCurrentCoverageTransactionOverview();
        }
      });

    this.offerClaimService.updateCoverageTransactionsOverviewEvent.pipe(takeUntil(this.destroy))
      .subscribe(() => this.setTableData(this.agreementList));
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  private setTableData(agreements: AgreementModel[]): void {
    this.coverageTransactionsOverviewDisplay = [];
    const requests: CoverageTransactionsOverviewRequest[] = [];

    agreements.forEach((agreement: AgreementModel) =>
      agreement.deliveryPeriods.forEach((deliveryPeriod: DeliveryPeriod) => {
        requests.push({
          deliveryPeriodId: deliveryPeriod.id
        });
      }));

    this.getOverview(requests);
  }

  private getOverview(requests: CoverageTransactionsOverviewRequest[]) {
    this.coverageTransactionsService.getOverview(requests).subscribe((responses: CoverageTransactionsOverviewDto[]) => {
      responses.forEach((response: CoverageTransactionsOverviewDto) => {
        let deliveryPeriod: DeliveryPeriod;
        const agreementModel: AgreementModel = _.find(this.agreementList, (agreement: AgreementModel) => {
          return _.some(agreement.deliveryPeriods, (deliveryPeriod1: DeliveryPeriod) => {
            if (deliveryPeriod1.id === response.deliveryPeriodId) {
              deliveryPeriod = deliveryPeriod1;
              return true;
            }
            return false;
          });
        });
        this.setCoverageTransactionsOverview(deliveryPeriod, agreementModel, response);
      });
      this.sortCoverageTransactionsOverviewByDeliveryPeriod();
      this.coverageTransactionsOverviewDisplay = this.coverageTransactionsOverviewDisplay.sort(this.sortCoverageTransactionOverview);
      this.coverageTransactionsPeriodTabService.coverageTransactionsOverviews.next(this.coverageTransactionsOverviewDisplay);
      this.setCurrentCoverageTransactionOverview();
    }, () => {
    });
  }

  private setCoverageTransactionsOverview(deliveryPeriod: DeliveryPeriod,
                                          agreement: AgreementModel,
                                          response: CoverageTransactionsOverviewDto): void {
    this.coverageTransactionsOverviewDisplay.push({
      agreementId: agreement.id,
      deliveryPeriodDto: {...deliveryPeriod, scheduling: agreement.services.scheduling, agreementId: agreement.id, productType: agreement.productType, clicks: agreement.clicks} as DeliveryPeriodDto,
      deliveryPeriod: `${this.cezDatePipe.transform(deliveryPeriod.startTime)} - ${this.cezDatePipe.transform(deliveryPeriod.endTime)}`,
      product: this.translateService.instant(`agreement.agreementData.services.productTypeValuesShort.${agreement.productType}`),
      quantity: response.quantity,
      mtmUnitPrice: null,
      productType: agreement.productType,
      progressBarConfig: {
        minValue: 0,
        maxValue: 100,
        currentValue: agreement.productType === ProductType.STEP_BY_STEP ? response.percent : null,
        warnAfter: 100,
        warnBefore: undefined
      }
    });
  }

  private sortCoverageTransactionsOverviewByDeliveryPeriod() {
    this.coverageTransactionsOverviewDisplay.sort((prev: CoverageTransactionsOverview, curr: CoverageTransactionsOverview) => {
      const prevStartDate: Moment = moment(prev.deliveryPeriod.split('-')[0], DateFormatUtil.getDateFormat());
      const currStartDate: Moment = moment(curr.deliveryPeriod.split('-')[0], DateFormatUtil.getDateFormat());
      if (prevStartDate.isBefore(currStartDate)) {
        return -1;
      }
      return prevStartDate.isAfter(currStartDate) ? 1 : 0;
    });
  }

  private selectCurrentDeliveryPeriod(): void {
    const selectedDeliveryPeriod: DeliveryPeriodDto = this.getLastSavedDeliveryPeriod() || this.getCurrentDeliveryPeriod() || this.getNextOnTimeDeliveryPeriod() || this.getOnTimePreviousDeliveryPeriod();

    if (!!selectedDeliveryPeriod) {
      this.deliveryPeriod = selectedDeliveryPeriod;
      this.onDeliveryPeriodChanged();
    }
  }

  onDeliveryPeriodChanged(): void {
    this.coverageTransactionsPeriodTabService.setSelectedDeliveryPeriod(this.deliveryPeriod);
    sessionStorage.setItem(this.STORAGE_KEY, JSON.stringify(this.deliveryPeriod.id));
    this.setCurrentCoverageTransactionOverview();
  }

  private setCurrentCoverageTransactionOverview(): void {
    if (!this.deliveryPeriod) {
      this.selectedOverview = null;
      return;
    }

    this.selectedOverview = this.coverageTransactionsOverviewDisplay.find((item) => {
      return item.deliveryPeriodDto.id === this.deliveryPeriod.id;
    });

    this.coverageTransactionsService.loadMtmUnitPrices(this.coverageTransactionsOverviewDisplay, this.deliveryPeriod.id);
  }

  private getLastSavedDeliveryPeriod(): DeliveryPeriodDto {
    const savedDeliveryPeriodId: string = sessionStorage.getItem(this.STORAGE_KEY);
    let deliveryPeriod: DeliveryPeriodDto;

    if (savedDeliveryPeriodId) {
      const deliveryPeriodId: number = Number(savedDeliveryPeriodId);
      deliveryPeriod = this._deliveryPeriods.find((item) => item.id === deliveryPeriodId);
    }

    return deliveryPeriod;
  }

  private getCurrentDeliveryPeriod(): DeliveryPeriodDto {
    return this._deliveryPeriods[CoverageTransactionsUtil.findIndexOfCurrentPeriod(this._deliveryPeriods)];
  }

  private getNextOnTimeDeliveryPeriod() {
    return this._deliveryPeriods[CoverageTransactionsUtil.findIndexOfNextPeriod(this._deliveryPeriods)];
  }

  private getOnTimePreviousDeliveryPeriod() {
    return this._deliveryPeriods[CoverageTransactionsUtil.findIndexOfPreviousPeriod(this._deliveryPeriods)];
  }

  private sortCoverageTransactionOverview(prev: CoverageTransactionsOverview, curr: CoverageTransactionsOverview): number {
    return CoverageTransactionsUtil.sortDeliveryPeriods(prev.deliveryPeriodDto, curr.deliveryPeriodDto);
  }
}
