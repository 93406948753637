<mat-card class="mat-card-min-height">
    <mat-card-content>
        <p-table #activeOffersTable
                 id="activeOffersTable"
                 [value]="activeOfferClaimDto"
                 [paginator]="true"
                 [rows]="10"
                 sortMode="multiple"
                 [multiSortMeta]="[
                     {field: 'dealId', order: TABLE_SORT_ASC},
                     {field: 'partner', order: TABLE_SORT_ASC},
                     {field: 'deliveryPeriod.startTime', order: TABLE_SORT_DESC}
                 ]"
                 (sortFunction)="sortOverview($event)"
                 [customSort]="true"
                 class="table table-striped">
            <ng-template pTemplate="header">
                <tr>
                    <th [pSortableColumn]="'dealId'" class="text-center head-th filter-column-width">
                        <span class="no-linebreak">
                            {{ "activeOffers.tableField.dealId" | translate }}&nbsp;<p-sortIcon [field]="'dealId'"></p-sortIcon>
                        </span>
                    </th>
                    <th [pSortableColumn]="'partner'" class="text-center head-th filter-column-width">
                        <span class="no-linebreak">
                            {{ "activeOffers.tableField.partner" | translate }}&nbsp;<p-sortIcon [field]="'partner'"></p-sortIcon></span>

                    </th>
                    <th [pSortableColumn]="'purchaseStatus'" class="text-center head-th default-column-width">
                        <span>
                            {{ "activeOffers.tableField.purchaseType" | translate }}&nbsp;<p-sortIcon [field]="'purchaseStatus'"></p-sortIcon>
                        </span>
                    </th>
                    <th [pSortableColumn]="'deliveryPeriod.startTime'" class="text-center head-th small-column-width">
                        <span>
                            {{ "activeOffers.tableField.deliveryPeriod" | translate }}&nbsp;<p-sortIcon [field]="'deliveryPeriod.startTime'"></p-sortIcon>
                        </span>
                    </th>
                    <th [pSortableColumn]="'startDate'" class="text-center head-th date-column-width">
                        <span>
                            {{ "activeOffers.tableField.startDate" | translate }}&nbsp;<p-sortIcon [field]="'startDate'"></p-sortIcon>
                        </span>
                    </th>
                    <th [pSortableColumn]="'endDate'" class="text-center head-th date-column-width">
                        <span>
                            {{ "activeOffers.tableField.endDate" | translate }}&nbsp;<p-sortIcon [field]="'endDate'"></p-sortIcon>
                        </span>
                    </th>
                    <th [pSortableColumn]="'product'" class="text-center head-th default-column-width">
                        <span>
                            {{ "activeOffers.tableField.productName" | translate }}&nbsp;<p-sortIcon [field]="'product'"></p-sortIcon>
                        </span>
                    </th>
                    <th [pSortableColumn]="'quantity'" class="text-center head-th quantity-column-width">
                        <span>
                            {{ "activeOffers.tableField.capacity" | translate }}&nbsp;<p-sortIcon [field]="'quantity'"></p-sortIcon>
                        </span>
                    </th>
                    <th [pSortableColumn]="'quantityMwh'" class="text-center head-th quantity-column-width">
                        <span>
                            {{ "activeOffers.tableField.quantity" | translate }}&nbsp;<p-sortIcon [field]="'quantityMwh'"></p-sortIcon>
                        </span>
                    </th>
                    <th [pSortableColumn]="'price'" class="text-center head-th price-column-width">
                        <span>
                            {{ "activeOffers.tableField.price" | translate }}&nbsp;<p-sortIcon [field]="'price'"></p-sortIcon>
                        </span>
                    </th>
                    <th [pSortableColumn]="'netSum'" class="text-center head-th quantity-column-width">
                        <span>
                            {{ "activeOffers.tableField.netSum" | translate }}&nbsp;<p-sortIcon [field]="'netSum'"></p-sortIcon>
                        </span>
                    </th>
                    <th [pSortableColumn]="'evaluationDeadline'" class="text-center head-th date-column-width">
                        <span>
                            {{ "activeOffers.tableField.deadline" | translate }}&nbsp;<p-sortIcon [field]="'evaluationDeadline'"></p-sortIcon>
                        </span>
                    </th>
                    <th class="text-center head-th">
                        <span jhiTranslate="activeOffers.tableField.contractingAuthor"></span>
                    </th>
                </tr>
                <tr>
                    <th class="filter-th" id="deal-id-filter-form">
                        <mat-form-field class="pt-2 pb-2 head-select text-left">
                            <mat-label>{{ 'global.choose' | translate }}</mat-label>
                            <input matInput
                                   type="text"
                                   aria-label="Number"
                                   [formControl]="dealIdControl"
                                   [matAutocomplete]="auto">
                            <mat-autocomplete #auto="matAutocomplete"
                                              (optionSelected)="activeOffersTable.filter($event.option.value, 'dealId', 'equals')"
                                              panelWidth="auto">
                                <mat-option>{{ 'multiSelectText.checkAll' | translate }}</mat-option>
                                <mat-option *ngFor="let dealId of filteredDealIds | async"
                                            [value]="dealId">{{ dealId }}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </th>
                    <th class="filter-th">
                        <mat-select
                            class="pt-2 pb-2 head-select text-left"
                            (selectionChange)="activeOffersTable.filter($event.value, 'partner', 'equals')"
                            placeholder="{{'global.choose' | translate}}">
                            <mat-option>{{ 'multiSelectText.checkAll' | translate }}</mat-option>
                            <mat-option
                                *ngFor="let partner of partners"
                                [value]="partner">
                                {{ partner }}
                            </mat-option>
                        </mat-select>
                    </th>
                    <th>
                        <mat-select
                            class="pt-2 pb-2 head-select"
                            (selectionChange)="activeOffersTable.filter($event.value, 'purchaseStatus', 'equals')"
                            placeholder="{{'global.choose' | translate}}">
                            <mat-option>{{ 'multiSelectText.checkAll' | translate }}</mat-option>
                            <mat-option
                                *ngFor="let purchaseStatus of purchaseStatuses"
                                [value]="purchaseStatus">
                                {{ translatePurchaseStatus(purchaseStatus) }}
                            </mat-option>
                        </mat-select>
                    </th>
                    <th class="filter-th">
                        <mat-select
                            class="pt-2 pb-2 head-select"
                            (selectionChange)="activeOffersTable.filter($event.value, 'deliveryPeriod.id', 'equals')"
                            placeholder="{{'global.choose' | translate}}">
                            <mat-option>{{ 'multiSelectText.checkAll' | translate }}</mat-option>
                            <mat-option
                                *ngFor="let deliveryPeriod of deliveryPeriods"
                                [value]="deliveryPeriod.id">
                                {{ deliveryPeriod.startTime | cezDate }} - {{ deliveryPeriod.endTime | cezDate }}
                            </mat-option>
                        </mat-select>
                    </th>
                    <th>
                        <div class="date-filter-padding">
                            <jhi-date-picker class="default-column-width"
                                             [(dpModel)]="dateFilterMap.get('startDate').start"
                                             (dpModelChange)="this.activeOffersTable.filter($event, 'startDate', 'dateBetween-startDate')"
                                             placeholder="{{ 'global.from' | translate }}">
                            </jhi-date-picker>
                        </div>
                        <div>
                            <jhi-date-picker class="default-column-width"
                                             [(dpModel)]="dateFilterMap.get('startDate').end"
                                             (dpModelChange)="this.activeOffersTable.filter($event, 'startDate', 'dateBetween-startDate')"
                                             placeholder="{{ 'global.to' | translate }}">
                            </jhi-date-picker>
                        </div>
                    </th>
                    <th>
                        <div class="date-filter-padding">
                            <jhi-date-picker class="default-column-width"
                                             [(dpModel)]="dateFilterMap.get('endDate').start"
                                             (dpModelChange)="this.activeOffersTable.filter($event, 'endDate', 'dateBetween-startDate')"
                                             placeholder="{{ 'global.from' | translate }}">
                            </jhi-date-picker>
                        </div>
                        <div>
                            <jhi-date-picker class="default-column-width"
                                             [(dpModel)]="dateFilterMap.get('endDate').end"
                                             (dpModelChange)="this.activeOffersTable.filter($event, 'endDate', 'dateBetween-startDate')"
                                             placeholder="{{ 'global.to' | translate }}">
                            </jhi-date-picker>
                        </div>
                    </th>
                    <th>
                        <mat-select
                            class="pt-2 pb-2 head-select"
                            (selectionChange)="activeOffersTable.filter($event.value, 'product', 'equals')"
                            placeholder="{{'global.choose' | translate}}">
                            <mat-option>{{ 'multiSelectText.checkAll' | translate }}</mat-option>
                            <mat-option
                                *ngFor="let product of products"
                                [value]="product">
                                {{ product }}
                            </mat-option>
                        </mat-select>
                    </th>
                    <th>
                        <mat-form-field class="pt-2 pb-2 quantity-column-width">
                            <input type="text"
                                   matInput
                                   mask="separator.2"
                                   autocomplete="off"
                                   class="text-right"
                                   maxlength="10"
                                   [maxNumber]="99999999.99"
                                   thousandSeparator="{{'number.thousandSeparator' | translate}}"
                                   (keydown)="onKeyDown($event)"
                                   [decimalMarker]="'number.decimalMarker' | translate"
                                   [dropSpecialCharacters]="true"
                                   [validation]="true"
                                   [(ngModel)]="numberFilterMap.get('quantity').min"
                                   (input)="activeOffersTable.filter($event.target.value, 'quantity', 'numberBetween-quantity')"
                                   placeholder="{{'global.from' | translate}}">
                        </mat-form-field>
                        <mat-form-field class="pt-2 pb-2 quantity-column-width number-filter-margin">
                            <input type="text"
                                   matInput
                                   mask="separator.2"
                                   autocomplete="off"
                                   class="text-right"
                                   maxlength="10"
                                   [maxNumber]="99999999.99"
                                   thousandSeparator="{{'number.thousandSeparator' | translate}}"
                                   (keydown)="onKeyDown($event)"
                                   [decimalMarker]="'number.decimalMarker' | translate"
                                   [dropSpecialCharacters]="true"
                                   [validation]="true"
                                   [(ngModel)]="numberFilterMap.get('quantity').max"
                                   (input)="activeOffersTable.filter($event.target.value, 'quantity', 'numberBetween-quantity')"
                                   placeholder="{{'global.to' | translate}}">
                        </mat-form-field>
                    </th>
                    <th>
                        <mat-form-field class="pt-2 pb-2 quantity-column-width">
                            <input type="text"
                                   matInput
                                   mask="separator.2"
                                   autocomplete="off"
                                   class="text-right"
                                   maxlength="10"
                                   [maxNumber]="99999999.99"
                                   thousandSeparator="{{'number.thousandSeparator' | translate}}"
                                   (keydown)="onKeyDown($event)"
                                   [decimalMarker]="'number.decimalMarker' | translate"
                                   [dropSpecialCharacters]="true"
                                   [validation]="true"
                                   [(ngModel)]="numberFilterMap.get('quantityMwh').min"
                                   (input)="activeOffersTable.filter($event.target.value, 'quantityMwh', 'numberBetween-quantityMwh')"
                                   placeholder="{{'global.from' | translate}}">
                        </mat-form-field>
                        <mat-form-field class="pt-2 pb-2 quantity-column-width number-filter-margin">
                            <input type="text"
                                   matInput
                                   mask="separator.2"
                                   autocomplete="off"
                                   class="text-right"
                                   maxlength="10"
                                   [maxNumber]="99999999.99"
                                   thousandSeparator="{{'number.thousandSeparator' | translate}}"
                                   (keydown)="onKeyDown($event)"
                                   [decimalMarker]="'number.decimalMarker' | translate"
                                   [dropSpecialCharacters]="true"
                                   [validation]="true"
                                   [(ngModel)]="numberFilterMap.get('quantityMwh').max"
                                   (input)="activeOffersTable.filter($event.target.value, 'quantityMwh', 'numberBetween-quantityMwh')"
                                   placeholder="{{'global.to' | translate}}">
                        </mat-form-field>
                    </th>
                    <th>
                        <mat-form-field class="pt-2 pb-2 price-column-width">
                            <input type="text"
                                   matInput
                                   mask="separator.2"
                                   autocomplete="off"
                                   class="text-right"
                                   maxlength="10"
                                   [maxNumber]="99999999.99"
                                   thousandSeparator="{{'number.thousandSeparator' | translate}}"
                                   (keydown)="onKeyDown($event)"
                                   [decimalMarker]="'number.decimalMarker' | translate"
                                   [dropSpecialCharacters]="true"
                                   [validation]="true"
                                   [(ngModel)]="numberFilterMap.get('price').min"
                                   (input)="activeOffersTable.filter($event.target.value, 'price', 'numberBetween-price')"
                                   placeholder="{{'global.from' | translate}}">
                        </mat-form-field>
                        <mat-form-field class="pt-2 pb-2 price-column-width number-filter-margin">
                            <input type="text"
                                   matInput
                                   mask="separator.2"
                                   autocomplete="off"
                                   class="text-right"
                                   maxlength="10"
                                   [maxNumber]="99999999.99"
                                   thousandSeparator="{{'number.thousandSeparator' | translate}}"
                                   (keydown)="onKeyDown($event)"
                                   [decimalMarker]="'number.decimalMarker' | translate"
                                   [dropSpecialCharacters]="true"
                                   [validation]="true"
                                   [(ngModel)]="numberFilterMap.get('price').max"
                                   (input)="activeOffersTable.filter($event.target.value, 'price', 'numberBetween-price')"
                                   placeholder="{{'global.to' | translate}}">
                        </mat-form-field>
                    </th>
                    <th>
                        <mat-form-field class="pt-2 pb-2 quantity-column-width">
                            <input type="text"
                                   matInput
                                   mask="separator.2"
                                   autocomplete="off"
                                   class="text-right"
                                   maxlength="10"
                                   [maxNumber]="99999999.99"
                                   thousandSeparator="{{'number.thousandSeparator' | translate}}"
                                   (keydown)="onKeyDown($event)"
                                   [decimalMarker]="'number.decimalMarker' | translate"
                                   [dropSpecialCharacters]="true"
                                   [validation]="true"
                                   [(ngModel)]="numberFilterMap.get('netSum').min"
                                   (input)="activeOffersTable.filter($event.target.value, 'netSum', 'numberBetween-netSum')"
                                   placeholder="{{'global.from' | translate}}">
                        </mat-form-field>
                        <mat-form-field class="pt-2 pb-2 quantity-column-width number-filter-margin">
                            <input type="text"
                                   matInput
                                   mask="separator.2"
                                   autocomplete="off"
                                   class="text-right"
                                   maxlength="10"
                                   [maxNumber]="99999999.99"
                                   thousandSeparator="{{'number.thousandSeparator' | translate}}"
                                   (keydown)="onKeyDown($event)"
                                   [decimalMarker]="'number.decimalMarker' | translate"
                                   [dropSpecialCharacters]="true"
                                   [validation]="true"
                                   [(ngModel)]="numberFilterMap.get('netSum').max"
                                   (input)="activeOffersTable.filter($event.target.value, 'netSum', 'numberBetween-netSum')"
                                   placeholder="{{'global.to' | translate}}">
                        </mat-form-field>
                    </th>
                    <th>
                        <div class="date-filter-padding">
                            <jhi-date-picker class="default-column-width"
                                             [(dpModel)]="dateFilterMap.get('evaluationDeadline').start"
                                             (dpModelChange)="this.activeOffersTable.filter($event, 'evaluationDeadline', 'dateBetween-startDate')"
                                             placeholder="{{ 'global.from' | translate }}">
                            </jhi-date-picker>
                        </div>
                        <div>
                            <jhi-date-picker class="default-column-width"
                                             [(dpModel)]="dateFilterMap.get('evaluationDeadline').end"
                                             (dpModelChange)="this.activeOffersTable.filter($event, 'evaluationDeadline', 'dateBetween-startDate')"
                                             placeholder="{{ 'global.to' | translate }}">
                            </jhi-date-picker>
                        </div>
                    </th>
                    <th></th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData">
                    <td class="text-left">{{ rowData.dealId }}</td>
                    <td class="text-left">{{ rowData.partner }}</td>
                    <td>{{ translatePurchaseStatus(rowData.purchaseStatus) }}</td>
                    <td>{{ rowData.deliveryPeriod.startTime | cezDate }}
                        - {{ rowData.deliveryPeriod.endTime | cezDate }}
                    </td>
                    <td>{{ rowData.startDate | cezDate }}</td>
                    <td>{{ rowData.endDate | cezDate }}</td>
                    <td class="text-left">{{ rowData.product }}</td>
                    <td class="text-right">{{ rowData.quantity | number: '1.2-2' }}</td>
                    <td class="text-right">{{ rowData.quantityMwh | number: '1.2-2' }}</td>
                    <td class="text-right">{{ rowData.price | number: '1.2-2' }}</td>
                    <td class="text-right">{{ rowData.netSum | number: '1.0-0' }}</td>
                    <td class="text-center">{{ rowData.evaluationDeadline | cezDateTime }}</td>
                    <td class="text-center">{{ getQuotationRequester(rowData.quotationRequester) }}</td>
                </tr>
            </ng-template>
        </p-table>
    </mat-card-content>
</mat-card>
